import { WidgetTypes } from '@typedef/Widget/widget.types';
import '../styles/CustomStyle.scss';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: WidgetTypes | null;
};

const CustomNews = ({ handleOnChange, selectLayout }: Props) => {
  return (
    <div className="custom">
      <div className="news-keyword-custom">
        <p className="title">키워드</p>
        <input
          type="text"
          value={selectLayout?.newsKeyword}
          onChange={(e) => {
            handleOnChange('newsKeyword', e.target.value);
          }}
        />
      </div>

      <div className="separator-keyword-custom">
        <p className="title">뉴스 문단 구분자</p>
        <input
          type="text"
          value={selectLayout?.newsSeparator}
          onChange={(e) => {
            handleOnChange('newsSeparator', e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default CustomNews;
