import { DeviceTypes } from '@typedef/Device/device.types';
import React from 'react';
import * as S from '../styles/DeviceManageStyle';
import { EDeviceOrientation } from '@typedef/Device/device-orientation.enum';
import { EDeviceType } from '@typedef/Device/device-type.enum';
import { accountSelector } from '@stories/account';
import { useRecoilValue } from 'recoil';
import { ECompanyType } from '@typedef/Company/company-type.enum';

type Props = {
  id: 'add' | 'update' | 'delete';
  type: '추가' | '수정' | '삭제';
  deviceData: DeviceTypes;
  setDeviceData: React.Dispatch<React.SetStateAction<DeviceTypes>>;
  onClickBtn: () => void;
  onClickMacDuplicateInspection: () => void;
  onClickIpDuplicateInspection: () => void;
};

const DeviceManage = ({ id, type, deviceData, setDeviceData, onClickBtn }: Props) => {
  const widthheightPattern: RegExp = /[0-9]/g;
  const account = useRecoilValue(accountSelector);

  return (
    <S.Container className="modalBody">
      <article className="contents">
        {id === 'delete' ? (
          <div className="delete">
            <p>선택하신 장비들을 삭제하시겠습니까?</p>
          </div>
        ) : (
          <>
            <p className="warning">*내용들은 필수로 있어야 합니다.</p>
            <div className="split">
              <p className="subtitle">
                <span>*</span>장비 이름
              </p>
              <S.Input>
                <input
                  type="text"
                  value={deviceData?.name ?? ''}
                  onChange={(e) => {
                    setDeviceData({
                      ...deviceData,
                      name: e.target.value,
                    });
                  }}
                />
              </S.Input>
            </div>
            <div className="split">
              <p className="subtitle">
                <span>*</span>장비 유형
              </p>
              <S.Input>
                <select
                  value={deviceData?.type?.value ?? ''}
                  onChange={(e) => {
                    setDeviceData({
                      ...deviceData,
                      type: EDeviceType.valueOf(e.target.value)!,
                    });
                  }}
                >
                  <option value="" disabled>
                    선택
                  </option>
                  {EDeviceType.values.map((v) => {
                    if (EDeviceType.COMPUTER.equals(v) && !ECompanyType.YOUTH_WORKSHOP.equals(account.company.type))
                      return <></>;
                    return <option value={v.value}>{v.name}</option>;
                  })}
                </select>
              </S.Input>
            </div>
            <div className="split">
              <p className="subtitle">
                <span>*</span>설치 위치
              </p>
              <S.Input>
                <input
                  type="text"
                  value={deviceData?.location ?? ''}
                  onChange={(e) => {
                    setDeviceData({
                      ...deviceData,
                      location: e.target.value,
                    });
                  }}
                />
              </S.Input>
            </div>
            {!deviceData.type.equals(EDeviceType.COMPUTER) && (
              <div className="split">
                <p className="subtitle">
                  <span>*</span>크기 ( 가로 x 세로 )
                </p>
                <S.DisplaySize>
                  <S.Input>
                    <input
                      type="text"
                      placeholder="1920"
                      value={deviceData?.width ?? ''}
                      onChange={(e) => {
                        setDeviceData({
                          ...deviceData,
                          width: widthheightPattern.test(e.target.value) ? e.target.value : '',
                        });
                      }}
                    />
                  </S.Input>
                  <span>x</span>
                  <S.Input>
                    <input
                      type="text"
                      placeholder="1080"
                      value={deviceData?.height ?? ''}
                      onChange={(e) => {
                        setDeviceData({
                          ...deviceData,
                          height: widthheightPattern.test(e.target.value) ? e.target.value : '',
                        });
                      }}
                    />
                  </S.Input>
                </S.DisplaySize>
              </div>
            )}
            {!deviceData.type.equals(EDeviceType.COMPUTER) && (
              <div className="split">
                <p className="subtitle">
                  <span>*</span>방향
                </p>
                <S.Input>
                  <select
                    value={deviceData?.displayType?.value ?? ''}
                    onChange={(e) => {
                      setDeviceData({
                        ...deviceData,
                        displayType: EDeviceOrientation.valueOf(e.target.value)!,
                      });
                    }}
                  >
                    <option value="" disabled>
                      선택
                    </option>
                    {EDeviceOrientation.values.map((v) => (
                      <option value={v.value}>{v.name}</option>
                    ))}
                  </select>
                </S.Input>
              </div>
            )}
            <div className="split">
              <p className="subtitle">
                <span>*</span>전원 관리 여부
              </p>
              <S.Input>
                <select
                  value={deviceData.isPowerManageable ? 'TRUE' : 'FALSE'}
                  onChange={(e) => {
                    setDeviceData({
                      ...deviceData,
                      isPowerManageable: e.target.value === 'TRUE' ? true : false,
                    });
                  }}
                  disabled={id === 'update'}
                >
                  <option value="" disabled>
                    선택
                  </option>
                  <option value={'TRUE'}>지원</option>
                  <option value={'FALSE'}>미지원</option>
                </select>
              </S.Input>
            </div>
            <div className="split">
              <p className="subtitle">
                <span>*</span>IP
              </p>
              <S.Input>
                <div className="input-btn">
                  <input
                    type="text"
                    placeholder="192.168.0.1"
                    value={deviceData?.ip ?? ''}
                    onChange={(e) => {
                      setDeviceData({
                        ...deviceData,
                        ip: e.target.value,
                      });
                    }}
                    disabled={id === 'update'}
                  />
                </div>
              </S.Input>
            </div>
            {deviceData.isPowerManageable && (
              <div className="split">
                <p className="subtitle">
                  <span>*</span>Mac
                </p>
                <S.Input>
                  <div className="input-btn">
                    <input
                      type="text"
                      placeholder="00:00:00:00:00:00"
                      value={deviceData?.macAddress ?? ''}
                      onChange={(e) => {
                        setDeviceData({
                          ...deviceData,
                          macAddress: e.target.value,
                        });
                      }}
                      disabled={id === 'update'}
                    />
                  </div>
                </S.Input>
              </div>
            )}
            <p className="warning">*전원 관리 여부와 IP, Mac 정보는 생성 후 수정이 불가능합니다.</p>
          </>
        )}
      </article>
      <article className="bottoms">
        <button onClick={onClickBtn} className={id === 'delete' ? 'danger' : ''}>
          {type}하기
        </button>
      </article>
    </S.Container>
  );
};

export default DeviceManage;
