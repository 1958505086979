import { lineOptions } from '@components/Monitoring/tempChartData';
import { Line } from 'react-chartjs-2';

type Props = {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: false;
      borderColor: string;
      tension: number;
    }[];
  };
};

const LineChart = ({ data: { labels, datasets } }: Props) => {
  return (
    <Line
      data={{
        labels,
        datasets: [...datasets],
      }}
      options={lineOptions}
    />
  );
};

export default LineChart;
