import { WeatherTypes, precipitationFormCodeE } from '@typedef/Widget/weather.types';
import { Rain, RainSnow, Snow, Sun } from '@assets/icons';

export function getWeatherPrecipitation(status: number): precipitationFormCodeE {
  switch (status) {
    case -1:
      return precipitationFormCodeE.UKNNOWN;
    case 0:
      return precipitationFormCodeE.NONE;
    case 1:
      return precipitationFormCodeE.RAIN;
    case 2:
      return precipitationFormCodeE.RAINSNOW;
    case 3:
      return precipitationFormCodeE.SNOW;
    case 5:
      return precipitationFormCodeE.DRIZZLE;
    case 6:
      return precipitationFormCodeE.DRIZZLESNOWFALL;
    case 7:
      return precipitationFormCodeE.SNOWFALL;
    default:
      return precipitationFormCodeE.UKNNOWN;
  }
}

export function getWeatherPrecipitationText(status: precipitationFormCodeE): string {
  switch (status) {
    case precipitationFormCodeE.UKNNOWN:
      return '알수없음';
    case precipitationFormCodeE.NONE:
      return '맑음';
    case precipitationFormCodeE.RAIN:
      return '비';
    case precipitationFormCodeE.RAINSNOW:
      return '비/눈';
    case precipitationFormCodeE.SNOW:
      return '눈';
    case precipitationFormCodeE.DRIZZLE:
      return '빗방울';
    case precipitationFormCodeE.DRIZZLESNOWFALL:
      return '빗방울눈날림';
    case precipitationFormCodeE.SNOWFALL:
      return '눈날림';
    default:
      return '알수 없음';
  }
}

export function getWeatherPrecipitationIcon(status: precipitationFormCodeE) {
  switch (status) {
    case precipitationFormCodeE.UKNNOWN:
      return <Sun title="sun" />;
    case precipitationFormCodeE.NONE:
      return <Sun title="sun" />;
    case precipitationFormCodeE.RAIN:
      return <Rain title="rain" />;
    case precipitationFormCodeE.RAINSNOW:
      return <RainSnow title="rainSnow" />;
    case precipitationFormCodeE.SNOW:
      return <Snow title="snow" />;
    case precipitationFormCodeE.DRIZZLE:
      return <Rain title="rain" />;
    case precipitationFormCodeE.DRIZZLESNOWFALL:
      return <RainSnow title="rainSnow" />;
    case precipitationFormCodeE.SNOWFALL:
      return <Snow title="snow" />;
    default:
      return <Sun title="sun" />;
  }
}

export function getWeatherLocationStr(weather: WeatherTypes) {
  let a = `${weather.location1}`;
  if (weather.location2) a += `/${weather.location2}`;
  if (weather.location3) a += `/${weather.location3}`;
  return a;
}
