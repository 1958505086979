import '@components/Common/Modal/styles/ModalBody.scss';
import * as S from '../styles/ScheduleManageStyle';
import React, { forwardRef } from 'react';
import { DayOfTheWeek, ScheduleTypes } from '@typedef/Schedule/schedule.types';
import { ContentTypes } from '@typedef/Contents/contents.types';
import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import dayjs from 'dayjs';
import { getFullDate } from '@libs/dateUtil';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import ko from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateTimeValidationError, DesktopDateTimePicker, TimePicker } from '@mui/x-date-pickers';

dayjs.extend(ko);
dayjs.extend(timezone);

type Props = {
  type: '추가' | '수정';
  inputs: ScheduleTypes;
  onRepetitionTypeChanged: (repetitionType: EScheduleRepetitionType) => void;
  onClickAddSchedule: () => void;
  onClickUpdateSchedule: () => void;
  onClickDeleteSchedule: () => void;
  onDayWeeksChanged: (day: DayOfTheWeek) => void;
  setScheduleData: React.Dispatch<React.SetStateAction<ScheduleTypes>>;
  timeTransform: (time: string) => number;
  contentList: ContentTypes[];
  onClickPreview: () => void;
  handleErrorMessage: (error: DateTimeValidationError) => void;
  errorMessage: string | null;
};

const ScheduleManage = ({
  type,
  inputs,
  onRepetitionTypeChanged,
  onClickAddSchedule,
  onClickUpdateSchedule,
  onClickDeleteSchedule,
  setScheduleData,
  onDayWeeksChanged,
  contentList,
  onClickPreview,
  handleErrorMessage,
  errorMessage,
}: Props) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button className="datepicker-custom-input" onClick={onClick} ref={ref}>
      {(value as string).length !== 0 ? value : '날짜를 선택해주세요.'}
    </button>
  ));

  return (
    <S.Container className="modalBody">
      <article className="contents">
        <div className="split">
          <p className="subtitle">컨텐츠 선택</p>
          {inputs.contentId && (
            <button onClick={onClickPreview} className="preview-btn">
              미리보기
            </button>
          )}
          <select
            id={inputs.contentId.toString()}
            value={inputs.contentId}
            onChange={(e) => {
              setScheduleData({
                ...inputs,
                contentId: e.target.value,
              });
            }}
          >
            <option>선택</option>
            {contentList.map((content) => (
              <option value={content.id}>{content.name}</option>
            ))}
          </select>
        </div>
        <div className="split">
          <p className="subtitle">반복 유형</p>
          <S.Weeks>
            {EScheduleRepetitionType.values.map((repetitionType) => (
              <S.Week
                key={repetitionType.value}
                active={inputs.repetitionType.equals(repetitionType.value)}
                onClick={() => onRepetitionTypeChanged(repetitionType)}
              >
                {repetitionType.name}
              </S.Week>
            ))}
          </S.Weeks>
        </div>

        {inputs.repetitionType && (
          <>
            <div className="split time">
              <p className="subtitle">
                {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) ? '시작시간' : '시작날짜'}
              </p>
              <S.Times>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                  {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label={'시작시간'}
                        format={'HH:mm'}
                        maxTime={
                          inputs.endTime
                            ? dayjs(getFullDate(inputs.endDate, inputs.endTime)).subtract(1, 'minute')
                            : null
                        }
                        value={inputs.startDate ? dayjs(getFullDate(inputs.startDate, inputs.startTime)) : null}
                        onChange={(value) => {
                          setScheduleData({
                            ...inputs,
                            startDate: dayjs(value).format('YYYY-MM-DD'),
                            startTime: dayjs(value).format('HH:mm'),
                          });
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <DesktopDateTimePicker
                      label={'시작날짜'}
                      format={'YYYY년 MM월 DD일 HH:mm'}
                      maxDateTime={
                        inputs.endTime ? dayjs(getFullDate(inputs.endDate, inputs.endTime)).subtract(1, 'minute') : null
                      }
                      value={inputs.startDate ? dayjs(getFullDate(inputs.startDate, inputs.startTime)) : null}
                      onChange={(value) => {
                        setScheduleData({
                          ...inputs,
                          startDate: dayjs(value).format('YYYY-MM-DD'),
                          startTime: dayjs(value).format('HH:mm'),
                        });
                      }}
                    />
                  )}
                </LocalizationProvider>
              </S.Times>
            </div>

            <div className={'split'} />

            <div className="split time">
              <p className="subtitle">
                {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) ? '종료시간' : '종료날짜'}
              </p>
              <S.Times>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                  {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label={'종료시간'}
                        format={'HH:mm'}
                        minTime={
                          inputs.startTime
                            ? dayjs(getFullDate(inputs.startDate, inputs.startTime)).add(1, 'minute')
                            : null
                        }
                        onError={(err: DateTimeValidationError) => handleErrorMessage(err)}
                        value={
                          inputs.endDate || inputs.dailyEndTime
                            ? dayjs(getFullDate(inputs.endDate, inputs.endTime ? inputs.endTime : inputs.dailyEndTime))
                            : null
                        }
                        onChange={(value) => {
                          setScheduleData({
                            ...inputs,
                            endDate: dayjs(value).format('YYYY-MM-DD'),
                            endTime: dayjs(value).format('HH:mm'),
                          });
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <DesktopDateTimePicker
                      label={'종료날짜'}
                      format={'YYYY년 MM월 DD일 HH:mm'}
                      minDateTime={
                        inputs.startTime
                          ? dayjs(getFullDate(inputs.startDate, inputs.startTime)).add(1, 'minute')
                          : null
                      }
                      onError={(err: DateTimeValidationError) => handleErrorMessage(err)}
                      value={inputs.endTime ? dayjs(getFullDate(inputs.endDate, inputs.endTime)) : null}
                      onChange={(value) => {
                        setScheduleData({
                          ...inputs,
                          endDate: dayjs(value).format('YYYY-MM-DD'),
                          endTime: dayjs(value).format('HH:mm'),
                        });
                      }}
                    />
                  )}
                </LocalizationProvider>
              </S.Times>
            </div>

            {inputs.repetitionType.equals(EScheduleRepetitionType.EVERY_WEEK) && (
              <>
                <div className={'split'} />
                <div className="split time">
                  <p className="subtitle">요일 설정</p>
                  <S.SelectWeeks repetitionDayOfTheWeek={inputs.repetitionDayOfTheWeek}>
                    <div className={'sun'} onClick={() => onDayWeeksChanged(DayOfTheWeek.SUN)}>
                      일
                    </div>
                    <div className={'mon'} onClick={() => onDayWeeksChanged(DayOfTheWeek.MON)}>
                      월
                    </div>
                    <div className={'tue'} onClick={() => onDayWeeksChanged(DayOfTheWeek.TUES)}>
                      화
                    </div>
                    <div className={'wed'} onClick={() => onDayWeeksChanged(DayOfTheWeek.WED)}>
                      수
                    </div>
                    <div className={'thu'} onClick={() => onDayWeeksChanged(DayOfTheWeek.THURS)}>
                      목
                    </div>
                    <div className={'fri'} onClick={() => onDayWeeksChanged(DayOfTheWeek.FRI)}>
                      금
                    </div>
                    <div className={'sat'} onClick={() => onDayWeeksChanged(DayOfTheWeek.SAT)}>
                      토
                    </div>
                  </S.SelectWeeks>
                </div>
              </>
            )}

            {!inputs.repetitionType.equals(EScheduleRepetitionType.ONCE) && (
              <>
                <div className="split" />
                <div className="split time">
                  <p className="subtitle">반복 종료 시간</p>
                  <S.Times>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                      <DesktopDateTimePicker
                        label={'종료날짜'}
                        format={'YYYY년 MM월 DD일 HH:mm'}
                        minDateTime={
                          inputs.endTime ? dayjs(getFullDate(inputs.endDate, inputs.endTime)).add(1, 'minute') : null
                        }
                        onError={(err: DateTimeValidationError) => handleErrorMessage(err)}
                        value={inputs.repetitionEndTime ? dayjs(inputs.repetitionEndTime) : null}
                        onChange={(value) => {
                          setScheduleData({
                            ...inputs,
                            repetitionEndTime: dayjs(value).format('YYYY-MM-DDTHH:MM'),
                          });
                        }}
                      />
                    </LocalizationProvider>
                  </S.Times>
                </div>
              </>
            )}
          </>
        )}

        {errorMessage !== null && <p className={'error-phrases'}>{errorMessage}</p>}
      </article>
      <article className="bottoms">
        {type === '수정' ? (
          <>
            <button onClick={onClickUpdateSchedule}>{type}하기</button>
            <button className="danger" onClick={onClickDeleteSchedule}>
              삭제하기
            </button>
          </>
        ) : (
          <button onClick={onClickAddSchedule}>{type}하기</button>
        )}
      </article>
    </S.Container>
  );
};

export default ScheduleManage;
