import * as S from './styles/ListStyle';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { metaCitySearchOptions, SurveysType } from '@typedef/Monitoring/monitoring.type';
import SearchTableContainer from '@components/Common/SearchTable/containers/SearchTableContainer';
import { getWeatherLocationStr } from '@libs/weatherUtils';
import { PageTypes } from '@typedef/libs/pagination.types';
import Pagination from '@components/Common/Pagination/Pagination';

type Props = {
  surveysData: SurveysType[];
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;

  surveyListExcelDownload: () => void;
};

const MetaCityList = ({
  surveysData,
  pageInfo,
  currentPage,
  setCurrentPage,
  surveyListExcelDownload,
  onPageChange,
}: Props) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.Header>
        <div className={'title'}>
          <div onClick={() => navigate('/monitoring')}>통합관제모니터링</div>
          <div>메타시티 방문자 조회</div>
        </div>
        <div className="search">
          <button
            className={'download-button'}
            onClick={() => {
              if (surveysData.length <= 0) return alert('저장할 데이터가 없습니다.');
              surveyListExcelDownload();
            }}
          >
            엑셀로 저장
          </button>
          <SearchTableContainer searchOptions={metaCitySearchOptions} />
        </div>
      </S.Header>
      <S.Article>
        <div className={'background'}>
          <div className="tableHeader">
            <table>
              <colgroup>
                <col width="5%" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>방문 날짜</th>
                  <th>성별</th>
                  <th>거주지역</th>
                  <th>방문 목적</th>
                  <th>나이대</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tableContent">
            <table>
              <colgroup>
                <col width="5%" />
              </colgroup>
              <tbody>
                {surveysData.map((data, i) => (
                  <S.Tr $checked={false}>
                    <td>{i + 1 + (currentPage - 1) * 10}</td>
                    <td>
                      {!data.createdAt ? <span>데이터 없음</span> : dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                    <td>{!data.gender ? <span>데이터 없음</span> : data.gender.name}</td>
                    <td>{!data.weather ? <span>데이터 없음</span> : getWeatherLocationStr(data.weather)}</td>
                    <td>{!data.visitType ? <span>데이터 없음</span> : data.visitType.name}</td>
                    <td>{!data.ageGroup ? <span>데이터 없음</span> : data.ageGroup.name}</td>
                  </S.Tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          onPageChange={onPageChange}
          pageInfo={pageInfo}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </S.Article>
    </S.Container>
  );
};

export default MetaCityList;
