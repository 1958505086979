import ReactDropdown from 'react-dropdown';
import * as S from '@components/Visit/styles/VisitStyle';
import React from 'react';
import { VisitInputColumnTypes } from '@typedef/Visit/visit-input-column.types';

type Props<T> = VisitInputColumnTypes<T> & {
  inputs: T;
  onInputsChanged: <K extends keyof T>(key: K, value: T[K]) => void;
};

export const VisitInputColumn = <T extends {}>({
  title,
  columnKey,
  type,
  required,
  placeHolder,
  options,
  inputs,
  onInputsChanged,
}: Props<T>) => {
  return (
    <S.InputContainer key={`inputColumn_${columnKey as string}`}>
      {title} {required && <span style={{ color: 'red' }}>*</span>}
      {(type === 'text' || type === 'number') && (
        <input
          type={type}
          placeholder={placeHolder ?? title}
          value={inputs[columnKey] as string | undefined}
          onChange={(event) => onInputsChanged(columnKey, event.target.value as T[keyof T])}
        />
      )}
      {type === 'dropdown' && (
        <ReactDropdown
          placeholder=" "
          controlClassName="dropdown"
          placeholderClassName="dropdown-placeholder"
          arrowClassName="dropdown-arrow"
          options={options!}
          value={inputs[columnKey] as string | undefined}
          onChange={(option) => onInputsChanged(columnKey, option.value as T[keyof T])}
        />
      )}
      {type === 'check' && (
        <div className="check-container">
          <S.CheckButton
            type="button"
            checked={inputs[columnKey] as boolean | undefined}
            onClick={() => onInputsChanged(columnKey, true as T[keyof T])}
          >
            O
          </S.CheckButton>
          <S.CheckButton
            type="button"
            checked={!inputs[columnKey] as boolean | undefined}
            onClick={() => onInputsChanged(columnKey, false as T[keyof T])}
          >
            X
          </S.CheckButton>
        </div>
      )}
      {type === 'multi-button' && (
        <div className="check-container">
          {options?.map((option) => (
            <S.CheckButton
              type="button"
              checked={inputs[columnKey] === option.value}
              onClick={() => onInputsChanged(columnKey, option.value as T[keyof T])}
            >
              {option.label}
            </S.CheckButton>
          ))}
        </div>
      )}
    </S.InputContainer>
  );
};
