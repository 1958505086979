import WidgetContainer from '@components/Widgets/containers/WidgetContainer';
import * as S from './styles/ContentSettingStyle';

const ContentSetting = () => {
  return (
    <S.Container>
      <WidgetContainer />
    </S.Container>
  );
};

export default ContentSetting;
