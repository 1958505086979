import styled, { css } from 'styled-components';
import { WidgetStyleProps } from '@typedef/Widget/widget.types';
import { getFontSize } from '@libs/getFontStyle';

const getResolutionStyle = (
  resolution: string,
  isSetting: boolean | undefined,
  width: number,
  height: number,
  contentWidth: number,
  contentHeight: number,
): string => {
  switch (resolution) {
    case '16:9':
    case '4:3':
      return `
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > div:first-child {
          width: 85%;
          height: 80%;
          display: flex;

          & > div {
            &:first-child {
              width: 85%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              & > .title {
                font-size: calc(3 * ${getFontSize(width, height, { w: contentWidth, h: contentWidth }, isSetting!)});
              }

              & > .temperature {
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;
                height: 80%;

                & > .error {
                  font-size: calc(4 * ${getFontSize(
                    width,
                    height,
                    { w: contentWidth, h: contentWidth },
                    isSetting!,
                  )}) !important;
                  color: #fff !important;
                }

                & > div {
                  &:first-child {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-end;

                    & > div {
                      &:first-child {
                        font-size: calc(
                          ${isSetting ? 3.3 : 4.5} *
                            ${getFontSize(
                              width,
                              height,
                              {
                                w: contentWidth,
                                h: contentHeight,
                              },
                              isSetting!,
                            )}
                        );
                        font-weight: bold;
                      }

                      &:last-child {
                        margin-left: 5%;
                        margin-top: 3%;
                        font-size: calc(
                          ${isSetting ? 1.1 : 1.7} *
                            ${getFontSize(
                              width,
                              height,
                              {
                                w: contentWidth,
                                h: contentHeight,
                              },
                              isSetting!,
                            )}
                        );
                        color: rgb(153, 180, 201);
                      }
                    }
                  }

                  &:last-child {
                    font-size: calc(
                      2 *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    color: rgb(187, 222, 245);
                  }
                }
              }
            }

            &:last-child {
              width: 35%;
              display: flex;
              align-items: center;
              justify-content: center;

              & > svg {
                width: 90%;
                height: 90%;
              }
            }
          }
        }
      `;
    case '32:9':
      return `
        justify-content: center;
        align-items: center;

        & > div:first-child {
          width: 95%;
          height: 80%;
          display: flex;

          & > div {
            &:first-child {
              width: 85%;
              // background: #faa;

              & > .title {
                width: auto;
                font-size: calc(2 * ${getFontSize(width, height, { w: contentWidth, h: contentWidth }, isSetting!)});
              }

              & > .temperature {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80%;
                width: 100%;

                & > .error {
                  font-size: calc(4 * ${getFontSize(
                    width,
                    height,
                    { w: contentWidth, h: contentWidth },
                    isSetting!,
                  )}) !important;
                  color: #fff !important;
                }

                & > div {
                  width: 60%;

                  &:first-child {
                    display: flex;

                    & > div {
                      &:first-child {
                        font-size: calc(
                          ${isSetting ? 3.5 : 4.5} *
                            ${getFontSize(
                              width,
                              height,
                              {
                                w: contentWidth,
                                h: contentHeight,
                              },
                              isSetting!,
                            )}
                        );
                        font-weight: bold;
                      }

                      &:last-child {
                        margin-left: 3%;
                        margin-top: auto;

                        font-size: calc(
                          ${isSetting ? 1.4 : 1.8} *
                            ${getFontSize(
                              width,
                              height,
                              {
                                w: contentWidth,
                                h: contentHeight,
                              },
                              isSetting!,
                            )}
                        );
                        color: rgb(153, 180, 201);
                      }
                    }
                  }

                  &:last-child {
                    font-size: calc(
                      ${isSetting ? 1.8 : 2.5} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    color: rgb(187, 222, 245);
                  }
                }
              }
            }

            &:last-child {
              width: 17%;
              display: flex;
              align-items: center;
              justify-content: center;

              & > svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      `;
    case '1:1':
      return `
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > div {
          width: 90%;
          height: 95%;

          & > div {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:first-child {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              & > .title {
                margin-right: auto;
                font-size: calc(2.5 * ${getFontSize(width, height, { w: contentWidth, h: contentWidth }, isSetting!)});
              }

              & > .icon-container {
                width: 100%;
                height: 35%;
                display: flex;
                justify-content: center;
                align-items: center;

                & > svg {
                  height: 100%;
                  width: 100%;
                }
              }

              & > .temperature {
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;
                height: 80%;
                width: 100%;

                & > .error {
                  font-size: calc(4 * ${getFontSize(
                    width,
                    height,
                    { w: contentWidth, h: contentWidth },
                    isSetting!,
                  )}) !important;
                  color: #fff !important;
                }

                & > div {
                  &:first-child {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    & > div {
                      text-align: center;

                      &:first-child {
                        font-size: calc(
                          ${isSetting ? 4 : 5.5} *
                            ${getFontSize(
                              width,
                              height,
                              {
                                w: contentWidth,
                                h: contentHeight,
                              },
                              isSetting!,
                            )}
                        );
                        font-weight: bold;
                      }

                      &:last-child {
                        margin-left: 5%;
                        font-size: calc(
                          ${isSetting ? 1.5 : 2} *
                            ${getFontSize(
                              width,
                              height,
                              {
                                w: contentWidth,
                                h: contentHeight,
                              },
                              isSetting!,
                            )}
                        );
                        color: rgb(153, 180, 201);
                      }
                    }
                  }

                  &:last-child {
                    text-align: center;
                    font-size: calc(
                      1.5 *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    color: rgb(187, 222, 245);
                  }
                }
              }
            }
          }
        }
      `;

    case '9:16':
      return `
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > div {
          width: 90%;
          height: 95%;
          flex-direction: column;
          justify-content: space-between;
          display: flex;
          align-items: center;

          & > div {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:first-child {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              & > .title {
                margin-right: auto;
                font-size: calc(2.5 * ${getFontSize(width, height, { w: contentWidth, h: contentWidth }, isSetting!)});
              }

              & > .icon-container {
                width: 100%;
                height: 25%;
                display: flex;
                justify-content: center;
                align-items: center;

                & > svg {
                  height: 100%;
                  width: 100%;
                }
              }

              & > .temperature {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 40%;
                width: 100%;

                & > .error {
                  font-size: calc(4 * ${getFontSize(
                    width,
                    height,
                    { w: contentWidth, h: contentWidth },
                    isSetting!,
                  )}) !important;
                  color: #fff !important;
                }

                & > div {
                  &:first-child {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    & > div {
                      text-align: center;

                      &:first-child {
                        font-size: calc(
                          ${isSetting ? 3 : 4} *
                            ${getFontSize(
                              width,
                              height,
                              {
                                w: contentWidth,
                                h: contentHeight,
                              },
                              isSetting!,
                            )}
                        );
                        font-weight: bold;
                      }

                      &:last-child {
                        margin-left: 5%;
                        font-size: calc(
                          ${isSetting ? 1.2 : 1.8} *
                            ${getFontSize(
                              width,
                              height,
                              {
                                w: contentWidth,
                                h: contentHeight,
                              },
                              isSetting!,
                            )}
                        );
                        color: rgb(153, 180, 201);
                      }
                    }
                  }

                  &:last-child {
                    text-align: center;
                    font-size: calc(
                      1.5 *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    color: rgb(187, 222, 245);
                    margin-bottom: 10%;
                  }
                }
              }
            }
          }
        }
      `;
    default:
      return '';
  }
};

const BaseStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(rgb(8, 77, 144), rgb(139, 178, 220));
`;

export const WeatherContainer = styled.div<WidgetStyleProps>`
  ${BaseStyle};
  ${({ resolution, isSetting, width, height, contentWidth, contentHeight }) =>
    `${getResolutionStyle(resolution, isSetting, width, height, contentWidth, contentHeight)}`};

  * {
    color: #fff;
  }
`;

export const WeatherHandlingContainer = styled.div`
  ${BaseStyle};
  align-items: center;
  justify-content: center;
  color: #fff;

  * {
    font-size: 14px;
    font-weight: 600;
  }
`;
