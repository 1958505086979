import {
  WidgetFineDust,
  WidgetHeader,
  WidgetMain,
  WidgetStock,
  WidgetSubtitle,
  WidgetSubtitleNews,
  WidgetTime,
  WidgetWeather,
  WidgetWidgets,
} from '@assets/widgetIcons';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';

export function getWidgetIcon(type: EWidgetType) {
  switch (type) {
    case EWidgetType.HEADER:
      return <WidgetHeader title="Header" />;
    case EWidgetType.MAIN:
      return <WidgetMain title="MAIN" />;
    case EWidgetType.SUB:
      return <WidgetSubtitle title="SUB" />;
    case EWidgetType.BLOCK:
      return <WidgetWidgets title="BLOCK" />;
    case EWidgetType.NEWS:
      return <WidgetSubtitleNews title="NEWS" />;
    case EWidgetType.WEATHER:
      return <WidgetWeather title="WEATHER" />;
    case EWidgetType.TIME:
      return <WidgetTime title="TIME" />;
    case EWidgetType.FINEDUST:
      return <WidgetFineDust title="FINEDUST" />;
    case EWidgetType.STOCK_INDEX:
      return <WidgetStock title="STOCK" />;
  }
}
