import { isNumberFromString } from '@libs/inputValidator';
import '../styles/CustomStyle.scss';
import { WidgetTypes } from '@typedef/Widget/widget.types';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: WidgetTypes | null;
};

const CustomOpacity = ({ handleOnChange, selectLayout }: Props) => {
  return (
    <div className="custom">
      <div className="background-opacity">
        <p className="title">배경 불투명도(%)</p>
        <input
          type="text"
          value={selectLayout?.opacity ?? 0}
          // max={100}
          onChange={(e) => {
            if (isNumberFromString(e.target.value)) {
              if (Number(e.target.value) > 100) handleOnChange('opacity', 100);
              else handleOnChange('opacity', Number(e.target.value));
            }
          }}
        />
      </div>
    </div>
  );
};

export default CustomOpacity;
