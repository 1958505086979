import { AccountTypes } from '@typedef/Account/account.types';
import React from 'react';
import './styles/Login.scss';

type Props = {
  account: AccountTypes;
  setAccount: React.Dispatch<React.SetStateAction<AccountTypes>>;
  signUpStatus: boolean;
  setSignUpStatus: React.Dispatch<React.SetStateAction<boolean>>;
  signIn: () => void;
  signUp: () => void;
  accountIdDuplicate: () => void;
  companyCheck: () => void;
  setIdDuplicateFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setCompanyCheckFlag: React.Dispatch<React.SetStateAction<boolean>>;
};

const Login = ({
  account,
  setAccount,
  signUpStatus,
  setSignUpStatus,
  signIn,
  signUp,
  accountIdDuplicate,
  companyCheck,
  setIdDuplicateFlag,
  setCompanyCheckFlag,
}: Props) => {
  return (
    <main className="loginContainer">
      <header>{signUpStatus ? '회원가입' : '로그인'}</header>
      {signUpStatus ? (
        // 회원가입
        <section className="joinWrapper">
          <article className="inputWrapper">
            <p>
              사명<span>*</span>
            </p>
            <div className="inputs">
              <input
                type="text"
                value={account.company.name}
                onChange={(e) => {
                  setCompanyCheckFlag(false);
                  setAccount({ ...account, company: { ...account.company, name: e.target.value } });
                }}
                autoFocus
                placeholder="사명"
              />
              <button onClick={companyCheck}>등록 확인</button>
            </div>
          </article>
          <article className="inputWrapper">
            <p>
              아이디<span>*</span>
            </p>
            <div className="inputs">
              <input
                type="text"
                value={account.userId}
                onChange={(e) => {
                  setIdDuplicateFlag(false);
                  setAccount({ ...account, userId: e.target.value });
                }}
                autoFocus
                placeholder="아이디"
              />
              <button onClick={accountIdDuplicate}>중복확인</button>
            </div>
          </article>
          <article className="inputWrapper">
            <p>
              비밀번호<span>*</span>
            </p>
            <input
              type="password"
              value={account.password}
              onChange={(e) => {
                setAccount({ ...account, password: e.target.value });
              }}
              placeholder="비밀번호"
            />
          </article>
          <article className="inputWrapper">
            <p>
              사용자명<span>*</span>
            </p>
            <input
              type="text"
              value={account.userName}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  signUp();
                }
              }}
              onChange={(e) => {
                setAccount({ ...account, userName: e.target.value });
              }}
              placeholder="사용자명"
            />
          </article>
        </section>
      ) : (
        // 로그인
        <section className="loginWrapper">
          <input
            type="text"
            value={account.userId}
            onChange={(e) => {
              setAccount({ ...account, userId: e.target.value });
            }}
            autoFocus
            placeholder="아이디"
          />
          <input
            type="password"
            value={account.password}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                signIn();
              }
            }}
            onChange={(e) => {
              setAccount({ ...account, password: e.target.value });
            }}
            placeholder="비밀번호"
          />
        </section>
      )}
      {signUpStatus ? (
        <section className="btns">
          <button onClick={signUp} className="brand">
            회원가입
          </button>
          <button
            onClick={() => {
              setSignUpStatus(false);
            }}
          >
            로그인하기
          </button>
        </section>
      ) : (
        <section className="btns">
          <button onClick={signIn} className="brand">
            로그인
          </button>
          <button
            onClick={() => {
              setSignUpStatus(true);
            }}
          >
            회원가입하기
          </button>
        </section>
      )}
    </main>
  );
};

export default Login;
