import { WidgetTypes } from '@typedef/Widget/widget.types';
import { EFileType } from '@typedef/Widget/file-type.enum';
import { EFileStreamingType } from '@typedef/Widget/file-streaming-type.enum';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import { FILE_DOWNLOAD_URL, FILE_STREAMING_URL } from '@libs/api';

type props = {
  widget: WidgetTypes;
};

const FileWidget = ({ widget }: props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: Number(widget.autoPlaySpeed ?? 0) * 1000,
    nextArrow: <></>,
    prevArrow: <></>,
    pauseOnHover: false,
  };

  return (
    <>
      {EWidgetType.FILE.equals(widget.type) &&
        EFileType.IMAGE.validate(widget.file!) &&
        !widget.files?.length &&
        (EFileStreamingType.STREAMING.equals(widget.fileStreamingType!) ? (
          <img src={`${FILE_DOWNLOAD_URL}/${widget.file}`} alt={widget.i} />
        ) : (
          <img src={widget.fileData!} alt={widget.i} />
        ))}

      {EWidgetType.FILE.equals(widget.type) &&
        EFileType.VIDEO.validate(widget.file!) &&
        (EFileStreamingType.STREAMING.equals(widget.fileStreamingType!) ? (
          <video src={`${FILE_STREAMING_URL}/${widget.file}`} autoPlay={true} muted={true} loop={true} />
        ) : (
          <video src={widget.fileData!} autoPlay={true} muted={true} loop={true} />
        ))}

      {EWidgetType.FILE_LIST.equals(widget.type) && EFileType.SLIDEIMAGE.validate(widget.file!) ? (
        EFileStreamingType.STREAMING.equals(widget.fileStreamingType!) ? (
          <CustomSlider {...settings}>
            <img src={`${FILE_DOWNLOAD_URL}/${widget.file}`} alt={widget.i} />
            {widget.files?.map((value: any, index: number) => (
              <img src={`${FILE_DOWNLOAD_URL}/${value}`} key={index} alt={widget.i} />
            ))}
          </CustomSlider>
        ) : (
          <img src={`${FILE_DOWNLOAD_URL}/${widget.file}`} alt={widget.i} />
        )
      ) : (
        <></>
      )}
    </>
  );
};

const CustomSlider = styled(Slider)`
  width: 100%;
  height: 100%;

  * {
    width: 100%;
    height: 100%;
  }
`;

export default FileWidget;
