import { deciceSearchOptions, DeviceImageMapTypes, DeviceTypes } from '@typedef/Device/device.types';
import * as S from './styles/MainStyle';
import SearchTableContainer from '@components/Common/SearchTable/containers/SearchTableContainer';
import Pagination from '@components/Common/Pagination/Pagination';
import { PageTypes } from '@typedef/libs/pagination.types';
import { FILE_STREAMING_URL } from '@libs/api';

type Props = {
  devices: DeviceTypes[];
  deviceImageMap: DeviceImageMapTypes;
  onDeviceClick: (device: DeviceTypes) => void;
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  onSearchSummit: () => void;
};

const Main = ({
  devices,
  deviceImageMap,
  onDeviceClick,
  pageInfo,
  setCurrentPage,
  currentPage,
  onPageChange,
  onSearchSummit,
}: Props) => {
  return (
    <>
      <S.Container>
        <S.MainHeader>
          전광판 현황 <SearchTableContainer searchOptions={deciceSearchOptions} onSummit={onSearchSummit} />
        </S.MainHeader>
        <S.Main>
          {devices.length ? (
            <S.DeviceList>
              {devices.map((device) => (
                <S.Device onClick={() => onDeviceClick(device)}>
                  <S.DeviceName>
                    <div>{device.name}</div>
                    <S.DeviceStatus status={device.status?.data ?? false}>
                      {device.status ? 'ON' : 'OFF'}
                    </S.DeviceStatus>
                  </S.DeviceName>

                  <S.ContentName>{deviceImageMap.get(device.id)?.contentsName ?? '동작중인 컨텐츠 없음'}</S.ContentName>
                  <S.DeviceImageBox>
                    {device.status?.data ? (
                      deviceImageMap.get(device.id)?.fileUrl?.length === 0 ? (
                        <S.DeviceNotWork>동작중인 스케줄이 없습니다.</S.DeviceNotWork>
                      ) : (
                        <S.DeviceImage
                          src={`${FILE_STREAMING_URL}/${deviceImageMap.get(device.id)?.fileUrl}`}
                          alt={`${device.id}`}
                        />
                      )
                    ) : (
                      <S.DeviceNotWork>장비가 OFF 되어있습니다.</S.DeviceNotWork>
                    )}
                    <S.ImageSize>{`${device.width} X ${device.height}`}</S.ImageSize>
                  </S.DeviceImageBox>
                </S.Device>
              ))}
            </S.DeviceList>
          ) : (
            <S.DeviceNothing>생성되어 있는 보드가 없습니다.</S.DeviceNothing>
          )}
        </S.Main>
        <Pagination
          onPageChange={onPageChange}
          pageInfo={pageInfo}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </S.Container>
    </>
  );
};

export default Main;
