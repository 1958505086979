import React, { useCallback, useEffect, useRef, useState } from 'react';
import { apiRoute, requestGet, requestPostFile } from '@libs/api';
import {
  DeviceResponseTypes,
  DeviceTypes,
  convertDeviceResponseTypeToType,
  defaultDeviceTypes,
} from '@typedef/Device/device.types';
import {
  GetSchedulesInputsDefault,
  GetSchedulesInputsTypes,
  ScheduleResponseTypes,
  ScheduleTypes,
} from '@typedef/Schedule/schedule.types';
import { ContentTypes, defaultContentTypes } from '@typedef/Contents/contents.types';
import { useMatch } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { useSetRecoilState } from 'recoil';
import useDeviceScheduleState from '@hooks/useDeviceScheduleState';
import { createWidget, RawWidgetTypes, WidgetTypes } from '@typedef/Widget/widget.types';
import { modalSelector } from '@stories/modal';
import { LoadingModalContainer } from '@components/Widgets/styles/WidgetStyle';
import Spinner from '@components/Common/Spinner/Spinner';
import Monitor from '@components/Monitor/Monitor';
import { useFullScreenHandle } from 'react-full-screen';
import ms from 'ms';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { useInterval } from 'usehooks-ts';

const MonitorContainer = () => {
  const match = useMatch('/monitor/:uniqueKey');
  const uniqueKey = match?.params.uniqueKey;

  const [scheduleList, setScheduleList] = useState<ScheduleTypes[]>([]);
  const [widgetList, setWidgetList] = useState<WidgetTypes[]>([]);
  const [currentWidgetList, setCurrentWidgetList] = useState<RawWidgetTypes[]>([]);

  const { updateScheduleWithDeviceId } = useDeviceScheduleState();

  // 레이아웃 파일 호출 후 로딩 스피너 표시용
  const [layoutLoading, setLayoutLoading] = useState<boolean>(true);

  const [isNetworkError, setIsNetworkError] = useState<boolean>(false);

  // 현재 레이아웃 width, height 값
  const [rowWidth, setRowWidth] = useState<number>(0);
  const [colHeight, setColHeight] = useState<number>(0);
  const [noticeMessage, setNoticeMessage] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [content, setContent] = useState<ContentTypes>(defaultContentTypes);
  const [device, setDevice] = useState<DeviceTypes>(defaultDeviceTypes);
  const setModal = useSetRecoilState(modalSelector);

  const monitorRef = useRef<HTMLDivElement>(null);
  // 스케줄 컨텐츠 번호 불러오기
  const loadContent = async (contentId: string) => {
    const { data, config } = await requestGet<ContentTypes>(apiRoute.contents.getContentsWithoutJWS + contentId, {});

    setIsNetworkError(config.networkError);

    if (config.status !== 200) {
      setNoticeMessage('스케줄에 컨텐츠가 없습니다.');
      setIsActive(false);
      return;
    }
    setModal({
      header: '컨텐츠 로딩 중...',
      close: false,
      body: (
        <LoadingModalContainer>
          <Spinner width={100} height={100} size={50} />
          <div className={'loading-text'}>{'컨텐츠를 로딩 중입니다...'}</div>
        </LoadingModalContainer>
      ),
    });

    setContent(data);
    loadWidgetList(contentId);

    setModal(null);
  };

  const loadWidgetList = async (contentId: string) => {
    const { data, config } = await requestGet<RawWidgetTypes[]>(
      apiRoute.widget.getWidgetListWithContentIdWithoutJWT + contentId,
      {},
    );

    setIsNetworkError(config.networkError);

    if (config.status !== 200) {
      setNoticeMessage('컨텐츠에 위젯이 없습니다.');
      setIsActive(false);
      return;
    }

    setCurrentWidgetList(data);

    if (JSON.stringify(data) !== JSON.stringify(currentWidgetList)) {
      setWidgetList(
        (await Promise.all(data.map(async (rawWidget) => createWidget(rawWidget)))).sort(
          (a, b) => a.priority - b.priority,
        ),
      );
    }
  };

  // 장비 스케줄리스트 불러오기
  const loadScheduleList = async (deviceId?: string) => {
    if (!navigator.onLine) return;
    if (!deviceId) return;

    const getSchedulesInputs: GetSchedulesInputsTypes = {
      ...GetSchedulesInputsDefault,
      deviceId,
      paged: false,
    };

    const { data, config } = await requestGet<PaginationTypes<ScheduleResponseTypes>>(
      `${apiRoute.schedule.getScheduleDeviceWithoutJWT}${parseQueryParamsToString(getSchedulesInputs)}`,
      {},
    );

    setIsNetworkError(config.networkError);

    if (config.status !== 200) return;

    let v = await updateScheduleWithDeviceId(deviceId);

    if (v.status) {
      setIsActive(true);
      loadContent(v.contentId);
    } else {
      setNoticeMessage('현재 동작중인 스케줄이 없습니다.');
      setIsActive(false);
    }
  };

  // 스케줄 시작시 reload
  const reloadScheduleStarting = useCallback(() => {}, [scheduleList]);

  // 화면 크기 변함에 따라 전체 width값 및 height값 변경
  const handleResize = useCallback(() => {
    setRowWidth((monitorRef.current?.clientWidth ?? 0) / (content.w / 20));
    setColHeight((monitorRef.current?.clientHeight ?? 0) / (content.h / 20));
  }, [monitorRef, widgetList, content]);

  const saveFile = async (id?: string) => {
    if (!isActive) return;
    if (!navigator.onLine) return;
    if (!id) return;
    if (isNetworkError) return;

    const target = document.getElementById('screen-shot-target');

    if (!target) return;

    const canvas = await html2canvas(target);
    const dataURL = canvas.toDataURL(); // 기본적으로 PNG 형식으로 인코딩됩니다.
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const file = new File([uint8Array], `${id}_thumnail.png`, {
      type: mimeString,
    });
    const formData = new FormData();

    formData.append('file', file);

    const { config } = await requestPostFile(apiRoute.devices.uploadDeviceImageWithoutJWT + '?id=' + id, {}, formData);

    setIsNetworkError(config.networkError);
  };

  const handle = useFullScreenHandle();
  const handleFullScreen = () => [handle.active ? handle.exit() : handle.enter()];

  useEffect(() => {
    if (!(scheduleList.length > 0)) return;

    const intervalId = setInterval(reloadScheduleStarting, 1000);

    return () => clearInterval(intervalId);
  }, [scheduleList, reloadScheduleStarting]);

  useEffect(() => {
    let saveFileTimeoutID: NodeJS.Timer;

    void (async () => {
      const { config, data } = await requestGet<DeviceResponseTypes>(
        apiRoute.devices.getDeviceDetailWithoutJWS + uniqueKey,
        {},
      );

      setIsNetworkError(config.networkError);

      if (config.status === 200) {
        loadScheduleList(data.id ?? '');
        setDevice(convertDeviceResponseTypeToType(data));

        saveFileTimeoutID = setTimeout(() => saveFile(data.id), ms('10s'));
      } else {
        setNoticeMessage('존재하지않은 유니크키입니다.');
        setIsActive(false);
      }
    })();

    return () => {
      clearTimeout(saveFileTimeoutID);
    };
  }, []);

  useInterval(() => {
    saveFile(device.id);
  }, ms('1m'));
  useInterval(() => {
    loadScheduleList(device.id);
  }, ms('10s'));

  // 현재 화면 크기 실시간 감지
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <Monitor
      widgetList={widgetList}
      monitorRef={monitorRef}
      rowWidth={rowWidth}
      colHeight={colHeight}
      handleFullScreen={handleFullScreen}
      handle={handle}
      content={content}
      device={device}
      isActive={isActive}
      noticeMessage={noticeMessage}
    />
  );
};

export default MonitorContainer;
