import { ArrowLeft, ArrowRight, ArrowsLeft, ArrowsRight } from '@assets/icons';
import { calculateStartPage } from '@libs/page';
import { PaginationButton } from '@components/Common/Pagination/styles/paginationStyle';
import { PageTypes } from '@typedef/libs/pagination.types';
import styled from 'styled-components';
import React from 'react';

type Props = {
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  isPlayHistory?: boolean;
};

const Pagination = ({ pageInfo, currentPage, setCurrentPage, onPageChange, isPlayHistory }: Props) => {
  const PaginationContainer = isPlayHistory ? PlayHistoryPagination : DefaultPagination;

  return (
    <PaginationContainer>
      <ArrowsLeft
        className="arrows"
        onClick={() => {
          if (currentPage === 1) return;

          if (currentPage - 5 >= 1) {
            currentPage !== 1 && onPageChange(calculateStartPage(currentPage - 5, 5));
            setCurrentPage(calculateStartPage(currentPage - 5, 5));
          } else {
            currentPage !== 1 && onPageChange(1);
            setCurrentPage(1);
          }
        }}
      />
      <ArrowLeft
        onClick={() => {
          if (currentPage === 1) return;

          if (currentPage - 1 >= 1) {
            onPageChange(currentPage - 1);
            setCurrentPage(currentPage - 1);
          } else {
            onPageChange(1);
            setCurrentPage(1);
          }
        }}
      />

      {[...Array(5)].map((_, i) => {
        const startPage = calculateStartPage(currentPage, 5);

        return (
          startPage + i <= pageInfo.totalPages && (
            <PaginationButton
              key={i}
              $active={startPage + i === currentPage}
              onClick={() => {
                if (startPage + i === currentPage) return;
                onPageChange(startPage + i);
                setCurrentPage(startPage + i);
              }}
            >
              {startPage + i}
            </PaginationButton>
          )
        );
      })}

      <ArrowRight
        onClick={() => {
          if (currentPage == pageInfo.totalPages) return;

          if (currentPage + 1 <= pageInfo.totalPages) {
            onPageChange(currentPage + 1);
            setCurrentPage(currentPage + 1);
          } else {
            onPageChange(pageInfo.totalPages);
            setCurrentPage(pageInfo.totalPages);
          }
        }}
      />
      <ArrowsRight
        className="arrows"
        onClick={() => {
          if (currentPage == pageInfo.totalPages) return;

          if (currentPage + 5 <= pageInfo.totalPages) {
            onPageChange(calculateStartPage(currentPage + 5, 5));
            setCurrentPage(calculateStartPage(currentPage + 5, 5));
          } else {
            onPageChange(pageInfo.totalPages);
            setCurrentPage(pageInfo.totalPages);
          }
        }}
      />
    </PaginationContainer>
  );
};

export default Pagination;

const DefaultPagination = styled.div`
  width: 100%;
  height: 35px;

  margin: 5vh auto;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrows {
    width: 40px;
    height: 40px;
    stroke: #256662;
  }

  & > svg {
    width: 35px;
    height: 35px;
  }

  & > svg:hover {
    border-radius: 5px;
    background-color: #e6e6e6;
  }
`;

const PlayHistoryPagination = styled(DefaultPagination)`
  height: 35px;
  margin-top: 10px;

  & > button {
    width: 30px;
    height: 30px;
    margin: 0 3px;
    font-size: 16px;
  }

  .arrows {
    width: 30px;
    height: 30px;
  }

  & > svg {
    width: 25px;
    height: 25px;
  }
`;
