import { Profile } from '@assets/icons';
import { accountSelector } from '@stories/account';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
const GNBProfile = () => {
  const userName = useRecoilValue(accountSelector).userName;

  const [visibleOptions, setVisibleOptions] = useState<boolean>(false);
  const navigate = useNavigate();

  const documentElement = document as Document;

  const fullScreenHandler = (type: 'maximize' | 'minimize') => {
    if (type === 'maximize') {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <section
      className="profile"
      onMouseLeave={() => {
        setVisibleOptions(false);
      }}
    >
      <ProfileButton
        onMouseOver={() => {
          setVisibleOptions(true);
        }}
      >
        <Profile title="profile" className="profile-image" />
      </ProfileButton>

      <div
        className="options"
        style={{
          visibility: visibleOptions ? 'visible' : 'hidden',
        }}
      >
        <ul>
          {isMobile && (
            <li>
              <button
                onClick={() => {
                  setVisibleOptions(false);
                  fullScreenHandler(documentElement.fullscreenElement ? 'minimize' : 'maximize');
                }}
              >
                {documentElement.fullscreenElement ? '전체화면 해제' : '전체화면'}
              </button>
            </li>
          )}
          <li>
            <button
              onClick={() => {
                setVisibleOptions(false);
                navigate(`profile`);
              }}
            >
              {userName}님
            </button>
          </li>
          <li
            onClick={() => {
              setVisibleOptions(false);
              window.localStorage.clear();
              window.location.href = '/';
            }}
          >
            로그아웃
          </li>
        </ul>
      </div>
    </section>
  );
};

const ProfileButton = styled.div`
  display: flex;
  width: 50px;
  height: 50px;

  align-items: center;
  justify-content: center;

  .profile-image {
    width: 30px;
    height: 30px;
  }
`;

export default GNBProfile;
