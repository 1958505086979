import styled from 'styled-components';
import MapControlStatus from '@components/Monitoring/components/MapControlStatus';
import { MonitoringDeviceType } from '@typedef/Monitoring/monitoring.type';
import React from 'react';
import { ZonePlaytimeType, ZoneTypes } from '@typedef/Monitoring/zone.type';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';

type Props = {
  deviceList: MonitoringDeviceType[];
  zonePlayTime: ZonePlaytimeType[];
  handleDevicePower: (powerOn: EDeviceStatus, ip: string, zone?: ZoneTypes) => void;
};

const Map: React.FC<Props> = ({ deviceList, zonePlayTime, handleDevicePower }) => {
  const getObjectStatus = (name: string, zoneName?: string): { status: EDeviceStatus; ip: string } => {
    const device = deviceList.find((item: MonitoringDeviceType) => {
      if (zoneName) {
        return item.name === name && item.zone?.name === zoneName;
      } else {
        return item.name === name;
      }
    });

    if (device) {
      return { status: device.status, ip: device.ip };
    } else {
      return { status: EDeviceStatus.OFF, ip: '' };
    }
  };

  const getObjectPlaytime = (name: string, zoneName?: string): ZonePlaytimeType | undefined => {
    return zonePlayTime.find((item: ZonePlaytimeType) => {
      if (zoneName) {
        return item.name === name && item.zoneName === zoneName;
      } else {
        return item.name === name;
      }
    });
  };
  const getObjectZone = (name: string) => {
    return deviceList.find((v) => v.name === name)?.zone;
  };

  return (
    <MapContainer>
      <div className={'hallway'}>
        <h3>복도</h3>
        <div>
          <MapControlStatus
            zoneName={'홍보 로봇'}
            zone={getObjectZone('로봇 전광판 단면')}
            statusOnly={true}
            power={getObjectStatus('로봇 전광판 단면')}
            handleDevicePower={handleDevicePower}
          />
        </div>
      </div>
      <div className={'senior'}>
        <h3>율동체크</h3>
        <div>
          <MapControlStatus
            zoneName={'율동체크'}
            zonePlayTime={getObjectPlaytime('VR 시니어 체험관', '율동체크')}
            zone={getObjectZone('VR 시니어 체험관')}
            power={getObjectStatus('VR 시니어 체험관', '율동체크')}
            handleDevicePower={handleDevicePower}
          />
        </div>
      </div>
      <div className={'riding'}>
        <h3>가상라이딩</h3>
        <div>
          <MapControlStatus
            zoneName={'가상라이딩 1번'}
            zonePlayTime={getObjectPlaytime('가상 사이클 1번')}
            zone={getObjectZone('가상 사이클 1번')}
            power={getObjectStatus('가상 사이클 1번')}
            handleDevicePower={handleDevicePower}
          />
        </div>
        <div>
          <MapControlStatus
            zoneName={'가상라이딩 2번'}
            zonePlayTime={getObjectPlaytime('가상 사이클 2번')}
            zone={getObjectZone('가상 사이클 2번')}
            power={getObjectStatus('가상 사이클 2번')}
            handleDevicePower={handleDevicePower}
          />
        </div>
      </div>
      <div className={'baby'}>
        <h3>키즈팡팡</h3>
        <div>
          <MapControlStatus
            zoneName={'키즈팡팡'}
            zonePlayTime={getObjectPlaytime('VR 유아 체험관')}
            zone={getObjectZone('VR 유아 체험관')}
            power={getObjectStatus('VR 유아 체험관')}
            handleDevicePower={handleDevicePower}
          />
        </div>
      </div>
      <div className={'family'}>
        <h3>모두의 스포츠</h3>
        <div>
          <MapControlStatus
            zoneName={'모두의 스포츠 1번'}
            zonePlayTime={getObjectPlaytime('모두의 스포츠 1번')}
            zone={getObjectZone('모두의 스포츠 1번')}
            power={getObjectStatus('모두의 스포츠 1번')}
            handleDevicePower={handleDevicePower}
          />
        </div>

        <div>
          <MapControlStatus
            zoneName={'모두의 스포츠 2번'}
            zonePlayTime={getObjectPlaytime('모두의 스포츠 2번')}
            zone={getObjectZone('모두의 스포츠 2번')}
            power={getObjectStatus('모두의 스포츠 2번')}
            handleDevicePower={handleDevicePower}
          />
        </div>
      </div>
      <div className={'hall'}>
        <h3>가상 LED</h3>
        <div className={'robot'}>
          <MapControlStatus
            zoneName={'출입관리 로봇'}
            zone={getObjectZone('양면 로봇 전광판 앞면')}
            statusOnly={true}
            power={getObjectStatus('양면 로봇 전광판 앞면')}
            handleDevicePower={handleDevicePower}
          />
        </div>
        <div className={'ar'}>
          <MapControlStatus
            zoneName={'가상 LED'}
            zonePlayTime={getObjectPlaytime('증강현실 체험')}
            zone={getObjectZone('증강현실 체험')}
            power={getObjectStatus('증강현실 체험')}
            handleDevicePower={handleDevicePower}
          />
        </div>
      </div>
    </MapContainer>
  );
};

const MapContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
  width: 1300px;
  height: 405px;
  grid-template: repeat(20, 1fr) / repeat(10, 1fr);
  display: grid;

  & > div {
    background: none;
    padding: 10px;

    h3 {
      font-size: 14px;
      color: #256662;
      font-weight: 600;
      position: absolute;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & > .hallway {
    grid-column: 1 / 8;
    grid-row: 1 / 5;

    & > div {
      width: 100%;
      height: 100%;
    }
  }

  & > .senior {
    grid-column: 2 / 4;
    grid-row: 5 / 13;

    & > div {
      width: 100%;
      height: 100%;
    }
  }

  & > .riding {
    grid-column: 4 / 8;
    grid-row: 5 / 12;
    position: relative;
    display: flex;
    justify-content: space-between;

    & > div {
      width: calc(50% - 6px);
      height: 100%;
    }
  }

  & > .hall {
    grid-column: 8 / 11;
    grid-row: 1 / 21;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    & > div {
      width: 100%;
      height: 30%;
    }

    .robot {
      height: 42%;
    }

    .ar {
      height: 55%;
    }
  }

  & > .baby {
    grid-column: 1 / 4;
    grid-row: 13 / 21;

    & > h3 {
      margin-left: 7px;
    }

    & > div {
      width: 100%;
      height: 100%;
    }
  }

  & > .family {
    grid-column: 4 / 8;
    grid-row: 12 / 21;
    position: relative;
    display: flex;
    justify-content: space-between;

    & > h3 {
      margin-top: 7px;
    }

    & > div {
      width: calc(50% - 6px);
      height: 100%;
    }
  }
`;

export default Map;
