import RealCityVisitorList from '../RealCityVisitorList';
import { useCallback, useEffect, useState } from 'react';
import { apiRoute, requestGetFile, requestSecureGet } from '@libs/api';
import { defaultPageTypes, PageTypes, PaginationTypes } from '@typedef/libs/pagination.types';
import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import { VisitorResponseType, VisitorType } from '@typedef/Visit/visitor.types';
import { EAgeGroup } from '@typedef/Visit/age-group.enum';
import { EGender } from '@typedef/Visit/gender.enum';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import useSearch from '@hooks/useSearch';
import { EVisitType } from '@typedef/Visit/visit-type.enum';

const RealCityVisitorListContainer = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const profileData = useRecoilValue(accountSelector);

  const [visitorData, setVisitorData] = useState<VisitorType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageTypes>(defaultPageTypes);

  const { option, search } = useSearch();

  const loadVisitor = useCallback(
    async (option?: string, search?: string, page?: number) => {
      const queryParams: Record<string, any> = {
        page: page,
        size: 10,
      };
      if (option) {
        queryParams[option] = search;
      }
      const { config, data } = await requestSecureGet<PaginationTypes<VisitorResponseType>>(
        `${apiRoute.visitor.getVisitor}${parseQueryParamsToString(queryParams)}`,
        {},
        profileData.accessToken,
      );

      if (config.status === 200) {
        setVisitorData(
          data.content.map((v) => {
            return {
              ...v,
              ageGroup: EAgeGroup.valueOf(v.ageGroup),
              gender: EGender.valueOf(v.gender),
              visitType: EVisitType.valueOf(v.visitType),
            };
          }),
        );
        setPageInfo(data);
      } else {
        setVisitorData([]);
      }
    },
    [profileData.accessToken],
  );

  const visitorListExcelDownload = useCallback(async () => {
    const { config, data, headers } = await requestGetFile<BlobPart>(
      apiRoute.excel.visitorListExcelDownload + `?paged=false`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      const url = window.URL.createObjectURL(new Blob([data], { type: String(headers['Content-Type']) }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `레알시티 방문자 조회 데이터.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  }, [profileData.accessToken, option, search]);
  const onPageChange = (page: number) => {
    setVisitorData([]);
  };

  useEffect(() => {
    void loadVisitor(option!, search!, currentPage);
  }, [currentPage, option, search]);

  return (
    <RealCityVisitorList
      visitorData={visitorData}
      pageInfo={pageInfo}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      onPageChange={onPageChange}
      visitorListExcelDownload={visitorListExcelDownload}
    />
  );
};

export default RealCityVisitorListContainer;
