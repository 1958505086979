import styled from "styled-components";

export const AllDetailContainer = styled.div<{ grid?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ grid }) => grid}, 1fr);
  gap: 10px;

  .table {
    width: 100%;

    ${({ theme }) => theme.flex.col};

    table {
      thead > tr > div {
        font-weight: 600;
      }

      tr {
        height: 50px;

        border-bottom: 1px solid #eee;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      div {
        flex: 1 1 0%;
        min-width: 100px;
        padding: 0px 5px;
        text-align: center;
      }
    }
  }

  .visitor-of-number {
    ${({ theme }) => theme.flex.col};
    align-items: center;
    justify-content: space-evenly;

    .visited-main-header > h4 {
      font-size: 22px;
      font-weight: bold;
    }

    .visited-container {
      div {
        font-size: 22px;
        font-weight: bold;
      }

      span {
        font-size: 22px;
        font-weight: bold;

        color: #256662;
      }
    }
  }
`;

export const DetailContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 15px;

  border-radius: 7px;

  background: #fff;
  box-shadow: rgb(125 125 125 / 10%) 0 1px 2px 0;

  & > h3 {
    margin-bottom: 10px;

    font-size: 20px;
    font-weight: bold;
  }

  span {
    color: #b1b1b1;
  }

  .visited-container {
    width: 100%;

    ${({ theme }) => theme.flex.row};
    justify-content: space-evenly;

    div {
      width: 100%;

      padding: 10px;

      text-align: center;
    }

    & > div:first-child {
      border-right: 1px solid #eee;
    }
  }
`;

export const PieChartContainer = styled.div`
  width: 300px;
  height: 250px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
`;