import styled from 'styled-components';
import { DayOfTheWeek, ScheduleActiveTypes } from '@typedef/Schedule/schedule.types';
import { dayStyles } from '@libs/getActiveDateStyle';

export const Container = styled.main`
  width: 600px;

  .split {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 5px;

    .preview-btn {
      margin-left: auto;
      margin-right: 5px;
      background-color: transparent;
      border: none;
      color: #8ab4f8;
      text-decoration: underline;
    }

    select {
      width: auto;
      max-width: 50%;
      padding: 5px;
    }

    .subtitle {
      font-weight: 600;
    }
  }

  .warning {
    margin-bottom: 10px;
    margin-top: 5px;
    justify-content: end;
    min-height: 2vh;

    p {
      color: red;
      font-size: 13px;
      font-weight: bold;
    }

    svg {
      width: 13px;
      margin-bottom: 2px;
      margin-right: 3px;
    }
  }

  .time {
    margin-bottom: 0;
  }

  .error-phrases {
    float: right;
    width: 265px;
    color: #d32f2f;
    text-align: right;
    font-size: 13px;
    padding-top: 15px;
  }
`;

export const Weeks = styled.div`
  ${({ theme }) => theme.flex.row}
  flex-wrap: wrap;
  gap: 15px;
`;

export const Week = styled.button<{ active: boolean }>`
  //width: 60px;
  padding: 0 1rem;
  height: 30px;
  background-color: ${({ active }) => (active ? '#256662' : '#fff')};
  border: 1px solid #256662;
  border-radius: 3px;
  color: ${({ active }) => active && '#fff'};
  font-weight: ${({ active }) => active && 600};
`;

export const Times = styled.div`
  ${({ theme }) => theme.flex.row};
  align-items: center;
  flex-wrap: wrap;
  width: 265px;

  .datepicker-custom-input {
    width: 185px;
    height: 25px;
  }

  & fieldset {
    border: 1px solid rgb(37, 102, 98);
  }

  & legend,
  .MuiInputLabel-shrink {
    color: rgb(37, 102, 98);
  }

  & .MuiStack-root,
  .MuiInputBase-root {
    width: 265px;
  }
`;

export const SelectWeeks = styled.div<ScheduleActiveTypes>`
  ${({ theme }) => theme.flex.row};
  align-items: center;
  flex-wrap: wrap;
  height: 40px;
  width: auto;
  gap: 10px;

  & > div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    color: rgb(37, 102, 98);
    display: flex;
    align-items: center;
    border: 1px solid rgb(37, 102, 98);
    font-size: 12px;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  & > .sun {
    ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.SUN))}
  }

  & > .mon {
    ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.MON))}
  }

  & > .tue {
    ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.TUES))}
  }

  & > .wed {
    ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.WED))}
  }

  & > .thu {
    ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.THURS))}
  }

  & > .fri {
    ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.FRI))}
  }

  & > .sat {
    ${({ repetitionDayOfTheWeek }) => dayStyles(repetitionDayOfTheWeek?.includes(DayOfTheWeek.SAT))}
  }
`;
