import styled, { css } from 'styled-components';
import { WidgetStyleProps } from '@typedef/Widget/widget.types';
import { getFontSize } from '@libs/getFontStyle';
import { EFluctuationDivisionType } from '@typedef/Widget/stock-index.type';

export const StockIndexLoadingOrError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 5, 31, 0.9);
  color: #fff;
  font-size: 16px;
`;

export const StockIndexStyle = styled.div<
  WidgetStyleProps & {
    isIncrease: {
      kospi: EFluctuationDivisionType;
      kosdaq: EFluctuationDivisionType;
    };
  }
>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 5, 31, 0.9);

  ${({ resolution }) =>
    resolution === '1:1' || resolution === '9:16'
      ? css`
          flex-direction: column;
        `
      : ``};

  .index {
    ${({ resolution }) =>
      resolution === '1:1' || resolution === '9:16'
        ? css`
            width: 80%;
            height: calc(50% - 0.025rem);
            justify-content: center;
          `
        : css`
            height: 80%;
            width: calc(50% - 0.025rem);
            justify-content: space-between;
          `}
    display: flex;
    flex-direction: column;

    & > .title {
      width: 100%;
      text-align: center;

      & > div {
        color: #fff;

        &:first-child {
          font-size: ${({ isSetting, width, height, contentWidth, contentHeight }) =>
            `calc(${isSetting ? 1 : 1.8} * ${getFontSize(
              width,
              height,
              {
                w: contentWidth,
                h: contentHeight,
              },
              isSetting!,
            )})`};
          font-weight: 700;
        }

        &:last-child {
          ${({ isSetting, width, height, contentWidth, contentHeight, resolution }) =>
            resolution === '16:9'
              ? css`
                  margin-top: 1.5%;
                `
              : ``}
          font-size: ${({ isSetting, width, height, contentWidth, contentHeight }) =>
            `calc(${isSetting ? 0.8 : 1.6} *
          ${getFontSize(
            width,
            height,
            {
              w: contentWidth,
              h: contentHeight,
            },
            isSetting!,
          )})`};
        }
      }
    }

    & > .stock-info {
      ${({ isSetting, width, height, contentWidth, contentHeight, resolution }) =>
        resolution === '32:9' || resolution === '1:1'
          ? css`
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: ${resolution === '1:1' && '10%'};
            `
          : resolution === '9:16'
            ? css`
                margin-top: 20%;
              `
            : ``}
      & > div {
        ${({ resolution }) =>
          resolution === '32:9' || resolution === '1:1'
            ? css`
                display: flex;
                align-items: center;
                justify-content: center;

                & > svg {
                  margin-right: 5%;
                  width: 100%;
                  height: 100%;
                  margin-bottom: 3%;
                }

                &:first-child {
                  width: auto;
                  height: 100%;
                }

                &:last-child {
                  margin-left: 5%;
                }
              `
            : resolution === '16:9' || resolution === '9:16' || resolution === '4:3'
              ? css`
                  width: 100%;

                  & > svg {
                    height: 100%;
                    width: 12%;
                    margin-right: 3%;
                    margin-bottom: 3%;
                  }

                  &:last-child {
                    margin-top: 6%;
                  }
                `
              : ``};

        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;

        &:first-child {
          color: #fff;
          font-size: ${({ isSetting, width, height, contentWidth, contentHeight }) =>
            `calc(${isSetting ? 1.8 : 2.6} *
          ${getFontSize(
            width,
            height,
            {
              w: contentWidth,
              h: contentHeight,
            },
            isSetting!,
          )})`};
        }

        &:last-child {
          font-size: ${({ isSetting, width, height, contentWidth, contentHeight }) =>
            `calc(${isSetting ? 1.2 : 2} *
          ${getFontSize(
            width,
            height,
            {
              w: contentWidth,
              h: contentHeight,
            },
            isSetting!,
          )})`};
        }
      }

      .kospi-fluctuation {
        color: ${({ isIncrease }) =>
          isIncrease.kospi === EFluctuationDivisionType.INCREASE
            ? '#ff4c4c'
            : isIncrease.kospi === EFluctuationDivisionType.DECREASE
              ? '#4c93ff'
              : '#2AC195'};
      }

      .kosdaq-fluctuation {
        color: ${({ isIncrease }) =>
          isIncrease.kosdaq === EFluctuationDivisionType.INCREASE
            ? '#ff4c4c'
            : isIncrease.kosdaq === EFluctuationDivisionType.DECREASE
              ? '#4c93ff'
              : '#2AC195'};
      }
    }

    & > .price {
      ${({ isSetting, width, height, contentWidth, contentHeight, resolution }) =>
        resolution === '16:9'
          ? css`
              width: 75%;
            `
          : resolution === '32:9'
            ? css`
                width: 60%;
              `
            : css`
                width: 80%;
              `};
      height: auto;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      & > div {
        & > div {
          text-align: center;
          font-weight: 600;
          font-size: ${({ isSetting, width, height, contentWidth, contentHeight, resolution }) =>
            `calc(${isSetting ? 0.85 : resolution === '4:3' ? 1.35 : 1.65} *
          ${getFontSize(
            width,
            height,
            {
              w: contentWidth,
              h: contentHeight,
            },
            isSetting!,
          )})`};

          &:first-child {
            color: #fff;
          }

          &:last-child {
            margin-top: 5%;
          }
        }

        & > .high {
          color: #ff4c4c;
        }

        & > .low {
          color: #4c93ff;
        }
      }
    }
  }

  & > .divider {
    background-color: #555;
    ${({ resolution }) =>
      resolution === '1:1' || resolution === '9:16'
        ? css`
            width: 100%;
            height: 0.05rem;
          `
        : css`
            height: 100%;
            width: 0.05rem;
          `};
  }
`;
