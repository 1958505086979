export type EGenderTypes = 'MALE' | 'FEMALE' | 'UNKNOWN';

export class EGender {
  constructor(
    readonly value: EGenderTypes,
    readonly name: string,
  ) {}

  static readonly MALE = new EGender('MALE', '남성');
  static readonly FEMALE = new EGender('FEMALE', '여성');
  static readonly UNKNOWN = new EGender('UNKNOWN', '미상');

  static readonly values = [EGender.MALE, EGender.FEMALE, EGender.UNKNOWN];

  static valueOf(value: EGenderTypes | string): EGender {
    return EGender.values.find((v) => v.value === value)!;
  }

  equals(value: EGenderTypes | EGender) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
