import Widget from '../Widget';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ContentTypes } from '@typedef/Contents/contents.types';
import {
  apiRoute,
  requestGet,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
  requestSecurePostFile,
} from '@libs/api';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { accountSelector } from '@stories/account';
import { modalSelector } from '@stories/modal';
import { saveStatusSelector } from '@stories/contentSetting';
import WidgetManageContainer from './WidgetManageContainer';
import {
  createWidget,
  RawWidgetTypes,
  RequestWidgetTypes,
  WIDGET_LIST,
  WidgetTypes,
} from '@typedef/Widget/widget.types';
import { v4 as uuidV4 } from 'uuid';
import { calculateBoardSize } from '@libs/calculateBoardSize';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import { LoadingModalContainer } from '@components/Widgets/styles/WidgetStyle';
import Spinner from '@components/Common/Spinner/Spinner';
import { EFileType } from '@typedef/Widget/file-type.enum';
import { EFileStreamingType } from '@typedef/Widget/file-streaming-type.enum';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { GetWeatherInputsTypes, GetWeathersInputsDefault, WeatherTypes } from '@typedef/Widget/weather.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { useBlocker } from 'react-router-dom';
import ContentWarning from '@components/ContentSetting/components/ContentWarning';
import { getResolutionValue } from '@libs/getResolutionValue';
import { weatherListSelector } from '@stories/weatherList';

const WidgetContainer = () => {
  const [weatherList, setWeatherList] = useRecoilState(weatherListSelector);
  // 선택한 레이아웃
  const [selectWidget, setSelectWidget] = useState<WidgetTypes | null>(null);
  // 컨텐츠 리스트
  const [contentList, setContentList] = useState<ContentTypes[]>([]);
  // 위젯 리스트
  const [widgetList, setWidgetList] = useState<WidgetTypes[]>([]);
  // 선택한 컨텐츠
  const [selectContent, setSelectContent] = useState<ContentTypes | null>(null);
  // 컨텐츠 삭제 후 재로딩용 변수
  const [reload, setReload] = useState<number>(0);
  // 파일 드랍시 드랍 영역부분 색상으로 표시
  const [fileDropBackground, setFileDropBackground] = useState<string | null>(null);

  const WidgetRef = useRef<HTMLDivElement>(null);

  const [customDisplaySize, setCustomDisplaySize] = useState<{
    width: number;
    height: number;
  }>({
    height: 0,
    width: 0,
  });

  const [resolution] = useState(20);

  const account = useRecoilValue(accountSelector);
  const setModal = useSetRecoilState(modalSelector);
  const [saveStatus, setSaveStatus] = useRecoilState(saveStatusSelector);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => !saveStatus && currentLocation.pathname !== nextLocation.pathname,
  );

  // 레이아웃 변경 ( 위치 및 크기 )
  const handleWidgetChange = useCallback(
    (newWidgets: WidgetTypes[]) => {
      setWidgetList((prev) => {
        return prev.map((value) => {
          const current = newWidgets.find((data) => data.i === value.i);
          if (
            current &&
            (current.x !== value.x || current.y !== value.y || current.w !== value.w || current.h !== value.h)
          ) {
            setSaveStatus(false);
            if (
              value.resolution &&
              (value.type.equals(EWidgetType.WEATHER) ||
                value.type.equals(EWidgetType.FINEDUST) ||
                value.type.equals(EWidgetType.TIME) ||
                value.type.equals(EWidgetType.STOCK_INDEX))
            ) {
              return {
                ...value,
                x: current.x,
                y: current.y,
                w: current.w,
                h: value.resolution * current.w,
              };
            }
            return {
              ...value,
              x: current.x,
              y: current.y,
              w: current.w,
              h: current.h,
            };
          } else
            return {
              ...value,
            };
        });
      });
    },
    [Widget, setSaveStatus],
  );

  // 컨텐츠 리스트에서 컨텐츠 선택시 위젯 리스트 불러오기
  const selectContentWidgetList = useCallback(
    async (selectContentId: string) => {
      const filterSelectContent = contentList.filter((content) => content.id === selectContentId)[0];

      setSelectContent(filterSelectContent);

      setModal({
        header: '컨텐츠 로딩 중...',
        close: false,
        body: (
          <LoadingModalContainer>
            <Spinner width={100} height={100} size={50} />
            <div className={'loading-text'}>컨텐츠를 로딩 중입니다...</div>
          </LoadingModalContainer>
        ),
      });

      const { data, config } = await requestSecureGet<RawWidgetTypes[]>(
        apiRoute.widget.get + selectContentId,
        {},
        account.accessToken,
      );
      if (config.status !== 200) {
        setModal(null);
        return;
      }

      setWidgetList(
        (await Promise.all(data.map((widget) => createWidget(widget)))).sort((a, b) => a.priority - b.priority),
      );
      setSelectWidget(null);

      setModal(null);
    },
    [contentList],
  );
  // 컨텐츠 리스트 불러오기
  const loadContentsList = useCallback(async () => {
    const {
      data: { content },
      config,
    } = await requestSecureGet<PaginationTypes<ContentTypes>>(
      apiRoute.contents.get + '?paged=false',
      {},
      account.accessToken,
    );

    if (config.status !== 200) {
      return alert(config.message);
    }
    setContentList(content);
  }, []);

  const loadWeatherList = async () => {
    const getWeathersInputs: GetWeatherInputsTypes = {
      ...GetWeathersInputsDefault,
      paged: false,
    };

    if (weatherList.length === 0) {
      const {
        data: { content },
        config,
      } = await requestGet<PaginationTypes<WeatherTypes>>(
        `${apiRoute.weather.getGridXYWithoutJWT}${parseQueryParamsToString(getWeathersInputs)}`,
        {},
      );

      if (config.status !== 200) return;

      setWeatherList(content);
    }
  };

  // 컨텐츠 선책시컨텐츠 화면 사이즈 설정
  useEffect(() => {
    if (selectContent) setCustomDisplaySize(calculateBoardSize(960, 534, selectContent.w / selectContent.h));
    else setCustomDisplaySize({ width: 960, height: 534 });
  }, [selectContent]);

  // 레이아웃에 위젯추가
  const onAddWidget = useCallback(
    async (type: EWidgetType) => {
      if (!selectContent) return;

      // 선택한 위젯 찾기
      const filterSelectWidget = WIDGET_LIST.find((data) => data.type === type)!;

      // 선택한 위젯 기존 레이아웃에서 사용하고있는지 체크
      const checkWidgetInLayout = !!widgetList.find((data) => data.type === type);

      if (
        (type.equals(EWidgetType.SUB) ||
          type.equals(EWidgetType.HEADER) ||
          type.equals(EWidgetType.TIME) ||
          type.equals(EWidgetType.NEWS)) &&
        checkWidgetInLayout
      ) {
        alert('이미 사용하고 있는 위젯입니다. 해당 위젯은 2개이상 생성할 수 없습니다.');
        return;
      }

      // 자막과 헤더 타입이면 가로 길이 최대로
      if (
        type.equals(EWidgetType.SUB) ||
        type.equals(EWidgetType.NEWS) ||
        type.equals(EWidgetType.HEADER) ||
        type.equals(EWidgetType.MAIN)
      ) {
        filterSelectWidget.w = selectContent.w / resolution;
        filterSelectWidget.minW = selectContent.w / resolution;
        filterSelectWidget.maxW = selectContent.w / resolution;
      }
      setWidgetList([
        ...widgetList,
        {
          ...filterSelectWidget,
          i: uuidV4(),
          subSpeed: filterSelectWidget.subSpeed ?? 0,
          newWidget: true,
          isDraggable: true,
          static: false,
          priority: widgetList.length === 0 ? 0 : widgetList[widgetList.length - 1].priority + 1,
        },
      ]);

      setSaveStatus(false);
    },
    [widgetList, selectContent],
  );

  // 파일 위젯 추가
  const onDropFileWidget = useCallback(
    async (file: File) => {
      if (!selectContent) {
        return alert('컨텐츠를 선택해주세요.');
      }
      const filterSelectWidget = WIDGET_LIST.find((data) => data.type.equals(EWidgetType.BLOCK))!;

      // 100MB 바이트 제한
      if (file.size >= 1024 * 1024 * 100) {
        return alert('파일 사이즈가 너무 큽니다. 100MB 이하 파일을 업로드 해주세요.');
      }

      if (!EFileType.IMAGE.validate(file.name) && !EFileType.VIDEO.validate(file.name)) {
        return alert(
          `이미지(${EFileType.IMAGE.fileExtensions.join(', ')}) 또는 비디오(${EFileType.VIDEO.fileExtensions.join(
            ',',
          )}) 파일만 업로드 가능합니다.`,
        );
      }

      setModal({
        header: '컨텐츠 로딩 중...',
        close: false,
        body: (
          <LoadingModalContainer>
            <Spinner width={100} height={100} size={50} />
            <div className={'loading-text'}>컨텐츠를 로딩 중입니다...</div>
          </LoadingModalContainer>
        ),
      });

      const filename = await saveFile(file);
      if (!filename) {
        setModal(null);
        return;
      }

      const widgetUUID = uuidV4();

      setWidgetList([
        ...widgetList,
        {
          ...filterSelectWidget,
          i: widgetUUID,
          subSpeed: filterSelectWidget.subSpeed ?? 0,
          newWidget: true,
          isDraggable: true,
          static: false,

          type: EWidgetType.FILE,
          name: EWidgetType.FILE.name,

          file: filename,
          files: [],
          fileStreamingType: EFileStreamingType.STREAMING.value,
          priority: widgetList.length === 0 ? 0 : widgetList[widgetList.length - 1].priority + 1,
        },
      ]);

      setSaveStatus(false);

      setModal(null);
    },
    [widgetList],
  );

  // 파일 저장
  const saveFile = useCallback(
    async (file: File) => {
      const formData = new FormData();

      formData.append('file', file);

      const {
        data,
        config: { status, message },
      } = await requestSecurePostFile<string>(apiRoute.company.filePost, {}, formData, account.accessToken);
      if (status !== 200) {
        if (status === 400) {
          alert(message);
          return;
        }
        alert('파일 업로드에 실패했습니다.');
        return;
      }
      return data;
    },
    [account.accessToken],
  );

  // 위젯 저장
  const saveWidget = useCallback(
    async (contentId: string) => {
      let updateWidgetList: RequestWidgetTypes[] = [];

      for (const widget of widgetList) {
        if (!widget.weatherId) {
          const getWeathersInputs: GetWeatherInputsTypes = {
            ...GetWeathersInputsDefault,
            paged: false,
            location1: widget.location1 ?? '서울특별시',
            location2: widget.location2 ?? ' ',
            location3: widget.location3 ?? ' ',
          };

          if (widget.type.equals(EWidgetType.WEATHER) || widget.type.equals(EWidgetType.FINEDUST)) {
            const {
              data: { content },
              config,
            } = await requestGet<PaginationTypes<WidgetTypes>>(
              `${apiRoute.weather.getGridXYWithoutJWT}${parseQueryParamsToString(getWeathersInputs)}`,
              {},
            );

            if (config) {
              widget.weatherId = content[0].id!;
            }
          }
        }

        updateWidgetList.push({
          ...widget,
          fileData: undefined,
          type: widget.type.value,
          fileStreamingType: widget.fileStreamingType,
        });
      }

      const { data, config } = await requestSecurePost<RawWidgetTypes[]>(
        apiRoute.widget.post + contentId,
        {},
        updateWidgetList,
        account.accessToken,
      );

      if (config.status === 200) {
        setWidgetList(
          data.map((v): WidgetTypes => {
            return { ...v, type: EWidgetType.valueOf(v.type)! };
          }),
        );
        setModal(null);
        alert('컨텐츠 저장이 완료되었습니다.');
      } else {
        alert('컨텐츠 저장에 실패했습니다.');
      }
      await requestSecurePost(apiRoute.company.fileFlush, {}, {}, account.accessToken);

      setTimeout(() => {
        setModal(null);
      }, 3000);

      loadContentsList();
    },
    [account.accessToken, widgetList],
  );

  // 컨텐츠 저장
  const saveContent = useCallback(async () => {
    if (!selectContent) {
      return;
    }

    setModal({
      header: '컨텐츠 저장 중...',
      close: false,
      body: (
        <LoadingModalContainer>
          <Spinner width={100} height={100} size={50} />
          <div className={'loading-text'}>컨텐츠를 저장 중입니다...</div>
        </LoadingModalContainer>
      ),
    });

    if (selectContent.id) {
      // 수정
      const { config } = await requestSecurePatch(
        apiRoute.contents.update + selectContent.id,
        {},
        selectContent,
        account.accessToken,
      );

      if (config.status !== 200) {
        alert(config.message);
        return;
      }

      saveWidget(selectContent.id);
      setSaveStatus(true);
      return;
    }

    // 생성
    if (!selectContent.name) {
      return;
    }

    const { data: responseData, config } = await requestSecurePost<{
      id: string;
    }>(
      apiRoute.contents.post,
      {},
      {
        name: selectContent.name,
      },
      account.accessToken,
    );

    if (config.status !== 200) {
      alert(config.message);
      return;
    }

    setSelectContent({
      id: responseData.id,
      name: selectContent.name,
      w: selectContent.w,
      h: selectContent.h,
      backgroundColor: selectContent.backgroundColor,
      companyId: selectContent.companyId,
    });

    saveWidget(responseData.id);

    loadContentsList();
    setSaveStatus(true);
  }, [selectContent, widgetList, account]);

  // 컨텐츠 삭제 모달 오픈
  const onClickModalOpen = useCallback(
    (type: 'add' | 'delete') => {
      if (type === 'add') {
        setModal({
          header: '컨텐츠 생성하기',
          close: true,
          body: (
            <WidgetManageContainer
              setSelectContent={setSelectContent}
              setLayout={setWidgetList}
              setWidgetList={setWidgetList}
              setSelectLayout={setSelectWidget}
              type={type}
              setReload={setReload}
              widgets={widgetList}
            />
          ),
        });
      }

      if (type === 'delete') {
        if (!selectContent?.id) {
          return;
        }

        setModal({
          header: '컨텐츠 삭제하기',
          close: true,
          body: (
            <WidgetManageContainer
              setSelectContent={setSelectContent}
              setLayout={setWidgetList}
              setWidgetList={setWidgetList}
              setSelectLayout={setSelectWidget}
              type={type}
              contentId={selectContent.id}
              setReload={setReload}
              widgets={widgetList}
            />
          ),
        });
      }
    },
    [setModal, setReload, selectContent, widgetList],
  );

  const handleWidgetSizeByResolution = (resolution: any) => {
    const sizeMap: Record<string, { width: number; height: number }> = {
      '1:1': { width: 5, height: 5 },
      '16:9': { width: 8, height: 4.5 },
      '32:9': { width: 16, height: 4.5 },
      '9:16': { width: 4.5, height: 8 },
      '4:3': { width: 6, height: 4.5 },
    };

    const size = sizeMap[getResolutionValue(resolution)] || { width: 8, height: 4.5 };

    setWidgetList((prevWidgetList) =>
      prevWidgetList.map((prevWidget) =>
        prevWidget.i === selectWidget?.i ? { ...prevWidget, w: size.width, h: size.height } : prevWidget,
      ),
    );

    setSelectWidget((v) => v && { ...v, w: size.width, h: size.height });
  };

  // 커스텀마이징 값 변경 적용
  const handleOnChange = useCallback(
    (id: string, value: any) => {
      setSaveStatus(false);

      if (id === 'resolution') {
        handleWidgetSizeByResolution(value);
      }
      if (id === 'weatherId') {
        console.log('weatherId');
      }

      setWidgetList((prevWidgetList) => {
        return prevWidgetList.map((prevWidget) =>
          prevWidget.i === selectWidget?.i ? { ...prevWidget, [id]: value } : prevWidget,
        );
      });
      setSelectWidget((v) => v && { ...v, [id]: value });
    },
    [selectWidget],
  );

  // 선택한 위젯 삭제
  const handleWidgetDelete = useCallback(() => {
    setWidgetList(widgetList.filter((data) => data.i !== selectWidget?.i));

    setSelectWidget(null);
  }, [selectContent, selectWidget, account.accessToken, widgetList]);

  // 키 입력시 이벤트 실행 ( 현재 선택한 레이아웃 닫기, 현재 선택한 레이아웃 삭제, 저장)
  const handleKeyUserDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setSelectWidget(null);
      }

      if (e.key === 'Delete' && selectWidget?.i) {
        handleWidgetDelete();
      }

      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        saveContent();
      }
    },
    [selectWidget],
  );
  const handleWidgetOrder = useCallback(
    (widget: WidgetTypes, type: 'front' | 'back') => {
      const currentIndex = widgetList.findIndex((data) => data.i === widget.i);

      if (type === 'front' && currentIndex === widgetList.length - 1) return;
      if (type === 'back' && currentIndex === 0) return;

      const targetIndex = type === 'front' ? currentIndex + 1 : currentIndex - 1;

      [widgetList[currentIndex].priority, widgetList[targetIndex].priority] = [
        widgetList[targetIndex].priority,
        widgetList[currentIndex].priority,
      ];

      setWidgetList([...widgetList.sort((a, b) => a.priority - b.priority)]);
    },
    [widgetList],
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setModal({
        header: '컨텐츠 저장 경고!!',
        close: true,
        onClose: () => {
          blocker.reset();
        },
        body: (
          <ContentWarning
            onSave={async () => {
              setSaveStatus(true);

              await saveContent();
              blocker.proceed();
            }}
            onCancle={() => {
              blocker.proceed();
            }}
          />
        ),
      });
    }
  }, [blocker]);

  useEffect(() => {
    loadContentsList();
    setSelectContent(null);
    setSelectWidget(null);
  }, [reload]);

  // 유저 키 입력시 이벤트 실행
  useEffect(() => {
    document.addEventListener('keydown', handleKeyUserDown);

    return () => {
      document.removeEventListener('keydown', handleKeyUserDown);
    };
  }, []);

  useEffect(() => {
    setSaveStatus(true);
  }, []);

  useEffect(() => {
    loadWeatherList();
  }, []);

  return (
    <Widget
      fileDropBackground={fileDropBackground}
      setFileDropBackground={setFileDropBackground}
      contentList={contentList}
      selectContentLayoutList={selectContentWidgetList}
      selectContent={selectContent}
      saveContent={saveContent}
      handleLayoutChange={handleWidgetChange}
      layout={widgetList.sort((a, b) => a.priority! - b.priority!)}
      layoutRef={WidgetRef}
      selectLayout={selectWidget}
      setSelectLayout={setSelectWidget}
      onAddLayout={onAddWidget}
      handleLayoutDelete={handleWidgetDelete}
      onDropFileLayout={onDropFileWidget}
      onClickModalOpen={onClickModalOpen}
      handleOnChange={handleOnChange}
      onContentOptionChange={setSelectContent}
      customDisplaySize={customDisplaySize}
      resolution={resolution}
      handleWidgetOrder={handleWidgetOrder}
    />
  );
};

export default WidgetContainer;
