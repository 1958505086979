import { useRecoilState } from 'recoil';
import Profile from '../Profile';
import { accountSelector } from '@stories/account';
import { useCallback, useEffect, useState } from 'react';
import { apiRoute, requestPut, requestSecureGet, requestSecurePatch, requestSecurePost } from '@libs/api';
import { AccountResponseTypes } from '@typedef/Account/account.types';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { set } from 'lodash';

const ProfileContainer = () => {
  const [account, setAccount] = useRecoilState(accountSelector);
  const [changeProfile, setChangeProfile] = useState<boolean>(false);
  const [userName, setUserName] = useState('');

  const byteToMegabyte = useCallback((bytes: number) => {
    return Math.round(bytes / (1024 * 1024));
  }, []);

  const onClickUpdateProfile = useCallback(async () => {
    const { config } = await requestSecurePatch(
      apiRoute.account.update,
      {},
      {
        userName: userName,
      },
      account.accessToken,
    );

    if (config.status === 200) {
      setChangeProfile(false);
      setAccount({
        ...account,
        userName: userName,
      });
    }
  }, [account, userName]);

  const loadUserSelf = useCallback(async () => {
    const { config, data } = await requestSecureGet<AccountResponseTypes>(
      apiRoute.account.getSelf,
      {},
      account.accessToken,
    );

    if (config.status === 200) {
      window.localStorage.setItem('id', data.id);
      window.localStorage.setItem('userId', data.userId);
      window.localStorage.setItem('userName', data.userName);
      window.localStorage.setItem('role', data.role);
      window.localStorage.setItem('company', JSON.stringify(data.company));
      window.localStorage.setItem('createdAt', data.createdAt);
      window.localStorage.setItem('updatedAt', data.updatedAt);

      setAccount({ ...data, accessToken: account.accessToken, role: EAccountRole.valueOf(data.role)! });
      setUserName(data.userName);
    }
  }, []);

  const fileFlush = useCallback(async () => {
    const { config } = await requestSecurePost(
      apiRoute.company.fileFlush,
      {},
      {},
      account.accessToken,
    )

    if (config.status !== 200) return alert("파일 정리에 실패했습니다.");
  }, []);

  const handleChange = useCallback(
    (name: string) => {
      setUserName(name);

      if (account.userName === name) {
        setChangeProfile(false);
      } else {
        setChangeProfile(true);
      }
    },
    [account],
  );

  useEffect(() => {
    setUserName(account.userName);
    loadUserSelf();
  }, []);

  return (
    <Profile
      company={account.company}
      account={account}
      userName={userName}
      handleChange={handleChange}
      byteToMegabyte={byteToMegabyte}
      changeProfile={changeProfile}
      onClickUpdateProfile={onClickUpdateProfile}
      fileFlush={fileFlush}
    />
  );
};

export default ProfileContainer;
