import styled from 'styled-components';

const space = '10px';
const height = '40px';

export const DatePickerStyle = styled.div`
  display: flex;
  align-items: center;
  height: ${height};

  form {
    width: 200px;
    position: relative;
  }

  .search-input {
    ${({ theme }) => theme.flex.row};
    align-items: center;
    background-color: #fff;
    border-radius: 5px;

    label {
      cursor: text;
      margin-left: 15px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    input[type='date'] {
      width: 100%;
      height: ${height};
      padding: 0 ${space} 0 5px;
      font-size: 15px;
      border: none;
      border-radius: 5px;

      &::placeholder {
        font-size: 16px;
        color: #d3d3d3;
        font-family: transparent;
      }

      &:focus {
        outline: none;
      }
    }
  }
`;
