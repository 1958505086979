export const getFontStyle = (font?: string) => {
  switch (font) {
    case 'IBM Plex Sans KR':
      return "'IBM Plex Sans KR', sans-serif";
    case 'Dongle':
      return "'Dongle', sans-serif";
    case 'Gowun Batang':
      return "'Gowun Batang', serif";
    case 'Hahmlet':
      return "'Hahmlet', serif";
    case 'Noto Serif KR':
      return "'Noto Serif KR', serif";
    case 'Gaegu':
      return "'Gaegu', cursive";
    case 'Gothic A1':
      return "'Gothic A1', sans-serif";
    case 'Noto Sans KR':
      return "'Noto Sans KR', sans-serif";
    case 'Nanum Myeongjo':
      return "'Nanum Myeongjo', serif";
    case 'Nanum Gothic Coding':
      return "'Nanum Gothic Coding', monospace";
    case 'Nanum Gothic':
      return "'Nanum Gothic', sans-serif";
    default:
      return '';
  }
};

export const getFontSize = (
  width: number,
  height: number,
  contentSize: { w: number; h: number },
  isSetting: boolean,
): string => {
  const widgetArea = width * height;
  const baseAreaSize = contentSize
    ? ((contentSize.w > contentSize.h ? 0.55 : isSetting ? 1.3 : 0.4) * 100) ** 1.6
    : 2000;
  const baseFontSize = 14;
  const maxFontSize = 36;

  const calculatedFontSize = Math.sqrt(widgetArea / baseAreaSize) * baseFontSize;

  return Math.min(calculatedFontSize, maxFontSize) + 'px';
};
