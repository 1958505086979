export type PaginationFilterTypes = {
  // 선택된 페이지
  page: number;
  // 페이지당 데이터 수
  size: number;
  // 페이지네이션 여부 (true: 페이지네이션, false: 전체 데이터 반환)
  paged: boolean;
  // 정렬
  sort?: string;
};

export const PaginationFilterInputsDefault: PaginationFilterTypes = {
  page: 1,
  size: 10,
  paged: true,
};
