import styled from 'styled-components';

export const Container = styled.div<{ backgroundColor: string }>`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Section = styled.div<{
  width: number;
  height: number;
  top: number;
  left: number;
  backgroundColor?: string;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;

  video,
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: fill;
  }
`;

export const Text = styled.p<{
  textColor?: string;
  textFamily?: string;
  textItalic?: boolean;
  textSize?: number;
  textUnderline?: boolean;
  textWeight?: boolean;
}>`
  color: ${({ textColor }) => textColor};
  font-family: ${({ textFamily }) => textFamily};
  font-style: ${({ textItalic }) => (textItalic ? 'italic' : 'none')};
  text-decoration: ${({ textUnderline }) => (textUnderline ? 'underline' : 'none')};
  font-weight: ${({ textWeight }) => (textWeight ? 'bold' : 'normal')};
  font-size: ${({ textSize }) => textSize}pt;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
`;
