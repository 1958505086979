import { WidgetTypes } from '@typedef/Widget/widget.types';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CustomFile from '@components/Widgets/components/CustomFile';
import { apiRoute, requestSecurePostFile } from '@libs/api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountSelector } from '@stories/account';
import { LoadingModalContainer } from '@components/Widgets/styles/WidgetStyle';
import Spinner from '@components/Common/Spinner/Spinner';
import { modalSelector } from '@stories/modal';

type Props = {
  selectedLayout: WidgetTypes;
  handleOnChange: (id: string, value: any) => void;
};

const CustomFileContainer = ({ selectedLayout, handleOnChange }: Props) => {
  const account = useRecoilValue(accountSelector);
  const setModal = useSetRecoilState(modalSelector);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, add: boolean, name?: string) => {
    const selectedFile = event.target.files?.[0];

    if (selectedLayout.files && selectedLayout.files.length >= 15) {
      alert('이미지는 슬라이드는 최대 15개 이미지만 가능합니다.');
      return;
    }

    if (selectedFile) {
      setModal({
        header: '이미지 저장 중...',
        close: false,
        body: (
          <LoadingModalContainer>
            <Spinner width={100} height={100} size={50} />
            <div className={'loading-text'}>이미지를 저장 중입니다...</div>
          </LoadingModalContainer>
        ),
      });

      try {
        const formData = new FormData();

        if (selectedFile) {
          formData.append('file', selectedFile);

          const {
            data,
            config: { status, message },
          } = await requestSecurePostFile<string>(apiRoute.company.filePost, {}, formData, account.accessToken);

          if (status !== 200 && status === 400) {
            alert(message);
          } else if (status === 200) {
            if (add) {
              handleOnChange('files', selectedLayout.files?.concat([data]));
            } else if (name) {
              const selectFileIndex = selectedLayout.files?.findIndex((v) => v === name);
              if (selectFileIndex && selectFileIndex !== -1 && selectedLayout.files) {
                let changeFileList = selectedLayout.files;
                changeFileList[selectFileIndex] = data;
                handleOnChange('files', changeFileList);
              }
            }
          }
        }
      } catch (error) {
        alert('이미지 저장 중 오류가 발생했습니다.');
      } finally {
        setModal(null);
      }
    }
  };

  const handleFileRemove = (uuid: string) => {
    handleOnChange('files', selectedLayout.files?.filter((v) => v !== uuid));
  };

  return (
    <CustomFile
      selectedLayout={selectedLayout}
      handleOnChange={handleOnChange}
      handleFileChange={handleFileChange}
      handleFileRemove={handleFileRemove}
    />
  );
};

export default CustomFileContainer;
