import { ContentTypes } from '@typedef/Contents/contents.types';
import '../styles/CustomStyle.scss';
import { WidgetTypes } from '@typedef/Widget/widget.types';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: WidgetTypes | ContentTypes | null;
};

const CustomBackgroundColor = ({ handleOnChange, selectLayout }: Props) => {
  return (
    <div className="custom">
      <div className="background-color">
        <p className="title">배경 색상</p>
        <input
          type="color"
          value={selectLayout?.backgroundColor}
          onChange={(e) => {
            handleOnChange('backgroundColor', e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default CustomBackgroundColor;
