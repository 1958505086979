import { convertDeviceResponseTypeToType, DeviceResponseTypes } from '@typedef/Device/device.types';
import Main from '../Main';
import { useCallback, useEffect, useState } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import { accountSelector } from '@stories/account';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { deviceImageMapSelector } from '@stories/deviceImageList';
import { deviceListSelector } from '@stories/deviceList';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';
import { EDeviceType } from '@typedef/Device/device-type.enum';
import useSearch from '@hooks/useSearch';
import { defaultPageTypes, PageTypes } from '@typedef/libs/pagination.types';
import useDeviceScheduleState from '@hooks/useDeviceScheduleState';
import { ContentTypes } from '@typedef/Contents/contents.types';

const MainContainer = () => {
  const navigate = useNavigate();

  const { option, search } = useSearch();

  const token = useRecoilValue(accountSelector).accessToken;
  const [deviceImageMap, setDeviceImageMap] = useRecoilState(deviceImageMapSelector);
  const [deviceList, setDeviceList] = useRecoilState(deviceListSelector);

  const [pageInfo, setPageInfo] = useState<PageTypes>(defaultPageTypes);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { updateScheduleWithDeviceId } = useDeviceScheduleState();
  const account = useRecoilValue(accountSelector);

  const loadDeviceList = useCallback(
    async (option?: string, search?: string, page?: number) => {
      const queryParams: Record<string, any> = { page: page, size: 8, type: EDeviceType.BOARD.value };
      if (option) {
        queryParams[option] = search;
      }

      const { config, data } = await requestSecureGet<PaginationTypes<DeviceResponseTypes>>(
        `${apiRoute.devices.get}${parseQueryParamsToString(queryParams)}`,
        {},
        token,
      );

      if (config.status === 200) {
        setDeviceList(data.content.map((v) => convertDeviceResponseTypeToType(v)));
        setPageInfo(data);
      }
    },
    [token, setDeviceList],
  );

  const getDeviceImage = useCallback(async (id: string) => {
    if (!id) {
      return '';
    }

    const { data: responseData, config } = await requestSecureGet<string>(
      apiRoute.devices.getDeviceImage + id,
      {},
      token,
    );
    if (config.status !== 200) {
      return '';
    }
    return responseData;
  }, []);

  const getContentsName = useCallback(async (id: string) => {
    if (!id) {
      return '';
    }

    const { config, data } = await requestSecureGet<ContentTypes>(
      apiRoute.contents.update + id,
      {},
      account.accessToken,
    );

    if (config.status !== 200) {
      return '';
    }
    return data.name;
  }, []);

  const loadDeviceImage = useCallback(async () => {
    for (const device of deviceList) {
      const existedDeviceImage = deviceImageMap.get(device.id);

      const now = dayjs();

      let updateSchedule = await updateScheduleWithDeviceId(device.id);

      if (updateSchedule.status) {
        if (
          device.status !== existedDeviceImage?.deviceStatus ||
          now.subtract(10, 'minute').isAfter(existedDeviceImage?.lastDeviceImageUpdate)
        ) {
          const deviceImage = await getDeviceImage(device.id);
          const contentsName = await getContentsName(updateSchedule.contentId);
          setDeviceImageMap((v) => {
            v.set(device.id, {
              deviceStatus: device.status ?? EDeviceStatus.OFF,
              fileUrl: deviceImage,
              contentsName: contentsName,
              lastDeviceImageUpdate: dayjs(),
            });

            return new Map(v);
          });
        }
      } else {
        setDeviceImageMap((v) => {
          v.set(device.id, {
            deviceStatus: device.status ?? EDeviceStatus.OFF,
            lastDeviceImageUpdate: dayjs(),
          });

          return new Map(v);
        });
      }
    }
  }, [deviceList, deviceImageMap, setDeviceImageMap]);

  const onSearchSummit = () => {
    setCurrentPage(1);
  };
  const onPageChange = (page: number) => {
    setDeviceList([]);
  };
  useEffect(() => {
    loadDeviceImage();
  }, [deviceList]);

  useEffect(() => {
    loadDeviceList(option!, search!, currentPage);

    const timer = setInterval(() => {
      loadDeviceList(option!, search!, currentPage);
    }, 60000);

    return () => clearInterval(timer);
  }, [option, search, currentPage]);

  return (
    <>
      <Main
        devices={deviceList}
        deviceImageMap={deviceImageMap}
        onDeviceClick={(device) => {
          navigate(`/monitor/${device.uniqueKey}`);
        }}
        onPageChange={onPageChange}
        pageInfo={pageInfo}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onSearchSummit={onSearchSummit}
      />
    </>
  );
};

export default MainContainer;
