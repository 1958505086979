import { WidgetTypes } from '@typedef/Widget/widget.types';
import styled from 'styled-components';

export const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;

  p {
    margin: 0;
    position: absolute;
    white-space: nowrap;
    z-index: 1;
  }
`;

type Props = {
  width: number;
  widget: WidgetTypes;
  windowWidth: number;
};

export const LeftCustomPTag = styled.p<Props>`
  font-family: ${({ widget }) => widget.textFamily};
  font-size: ${({ widget }) => widget.textSize ?? 0}pt;
  font-weight: ${({ widget }) => (widget.textWeight ? 'bold' : 'normal')};
  font-style: ${({ widget }) => (widget.textItalic ? 'italic' : 'normal')};
  text-decoration: ${({ widget }) => (widget.textUnderline ? 'underline' : 'none')};
  color: ${({ widget }) => widget.textColor};
  left: ${({ widget }) => (widget.subSpeed === 0 ? '50%' : '')};
  transform: translate(${({ widget }) => (widget.subSpeed === 0 ? '-50%' : 0)}, 0);
  animation: rightToLeft
    ${({ widget, width, windowWidth }) => {
      if (widget.subSpeed && widget.subSpeed !== 0) return (width + windowWidth) / widget.subSpeed;
      else return 0;
    }}s
    linear infinite;

  @keyframes rightToLeft {
    0% {
      left: 100%;
    }
    to {
      left: -${({ width }) => width}px;
    }
  }
`;
