import { apiRoute, requestSecureGet, requestSecurePost } from '@libs/api';
import { accountSelector } from '@stories/account';
import { AccountResponseTypes, AccountTypesDefault } from '@typedef/Account/account.types';
import { ApiDataResponseType } from '@typedef/libs/api.types';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import Login from '../Login';
import { CompanyTypes } from '@typedef/Company/company.types';
import { EAccountRole } from '@typedef/Account/account-role.enum';

const LoginContainer = () => {
  // 회원가입 클릭시 html 폼 변경
  const [signUpStatus, setSignUpStatus] = useState<boolean>(false);
  const [idDuplicateFlag, setIdDuplicateFlag] = useState<boolean>(false);
  const [companyCheckFlag, setCompanyCheckFlag] = useState<boolean>(false);

  const [account, setAccount] = useRecoilState(accountSelector);

  // 로그인
  const signIn = useCallback(async () => {
    if (account.userId.length === 0 || (account.password && account.password.length === 0))
      return alert('게정 정보가 없습니다');

    const { config, data } = await requestSecurePost<AccountResponseTypes>(
      apiRoute.account.signIn,
      {},
      {
        id: account.userId,
        password: account.password,
      },
      '',
    );

    if (config.status === 200 && data) {
      window.localStorage.setItem('id', account.id);
      window.localStorage.setItem('userId', data.userId);
      window.localStorage.setItem('userName', data.userName);
      window.localStorage.setItem('role', data.role);
      window.localStorage.setItem('company', JSON.stringify(data.company));
      window.localStorage.setItem('accessToken', data.accessToken);
      window.localStorage.setItem('createdAt', data.createdAt);
      window.localStorage.setItem('updatedAt', data.updatedAt);

      setAccount({
        userId: data.userId,
        userName: data.userName,
        accessToken: data.accessToken,
        company: data.company,
        role: EAccountRole.valueOf(data.role)!,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        id: data.id,
      });
    } else {
      if (config.message) alert(config.message);
      else alert('계정 정보가 올바르지 않습니다.');
    }
  }, [account, setAccount]);

  // 회원가입
  const signUp = useCallback(async () => {
    if (
      account.userId.length === 0 ||
      (account.password && account.password.length === 0) ||
      account.userName.length === 0
    )
      return alert('계정 정보중 빈값이 있습니다 모두 적어주세요.');

    if (!idDuplicateFlag) return alert('아이디 중복확인을 해주세요.');
    if (!companyCheckFlag) return alert('회사 확인을 해주세요.');

    const { config, data } = await requestSecurePost<AccountResponseTypes>(
      apiRoute.account.signUp,
      {},
      {
        userId: account.userId,
        password: account.password,
        companyId: account.company.id,
        userName: account.userName,
      },
      '',
    );

    if (config.status === 200) {
      alert('계정이 성공적으로 생성되었습니다. ');

      setSignUpStatus(false);
    }
  }, [account, idDuplicateFlag]);

  // 아이디 중복확인
  const accountIdDuplicate = useCallback(async () => {
    if (account.userId.length === 0) return alert('아이디를 입력해주세요');

    const { config, data } = await requestSecureGet<ApiDataResponseType>(
      apiRoute.account.checkIdGet + account.userId,
      {},
      '',
    );

    if (config.status === 200) {
      alert(data.message);
      setIdDuplicateFlag(true);
    } else {
      alert(config.message);
    }
  }, [account]);

  // 회사 확인
  const companyCheck = useCallback(async () => {
    if (account.company.name.length === 0) return alert('회사를 입력해주세요');

    const { config, data } = await requestSecureGet<ApiDataResponseType | CompanyTypes>(
      apiRoute.company.checkCompany + account.company.name,
      {},
      '',
    );

    if (config.status === 200) {
      const res = data as CompanyTypes;

      alert('등록되어 있는 회사입니다.');
      setCompanyCheckFlag(true);
      setAccount({ ...account, company: { ...account.company, id: res.id } });
    } else {
      alert(config.message);
    }
  }, [account]);

  useEffect(() => {
    // 로그인 => 회원가입 || 회원가입 계정 생성시 입력값 초기화
    setAccount(AccountTypesDefault);
  }, [signUpStatus, setAccount]);

  return (
    <Login
      account={account}
      setAccount={setAccount}
      signUpStatus={signUpStatus}
      setSignUpStatus={setSignUpStatus}
      signIn={signIn}
      signUp={signUp}
      accountIdDuplicate={accountIdDuplicate}
      companyCheck={companyCheck}
      setIdDuplicateFlag={setIdDuplicateFlag}
      setCompanyCheckFlag={setCompanyCheckFlag}
    />
  );
};

export default LoginContainer;
