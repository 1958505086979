import MetaCityList from '../MetaCityList';
import { useCallback, useEffect, useState } from 'react';
import { apiRoute, requestGetFile, requestSecureGet } from '@libs/api';
import { defaultPageTypes, PageTypes, PaginationTypes } from '@typedef/libs/pagination.types';
import { SurveyResponseType, SurveysType } from '@typedef/Monitoring/monitoring.type';
import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import useSearch from '@hooks/useSearch';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { EAgeGroup } from '@typedef/Visit/age-group.enum';
import { EGender } from '@typedef/Visit/gender.enum';
import { EVisitType } from '@typedef/Visit/visit-type.enum';

const MetaCityListContainer = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const profileData = useRecoilValue(accountSelector);

  const [surveysData, setSurveysData] = useState<SurveysType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageTypes>(defaultPageTypes);

  const { option, search } = useSearch();

  const loadSurveys = useCallback(
    async (option: string, search: string, page: number) => {
      const queryParams: Record<string, any> = {
        page: page,
        size: 10,
      };
      if (option) {
        queryParams[option] = search;
      }

      const { config, data } = await requestSecureGet<PaginationTypes<SurveyResponseType>>(
        `${apiRoute.monitoring.getSurveys}${parseQueryParamsToString(queryParams)}`,
        {},
        profileData.accessToken,
      );

      if (config.status === 200) {
        setSurveysData(
          data.content.map((v) => {
            return {
              ...v,
              ageGroup: EAgeGroup.valueOf(v.ageGroup),
              gender: EGender.valueOf(v.gender),
              visitType: EVisitType.valueOf(v.visitType),
            };
          }),
        );
        setPageInfo(data);
      } else {
        setSurveysData([]);
      }
    },
    [profileData.accessToken],
  );

  const surveyListExcelDownload = useCallback(async () => {
    const { config, data, headers } = await requestGetFile<BlobPart>(
      apiRoute.excel.surveyListExcelDownload + `?paged=false`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      const url = window.URL.createObjectURL(new Blob([data], { type: String(headers['Content-Type']) }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `메타시티 방문자 조회 데이터.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  }, [profileData.accessToken]);

  const onPageChange = () => {
    setSurveysData([]);
  };

  useEffect(() => {
    void loadSurveys(option!, search!, currentPage);
  }, [currentPage, option, search]);

  return (
    <MetaCityList
      surveysData={surveysData}
      pageInfo={pageInfo}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      onPageChange={onPageChange}
      surveyListExcelDownload={surveyListExcelDownload}
    />
  );
};

export default MetaCityListContainer;
