import { atom, selector } from 'recoil';

const saveStatusAtom = atom<boolean>({
  key: 'saveStatusAtom',
  default: true,
});

export const saveStatusSelector = selector({
  key: 'saveStatusSelector',
  get: ({ get }) => {
    return get(saveStatusAtom);
  },
  set: ({ set }, value) => {
    set(saveStatusAtom, value);
  },
});
