import ReactGridLayout from 'react-grid-layout';
import * as S from './styles/WidgetStyle';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { ArrowLeftWhite, Delete, Save } from '@assets/icons';
import React from 'react';
import { ContentTypes } from '@typedef/Contents/contents.types';
import CustomContainer from './containers/CustomContainer';
import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import CustomContentContainer from './containers/CustomContentContainer';
import { WIDGET_LIST, WidgetTypes } from '@typedef/Widget/widget.types';
import { getWidgetIcon } from '@libs/getWidgetIcon';
import CustomWidgetContainer from './containers/CustomWidgetContainer';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import ReactDropdown, { Option } from 'react-dropdown';
import { DropdownStyle } from '@styles/dropdownStyle';
import FileWidget from '@components/Widgets/components/FileWidget';
import { EAccountRole } from '@typedef/Account/account-role.enum';

type Props = {
  fileDropBackground: string | null;
  setFileDropBackground: React.Dispatch<React.SetStateAction<string | null>>;
  selectContentLayoutList: (selectContentId: string) => void;
  contentList: ContentTypes[];
  selectContent: ContentTypes | null;
  saveContent: () => void;
  handleLayoutChange: (newLayout: WidgetTypes[]) => void;
  layout: WidgetTypes[];
  layoutRef: React.RefObject<HTMLDivElement>;
  selectLayout: WidgetTypes | null;
  setSelectLayout: React.Dispatch<React.SetStateAction<WidgetTypes | null>>;
  onAddLayout: (type: EWidgetType) => void;
  handleLayoutDelete: () => void;
  onDropFileLayout: (file: File) => void;
  onClickModalOpen: (type: 'add' | 'delete') => void;
  handleOnChange: (id: string, value: any) => void;
  onContentOptionChange: React.Dispatch<React.SetStateAction<ContentTypes | null>>;
  resolution: number;
  customDisplaySize: { width: number; height: number };
  handleWidgetOrder: (widget: WidgetTypes, type: 'front' | 'back') => void;
};

const Widget = ({
  fileDropBackground,
  setFileDropBackground,
  selectContentLayoutList,
  contentList,
  selectContent,
  saveContent,
  handleLayoutChange,
  layout,
  layoutRef,
  selectLayout,
  setSelectLayout,
  onAddLayout,
  handleLayoutDelete,
  onDropFileLayout,
  onClickModalOpen,
  handleOnChange,
  onContentOptionChange,
  resolution,
  customDisplaySize,
  handleWidgetOrder,
}: Props) => {
  const ROLE = useRecoilValue(accountSelector).role;

  return (
    <>
      <S.Header>
        <DropdownStyle width={'270px'} fontSize={'24px'} height={'34px'}>
          <ReactDropdown
            className="react-dropdown-main"
            menuClassName="react-dropdown-menu"
            controlClassName="react-dropdown-control"
            options={contentList.map((v) => {
              return { label: v.name, value: v.id.toString() };
            })}
            onChange={(data: Option) => {
              selectContentLayoutList(data.value);
            }}
            value={selectContent ? selectContent.id.toString() : undefined}
            placeholder={'컨텐츠를 선택해주세요'}
            arrowOpen={undefined}
            arrowClosed={<div></div>}
          />
        </DropdownStyle>
        <div className="btns">
          <S.Button
            backgroundColor="#2b3e63"
            onClick={() => {
              onClickModalOpen('add');
            }}
          >
            새 컨텐츠
          </S.Button>
          {selectContent && selectContent?.id && (
            <>
              <S.Button
                backgroundColor="#2b3e63"
                onClick={() => {
                  saveContent();
                }}
                id="layoutSave"
              >
                <Save title="save" />
                <p>저장</p>
              </S.Button>

              <S.Button
                backgroundColor="#F35D3C"
                onClick={() => {
                  onClickModalOpen('delete');
                }}
              >
                <Delete title="delete" />
                <p>삭제</p>
              </S.Button>
            </>
          )}
        </div>
      </S.Header>
      <S.Container ref={layoutRef}>
        <S.WidgetContainer>
          {WIDGET_LIST.map((widget) => {
            if (widget.type.equals(EWidgetType.NEWS) || widget.type.equals(EWidgetType.WEATHER)) {
              if (EAccountRole.USER.equals(ROLE)) return <></>;
            }

            return (
              <S.WidgetButton
                onClick={() => {
                  onAddLayout(widget.type);
                }}
              >
                {getWidgetIcon(widget.type)}
                <div className={'widget-title'}>{widget.name}</div>
              </S.WidgetButton>
            );
          })}
        </S.WidgetContainer>
        <S.MainCustomContainer
          onDragOver={(e) => {
            setFileDropBackground('#e0f1eb');
            e.preventDefault();
            return;
          }}
          onDrop={(e) => {
            setFileDropBackground(null);
            onDropFileLayout(e.dataTransfer.files[0]);
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            setFileDropBackground(null);
            e.preventDefault();
            return;
          }}
        >
          <S.MainBoard>
            <S.WidgetBoard
              backgroundColor={fileDropBackground ?? selectContent?.backgroundColor}
              width={customDisplaySize.width}
              height={customDisplaySize.height}
            >
              {selectContent && (
                <S.CustomGridLayout
                  layout={layout as ReactGridLayout.Layout[]}
                  // isResizable={true}
                  isDraggable={true}
                  cols={selectContent.w / resolution}
                  width={customDisplaySize.width}
                  maxRows={selectContent.h / resolution}
                  rowHeight={customDisplaySize.height / (selectContent.h / resolution)}
                  margin={[0, 0]}
                  verticalCompact={false}
                  allowOverlap={true}
                  onDragStart={(_, oldItem) => {
                    setSelectLayout(layout.find((i) => i.i === oldItem.i) ?? null);
                  }}
                  onDragStop={(layout) => {
                    handleLayoutChange(layout as WidgetTypes[]);
                  }}
                  onResizeStop={(layout) => {
                    handleLayoutChange(layout as WidgetTypes[]);
                  }}
                >
                  {/*<S.Grid key={'new-grid'}>data</S.Grid>*/}
                  {layout.map((data) => (
                    <S.Widget
                      id={data.i}
                      key={data.i}
                      selected={selectLayout?.i === data.i}
                      styleOption={data}
                      fontRatio={customDisplaySize.width / selectContent.w}
                    >
                      {data.type.equals(EWidgetType.FILE) || data.type.equals(EWidgetType.FILE_LIST) ? (
                        <FileWidget widget={data} />
                      ) : data.type.equals(EWidgetType.TIME) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : data.type.equals(EWidgetType.FINEDUST) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : data.type.equals(EWidgetType.WEATHER) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : data.type.equals(EWidgetType.STOCK_INDEX) ? (
                        <CustomWidgetContainer widget={data} isSetting={true} content={selectContent} />
                      ) : (
                        <div className="input" key={data.i}>
                          <S.WidgetText
                            placeholder="addText"
                            spellCheck={false}
                            value={data.text}
                            onChange={(e) => {
                              handleOnChange('text', e.target.value);
                            }}
                            rows={(data?.text?.match(/\n/g)?.filter((item) => item !== '').length ?? 0) + 1}
                            readOnly={data.type.equals(EWidgetType.NEWS)}
                          />
                        </div>
                      )}
                    </S.Widget>
                  ))}
                </S.CustomGridLayout>
              )}
            </S.WidgetBoard>
          </S.MainBoard>
          <S.CustomBoard>
            <div>
              {selectLayout ? (
                <div className={'title-text'}>위젯 설정</div>
              ) : (
                selectContent && <div className={'title-text'}>Content 설정</div>
              )}
              {selectLayout ? (
                <CustomContainer selectLayout={selectLayout} layout={layout} handleOnChange={handleOnChange} />
              ) : (
                selectContent && (
                  <CustomContentContainer onContentOptionChange={onContentOptionChange} selectContent={selectContent} />
                )
              )}
            </div>
            {selectLayout && (
              <div>
                <S.CustomOrderArea>
                  <S.Button backgroundColor={'#2b3e63'} onClick={() => handleWidgetOrder(selectLayout, 'front')}>
                    <ArrowLeftWhite className="up-arrow" />
                    <p>위로</p>
                  </S.Button>
                  <S.Button backgroundColor={'#2b3e63'} onClick={() => handleWidgetOrder(selectLayout, 'back')}>
                    <ArrowLeftWhite className="down-arrow" />
                    <p>아래로</p>
                  </S.Button>
                </S.CustomOrderArea>
                <S.Button backgroundColor={'#f0614f'} onClick={handleLayoutDelete}>
                  <Delete title="delete" />
                  <p>DELETE</p>
                </S.Button>
              </div>
            )}
          </S.CustomBoard>
        </S.MainCustomContainer>
      </S.Container>
    </>
  );
};

export default Widget;
