import { RefObject } from 'react';
import * as S from './styles/NewsSubStyle';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import { convertToRgba } from '@libs/colorUtiles';

type Props = {
  widget: WidgetTypes;
  width: number;
  subtitleRef: RefObject<HTMLParagraphElement>;
};

const NewsSub = ({ widget, width, subtitleRef }: Props) => {
  return (
    <>
      <S.NewsContainer backgroundColor={convertToRgba(widget.backgroundColor,widget.opacity)}>
        <S.NewsLeftCustomPTag ref={subtitleRef} width={width} widget={widget} windowWidth={window.innerWidth}>
          {widget.text}
        </S.NewsLeftCustomPTag>
      </S.NewsContainer>
    </>
  );
};

export default NewsSub;
