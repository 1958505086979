import { WeatherTypes } from '@typedef/Widget/weather.types';
import { atom, selector } from 'recoil';

const weatherListAtom = atom<WeatherTypes[]>({
  key: 'weatherListAtom',
  default: [],
});

export const weatherListSelector = selector<WeatherTypes[]>({
  key: 'weatherListSelector',
  get: ({ get }) => {
    return get(weatherListAtom);
  },
  set: ({ set }, value) => {
    set(weatherListAtom, value);
  },
});
