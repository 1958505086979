import { WidgetTypes } from '@typedef/Widget/widget.types';
import { ContentTypes } from '@typedef/Contents/contents.types';
import React, { useCallback, useEffect, useState } from 'react';
import StockIndex from '@components/Common/StockIndex/StockIndex';
import { getResolutionValue } from '@libs/getResolutionValue';
import { apiRoute, requestGet } from '@libs/api';
import { StockIndexDefaultValue, StockIndexStateType, StockIndexType } from '@typedef/Widget/stock-index.type';
import dayjs from 'dayjs';

type props = {
  size: {
    width: number;
    height: number;
  };
  widget: WidgetTypes;
  isSetting?: boolean;
  content: ContentTypes;
};

const StockIndexContainer: React.FC<props> = ({ size, widget, isSetting, content }) => {
  const [stockIndex, setStockIndex] = useState<StockIndexStateType>({
    kosdaq: StockIndexDefaultValue,
    kospi: StockIndexDefaultValue,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const getStockIndex = useCallback(() => {
    const requests = ['KOSPI', 'KOSDAQ'].map(async (value) => {
      const { data: content, config } = await requestGet<StockIndexType>(`${apiRoute.stock.index}?type=${value}`, {});
      if (config.status === 200) {
        setStockIndex((prevState) => ({
          ...prevState,
          [value.toLowerCase()]: content,
        }));
      }
    });

    Promise.all(requests)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError(true);
      });
  }, []);

  const isMarketOpen = (): boolean => {
    const currentTime = dayjs();
    const startTime = dayjs().set('hour', 8).set('minute', 45);
    const endTime = dayjs().set('hour', 15).set('minute', 45);

    return currentTime.isSameOrAfter(startTime) && currentTime.isSameOrBefore(endTime);
  };

  useEffect(() => {
    getStockIndex();

    const timer = setInterval(() => {
      if (isMarketOpen()) {
        getStockIndex();
      }
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  return (
    <StockIndex
      size={size}
      content={content}
      isSetting={isSetting!}
      resolution={getResolutionValue(widget.resolution)}
      loading={loading}
      error={error}
      stockIndex={stockIndex}
    />
  );
};

export default StockIndexContainer;
