import { EDeviceStatus } from '@typedef/Device/device-status.enum';
import { ZonePlaytimeType, ZoneTypes } from '@typedef/Monitoring/zone.type';
import React from 'react';
import styled from 'styled-components';

type Props = {
  zoneName: string;
  zone?: ZoneTypes;
  statusOnly?: boolean;
  power: { status: EDeviceStatus; ip: string };
  zonePlayTime?: ZonePlaytimeType;
  handleDevicePower: (powerOn: EDeviceStatus, ip: string, zone?: ZoneTypes) => void;
};

const MapControlStatus: React.FC<Props> = ({ zoneName, zone, statusOnly, power, zonePlayTime, handleDevicePower }) => {
  return (
    <StatusContainer $power={power.status.equals(EDeviceStatus.ON)}>
      {!zonePlayTime && !statusOnly ? (
        <div className={'status-loading'}>데이터를 조회중입니다...</div>
      ) : (
        <>
          <div className={'zone-name'}>{zoneName}</div>
          {statusOnly ? (
            <div className={'status-only'}>
              전원 <span className={'power-status'}>{power.status.value}</span>
            </div>
          ) : (
            <>
              <div className={'status-controller'}>
                {power.status.equals(EDeviceStatus.REQUEST_OFF) || power.status.equals(EDeviceStatus.REQUEST_ON) ? (
                  <div>{`전원을 ${power.status.equals(EDeviceStatus.REQUEST_ON) ? '키는' : '끄는'} 중입니다.`}</div>
                ) : (
                  <>
                    전원 <span className={'power-status'}>{power.status.value}</span>
                    {power.status.equals(EDeviceStatus.OFF) && (
                      <button
                        className={'button-on'}
                        onClick={() => handleDevicePower(EDeviceStatus.REQUEST_ON, power.ip, zone)}
                      >
                        켜기
                      </button>
                    )}
                    {power.status.equals(EDeviceStatus.ON) && (
                      <button
                        className={'button-off'}
                        onClick={() => handleDevicePower(EDeviceStatus.REQUEST_OFF, power.ip, zone)}
                      >
                        끄기
                      </button>
                    )}
                  </>
                )}
              </div>

              <div className={'play-time'}>
                <div>
                  플레이 횟수 <br />
                  <span>{zonePlayTime?.playtime}</span> 회
                </div>

                <div>
                  플레이 시간 <br />
                  <span>{zonePlayTime?.playHour}</span>시간 <span>{zonePlayTime?.playMinute}</span>분
                </div>
              </div>
            </>
          )}
        </>
      )}
    </StatusContainer>
  );
};

const StatusContainer = styled.div<{ $power?: boolean }>`
  text-align: center;

  * {
    font-weight: 600;
  }

  .status-loading {
    font-weight: 400;
    color: #bbb;
    text-align: center;
    font-size: 14px;
  }

  span {
    color: #256662;
    font-size: 12px;
  }

  .power-status {
    font-weight: bold;
    color: ${({ $power }) => ($power ? '#CC3300' : '#6C6C6C')};
  }

  & > .zone-name {
    font-size: 12px;
  }

  & > .status-only {
    font-size: 12px;
    margin: 10px auto 0;
    display: flex;
    justify-content: space-between;
    width: 55px;
  }

  & > .status-controller {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    width: 165px;
    font-size: 12px;

    & > div {
      width: 100%;
      text-align: center;
      font-size: 13px;
      color: #256662;
    }

    & > button {
      width: 45px;
      height: 18px;
      font-size: 8px;
      border: none;
      border-radius: 5px;
      color: #fff;
    }

    & > .button-on {
      background: #4399ff;
    }

    & > .button-off {
      background: #6c6c6c;
    }
  }

  & > .play-time {
    margin-top: 15px;
    width: 165px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: auto;

    span {
      font-size: 15px;
    }

    &::before {
      content: '';
      left: 50%;
      position: absolute;
      top: 0;
      height: 100%;
      border-left: 1px solid #eee;
      transform: translateX(-50%);
    }
  }
`;

export default MapControlStatus;
