import { RefObject } from 'react';
import * as S from './styles/SubStyle';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import { convertToRgba } from '@libs/colorUtiles';

type Props = {
  widget: WidgetTypes;
  width: number;
  subtitleRef: RefObject<HTMLParagraphElement>;
};

const Sub = ({ widget, width, subtitleRef }: Props) => {
  return (
    <>
      <S.Container backgroundColor={convertToRgba(widget.backgroundColor,widget.opacity)}>
        <S.LeftCustomPTag ref={subtitleRef} width={width} widget={widget} windowWidth={window.innerWidth}>
          {widget.text}
        </S.LeftCustomPTag>
      </S.Container>
    </>
  );
};

export default Sub;
