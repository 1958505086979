import * as S from './styles/MonitoringStyle';
import React, {useState} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {lineData, lineOptions} from "@components/Monitoring/tempChartData";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

const UserStatistics = () => {

  return (
    <S.Container>
      <S.Header>
        통합관제모니터링 | 사용자 통계 조회
      </S.Header>
      <S.Article>
        <div className={'background'}>
          {[...Array(3)].map((_, i) => (
          <div className={'container'} key={i}>
            <S.ContainerHeader>
              <h3>{i === 0 ? '일간' : i === 1 ? '주간' : '월간'}</h3>
              <button>엑셀로 저장</button>
            </S.ContainerHeader>

            <div className={'usage-sub-header'}>
              VR/MR
            </div>

            <div className={'graph-container'}>
              <div>컨텐츠 정보</div>
              <Line data={lineData} options={lineOptions}/>
            </div>

            <div className={'usage-sub-header'}>
              자전거/모니터
            </div>

            <div className={'graph-container'}>
              <div>컨텐츠 정보</div>
              <Line data={lineData} options={lineOptions}/>
            </div>
          </div>
          ))}
        </div>
      </S.Article>
    </S.Container>
  )
}

export default UserStatistics