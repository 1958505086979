import styled from 'styled-components';

const space = '15px';
const height = '40px';

export const Container = styled.main`
  width: 100%;
  height: 100%;
`;

export const Header = styled.header`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.button<{ backgroundColor: string }>`
  height: ${height};
  padding: 0 ${space};
  border: 1px solid ${({ backgroundColor }) => backgroundColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 15px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  p {
    color: #f1f1f1;
  }

  &:active {
    opacity: 0.8;
  }
`;

export const Article = styled.article`
  margin-top: 10px;

  .background {
    ${({ theme }) => theme.flex.col};
    padding: ${space};
    background-color: #fff;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    border-radius: 7px;
    height: 70vh;

    .tableContent {
      height: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #ccc;
      }
    }

    table {
      width: 100%;
      table-layout: fixed;

      thead {
        tr {
          th {
            padding: ${space} 0;
            text-align: center;
            font-size: 17px;
            font-weight: 600;

            input[type='checkbox'] {
              cursor: pointer;
              scale: 1.3;
            }
          }
        }
      }
    }
  }
`;

export const DeviceType = styled.div<{ checked: boolean }>`
  cursor: pointer;
  width: auto;
  padding: 10px 14px;
  border-radius: 4px;
  margin-right: ${space};
  background: ${({ checked }) => (checked ? '#8ab4f8' : '#ddd')};
  color: ${({ checked }) => (checked ? '#fff' : '#474747')};
  font-size: 16px;
  transition:
    color 0.2s,
    background 0.2s;
`;

export const Tr = styled.tr<{ checked: boolean }>`
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? '#e0f1eb' : '#ffffff')};
  height: 8vh;

  td {
    vertical-align: middle;
    padding: 10px 0;
    text-align: center;
    font-size: 15px;

    & > div {
      color: #b1b1b1;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    input[type='checkbox'] {
      cursor: pointer;
      scale: 1.3;

      &:checked {
        background-color: #fff;
      }
    }

    select {
      width: 100%;
      border-radius: 5px;
    }

    a {
      color: #8ab4f8;
    }
  }

  &:hover {
    background-color: ${({ checked }) => (checked ? '#e0f1eb' : '#f0f0f0')};
  }
`;

export const Switch = styled.div`
  text-align: center;

  input[type='checkbox'] {
    display: none;
  }

  label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 54px;
    height: 24px;
    background: #cc3300;
    border: 2px solid #cc3300;
    border-radius: 20px;
    transition: 0.4s;

    span {
      position: absolute;
      top: 2px;
      left: 2px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 20px;
      background: #fff;
      transition: 0.4s;
    }
  }

  /* checking style */
  input[type='checkbox']:checked + label {
    background: #339900;
    border: 2px solid #339900;
  }

  /* move */
  input[type='checkbox']:checked + label span {
    left: 32px;
    background: #fff;
    box-shadow: 1px 2px 3px #00000020;
  }
`;

export const DeviceStatusTd = styled.td<{ $powerOn: boolean }>`
  color: ${({ $powerOn }) => ($powerOn ? '#339900' : '#cc3300')};
`;
