import { ContentTypes } from '@typedef/Contents/contents.types';
import { useCallback } from 'react';
import CustomBackgroundColor from '../components/CustomBackgroundColor';
import CustomSize from '../components/CustomSize';

type Props = {
  onContentOptionChange: React.Dispatch<React.SetStateAction<ContentTypes | null>>;
  selectContent: ContentTypes | null;
};

const CustomContentContainer = ({ onContentOptionChange, selectContent }: Props) => {
  const onBackgroundChange = useCallback(
    (id: string, value: any) => {
      if (selectContent) onContentOptionChange({ ...selectContent, [id]: value });
    },
    [selectContent, onContentOptionChange],
  );

  return (
    <>
      <CustomBackgroundColor handleOnChange={onBackgroundChange} selectLayout={selectContent} />
      <CustomSize handleOnChange={onBackgroundChange} selectLayout={selectContent} />
    </>
  );
};

export default CustomContentContainer;
