import * as S from './styles/VisitStyle';
import ReactDropdown, { Option } from 'react-dropdown';
import { WeatherTypes } from '@typedef/Widget/weather.types';
import 'react-dropdown/style.css';
import { VisitorRequestType } from '@typedef/Visit/visitor.types';
import { EAgeGroup } from '@typedef/Visit/age-group.enum';
import { EGender } from '@typedef/Visit/gender.enum';
import { VisitInputColumnTypes } from '@typedef/Visit/visit-input-column.types';
import { VisitInputColumn } from '@components/Visit/components/VisitInputColumn';
import { EVisitType } from '@typedef/Visit/visit-type.enum';

type Props = {
  visitor: VisitorRequestType;
  onVisitorChanged: <K extends keyof VisitorRequestType>(name: K, value: VisitorRequestType[K]) => void;
  weatherList: WeatherTypes[];
  weatherDict: Record<string, WeatherTypes>;
  onHomeButtonClicked: () => void;
  onCompleteButtonClicked: () => void;
};

const Visit = ({
  visitor,
  onVisitorChanged,
  weatherList,
  weatherDict,
  onHomeButtonClicked,
  onCompleteButtonClicked,
}: Props) => {
  const inputColumns: VisitInputColumnTypes<VisitorRequestType>[] = [
    {
      title: '동행자 수',
      columnKey: 'companionCount',
      type: 'number',
      required: true,
    },
    {
      title: '성별',
      columnKey: 'gender',
      type: 'multi-button',
      required: true,
      options: EGender.values.map((v) => ({ label: v.name, value: v.value })),
    },
    {
      title: '나이대',
      columnKey: 'ageGroup',
      required: true,
      type: 'dropdown',
      options: EAgeGroup.values.map((v) => ({ value: v.value, label: v.name })),
    },
    {
      title: '방문유형',
      columnKey: 'visitType',
      required: true,
      type: 'dropdown',
      options: EVisitType.values.map((v) => ({ value: v.name, label: v.name })),
    },
    {
      title: '의성군 거주여부',
      columnKey: 'isLivingInUiseong',
      type: 'check',
      required: true,
    },
  ];

  const addressInputColumns: { title: string; index: number }[] = [];
  if (visitor.isLivingInUiseong) {
    addressInputColumns.push({ title: '거주지역 읍/면/동', index: 2 });
  } else {
    addressInputColumns.push({ title: '거주지역 시/도', index: 0 });
    addressInputColumns.push({ title: '거주지역 시/군/구', index: 1 });
  }

  const locationsList: Option[][] = [
    weatherList
      .filter((weather) => !weather.location2)
      // location1 가 "경상북도" "경상남도" 최상단 위치, 뒤는 기존 정렬 적용
      .sort((a, b) => {
        if (a.location1 === '경상북도' && b.location1 !== '경상북도') {
          return -1;
        }
        if (a.location1 !== '경상북도' && b.location1 === '경상북도') {
          return 1;
        }
        if (a.location1 === '경상남도' && b.location1 !== '경상남도') {
          return -1;
        }
        if (a.location1 !== '경상남도' && b.location1 === '경상남도') {
          return 1;
        }
        return a.createdAt.localeCompare(b.createdAt);
      })
      .map((weather) => ({
        label: weather.location1,
        value: weather.id!.toString(),
      })),
    weatherList
      .filter(
        (weather) =>
          weather.location2 && !weather.location3 && weatherDict[visitor.weatherId]?.location1 === weather.location1,
      )
      .map((weather) => ({
        label: weather.location2,
        value: weather.id!.toString(),
      })),
    weatherList
      .filter((weather) => weather.location3 && weatherDict[visitor.weatherId]?.location2 === weather.location2)
      .map((weather) => ({
        label: weather.location3,
        value: weather.id!.toString(),
      })),
  ];

  return (
    <S.ContentContainer>
      <S.TitleText>방문자 정보</S.TitleText>
      <S.Grid>
        {inputColumns.map((column) => (
          <VisitInputColumn<VisitorRequestType>
            key={`inputColumn_${column.columnKey}`}
            {...column}
            inputs={visitor}
            onInputsChanged={onVisitorChanged}
          />
        ))}
        <br />
        {addressInputColumns.map(({ title, index }) => (
          <S.InputContainer key={`inputColumn_address_${index}`}>
            {title} {index !== 1 && <span style={{ color: 'red' }}>*</span>}
            <ReactDropdown
              placeholder=" "
              controlClassName="dropdown"
              placeholderClassName="dropdown-placeholder"
              arrowClassName="dropdown-arrow"
              options={locationsList?.[index] ?? []}
              value={
                (weatherDict[visitor.weatherId]?.[`location${index + 1}` as keyof WeatherTypes] as
                  | string
                  | undefined) ?? ''
              }
              onChange={(option) => onVisitorChanged('weatherId', option.value)}
            />
          </S.InputContainer>
        ))}
        <S.EmptyContainer />
      </S.Grid>
      <S.ButtonGrid>
        <S.Button height={65} fontSize={30} onClick={onHomeButtonClicked}>
          이전으로
        </S.Button>
        <S.Button height={65} fontSize={30} onClick={onCompleteButtonClicked}>
          다음
        </S.Button>
      </S.ButtonGrid>
    </S.ContentContainer>
  );
};

export default Visit;
