import { apiRoute, requestSecurePatch } from '@libs/api';
import { useCallback } from 'react';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';
import { accountSelector } from '@stories/account';
import { useRecoilValue } from 'recoil';

export default function useDevicePowerManage() {
  const token = useRecoilValue(accountSelector).accessToken;

  const updateDevicePower = useCallback(async (ip: string, status: EDeviceStatus) => {
    const { config, data } = await requestSecurePatch(
      apiRoute.devices.updateDeviceStatus.replace('{ip}', ip),
      {},
      { status: status.value },
      token,
    );
    return { config, data };
  }, []);

  return { updateDevicePower };
}
