import styled from 'styled-components';
import { Header } from '@components/Devices/styles/DevicesStyle';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const MainHeader = styled(Header)`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  min-height: 70vh;

  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  border-radius: 7px;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #fff;
`;
export const DeviceNothing = styled.div`
  font-size: 35px;
  font-weight: bold;
`;
export const DeviceList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 230px);

  gap: 25px;
  justify-content: space-around;
  align-content: start;

  margin-top: 10px;
`;
export const Device = styled.button`
  display: flex;
  flex-direction: column;

  width: 230px;
  height: 300px;

  margin-top: 30px;
  margin-bottom: 30px;

  background-color: #fff;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  border-radius: 7px;

  padding: 10px;
`;
export const DeviceName = styled.div`
  & > div:first-child {
    font-size: 20px;
    font-weight: bold;
  }

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DeviceImageBox = styled.div`
  position: relative;

  width: 200px;
  height: 200px;
  margin-top: 20px;

  background-color: #000;
  color: #fff;
`;
export const DeviceImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: fill;
`;

export const DeviceNotWork = styled.div`
  width: 100%;
  height: 100%;
  background-color: #333;
  color: #ebebeb;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageSize = styled.div`
  width: auto;
  height: auto;

  font-size: 12px;
  color: #fff;

  position: absolute;

  bottom: 5px;
  left: 5px;
`;

export const ContentName = styled.div`
  width: auto;
  height: auto;

  margin-top: 10px;
  font-size: 20px;
`;

export const DeviceStatus = styled.button<{ status: boolean }>`
  width: 60px;
  height: 28px;
  border-radius: 50px;
  background-color: ${({ status }) => (status ? '#ECF9F0' : '#EBEBEB')};
  color: ${({ status }) => (status ? '#3DC061' : '#C4C4C4')};
  float: right;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
`;
