import { deciceSearchOptions, DeviceTypes } from '@typedef/Device/device.types';
import * as S from './styles/DevicesStyle';
import React from 'react';
import { Link } from 'react-router-dom';
import { Delete, Plus } from '@assets/icons';
import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import { ECompanyType } from '@typedef/Company/company-type.enum';
import { EDeviceType } from '@typedef/Device/device-type.enum';
import SearchTableContainer from '@components/Common/SearchTable/containers/SearchTableContainer';
import Pagination from '@components/Common/Pagination/Pagination';
import { PageTypes } from '@typedef/libs/pagination.types';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';

type Props = {
  deviceList: DeviceTypes[];
  checkedList: DeviceTypes[];
  setCheckedList: React.Dispatch<React.SetStateAction<DeviceTypes[]>>;
  onClickModalOpen: (key: string, device?: DeviceTypes, status?: boolean) => void;
  ratioGCDCalculator: (width: number, height: number) => string;
  selectedDeviceType: EDeviceType;
  handleSelectedEDevice: (v: EDeviceType) => void;
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  onSearchSummit: () => void;
};

const COL_GROUP = [3, 10, 10, 10, 10, 10, 7, 10, 5];
const COMPUTER_COL_GROUP = [3, 10, 10, 10, 10, 10, 5];

const Devices = ({
  deviceList,
  checkedList,
  setCheckedList,
  onClickModalOpen,
  ratioGCDCalculator,
  selectedDeviceType,
  handleSelectedEDevice,
  currentPage,
  pageInfo,
  setCurrentPage,
  onPageChange,
  onSearchSummit,
}: Props) => {
  const account = useRecoilValue(accountSelector);

  return (
    <S.Container>
      <S.Header>
        {EDeviceType.values.map((v) => {
          if (
            EDeviceType.COMPUTER.equals(v) &&
            ![ECompanyType.YOUTH_WORKSHOP, ECompanyType.MAKER_SPACE].some((it) => it.equals(account.company.type))
          ) {
            return <></>;
          }
          return (
            <S.DeviceType
              checked={selectedDeviceType.equals(v)}
              onClick={() => {
                handleSelectedEDevice(v);
              }}
            >
              {v.name}
            </S.DeviceType>
          );
        })}
        <SearchTableContainer searchOptions={deciceSearchOptions} onSummit={onSearchSummit} />
        <S.Button
          backgroundColor="#2b3e63"
          onClick={() => {
            onClickModalOpen('add');
          }}
        >
          <Plus title="plus" />
          <p>추가</p>
        </S.Button>
        <S.Button
          backgroundColor={checkedList.length > 0 ? '#f0614f' : '#ddd'}
          onClick={() => {
            onClickModalOpen('delete');
          }}
          disabled={checkedList.length <= 0}
        >
          <Delete title="delete" />
          <p>삭제</p>
        </S.Button>
      </S.Header>
      <S.Article>
        <div className="background">
          <div className="tableHeader">
            <table>
              <colgroup>
                {selectedDeviceType.equals(EDeviceType.BOARD)
                  ? COL_GROUP.map((col, index) => <col width={col + '%'} key={index + 'header'} />)
                  : COMPUTER_COL_GROUP.map((col, index) => <col width={col + '%'} key={index + 'header'} />)}
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedList(deviceList);
                        } else {
                          setCheckedList([]);
                        }
                      }}
                    />
                  </th>
                  {selectedDeviceType.equals(EDeviceType.BOARD) ? (
                    <>
                      <th>이름</th>
                      <th>위치</th>
                      <th>크기</th>
                      <th>디스플레이 방향</th>
                      <th>IP</th>
                      <th>장비 키</th>
                      <th>전원 상태</th>
                      <th>전원 관리</th>
                    </>
                  ) : (
                    <>
                      <th>이름</th>
                      <th>위치</th>
                      <th>IP</th>
                      <th>MAC</th>
                      <th>전원 상태</th>
                      <th>전원 관리</th>
                    </>
                  )}
                </tr>
              </thead>
            </table>
          </div>
          <div className="tableContent">
            <table>
              <colgroup>
                {selectedDeviceType.equals(EDeviceType.BOARD)
                  ? COL_GROUP.map((col, index) => <col width={col + '%'} key={index + 'header'} />)
                  : COMPUTER_COL_GROUP.map((col, index) => <col width={col + '%'} key={index + 'header'} />)}
              </colgroup>
              <tbody>
                {deviceList.map((device) => (
                  <S.Tr
                    id={device.id.toString()}
                    checked={checkedList.findIndex((v) => v.id === device.id) > -1}
                    key={device.id}
                    onClick={() => {
                      onClickModalOpen('update', device);
                    }}
                  >
                    <td
                      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      <input
                        type="checkbox"
                        id={device.id ? device.id.toString() : '0'}
                        checked={checkedList.findIndex((v) => v.id === device.id) > -1}
                        onChange={(e) => {
                          e.stopPropagation();

                          if (e.target.checked === true) {
                            setCheckedList((prev) => {
                              const clone = [...prev];

                              clone.push(device);

                              return clone;
                            });
                          } else {
                            setCheckedList(checkedList.filter((check) => check.id !== device.id));
                          }
                        }}
                      />
                    </td>
                    {selectedDeviceType.equals(EDeviceType.BOARD) ? (
                      <>
                        <td>{device.name}</td>
                        <td>{device.location}</td>
                        <td>{device.width && device.height && `${device.width} x ${device.height}`}</td>
                        <td>{device.displayType?.name}</td>
                        <td>{device.ip}</td>
                        <td
                          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            event.stopPropagation();
                          }}
                        >
                          <Link to={`/monitor/${device.uniqueKey}`} target="_blank">
                            {device.uniqueKey}
                          </Link>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{device.name}</td>
                        <td>{device.location}</td>
                        <td>{device.ip}</td>
                        <td>{device.macAddress}</td>
                      </>
                    )}
                    <S.DeviceStatusTd $powerOn={device.status?.data!}>
                      {device.status?.equals(EDeviceStatus.ON) && 'ON'}
                      {device.status?.equals(EDeviceStatus.OFF) && 'OFF'}
                      {device.status?.equals(EDeviceStatus.REQUEST_ON) && '키는 중'}
                      {device.status?.equals(EDeviceStatus.REQUEST_OFF) && '끄는 중'}
                    </S.DeviceStatusTd>
                    {device.isPowerManageable ? (
                      <td
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                          event.stopPropagation();
                        }}
                      >
                        <S.Switch>
                          <input
                            id={'power-' + device.id}
                            type="checkbox"
                            checked={device.status?.data}
                            onClick={(e) => {
                              onClickModalOpen('power', device);
                            }}
                          />
                          <label htmlFor={'power-' + device.id}>
                            <span></span>
                          </label>
                        </S.Switch>
                      </td>
                    ) : (
                      <td>
                        <div>
                          전원관리
                          <br />
                          불가
                        </div>
                      </td>
                    )}
                  </S.Tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </S.Article>
      <Pagination
        onPageChange={onPageChange}
        pageInfo={pageInfo}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </S.Container>
  );
};

export default Devices;
