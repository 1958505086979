import { CompanyTypes } from '@typedef/Company/company.types';
import React from 'react';
import * as S from '@components/Account/styles/AccountManageStyle';
import { ECompanyType } from '@typedef/Company/company-type.enum';

type Props = {
  id: string;
  type: string;
  companyData: CompanyTypes;
  setCompanyData: React.Dispatch<React.SetStateAction<CompanyTypes>>;
  onClickBtn: () => void;
};

const CompanyManage = ({ id, type, companyData, setCompanyData, onClickBtn }: Props) => {
  return (
    <S.Container className="modalBody">
      <article className="contents">
        {id === 'delete' ? (
          <div className="delete">
            <p>선택하신 회사들을 삭제하시겠습니까?</p>
            <p>관련된 계정들도 삭제됩니다.</p>
          </div>
        ) : id === 'add' ? (
          <>
            <div className="split">
              <p className="title">이름</p>
              <S.Input>
                <input
                  type="text"
                  value={companyData.name}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      name: e.target.value,
                    });
                  }}
                />
              </S.Input>
            </div>
            <div className="split">
              <p className="title">사업자번호</p>
              <S.Input>
                <input
                  type="text"
                  value={companyData.number}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      number: e.target.value,
                    });
                  }}
                />
              </S.Input>
            </div>
            <div className="split">
              <p className="title">회사 유형</p>
              <S.Input>
                <select
                  value={companyData?.type?.value ?? ''}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      type: ECompanyType.valueOf(e.target.value)!,
                    });
                  }}
                >
                  <option value="" disabled>
                    선택
                  </option>
                  {ECompanyType.values.map((v) => {
                    return <option value={v.value}>{v.name}</option>;
                  })}
                </select>
              </S.Input>
            </div>
          </>
        ) : (
          <>
            <div className="split">
              <p className="title">이름</p>
              <S.Input>
                <input
                  type="text"
                  value={companyData.name}
                  style={{
                    backgroundColor: '#ddd',
                  }}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      name: e.target.value,
                    });
                  }}
                />
              </S.Input>
            </div>
            <div className="split">
              <p className="title">사업자번호</p>
              <S.Input>
                <input
                  type="text"
                  value={companyData.number}
                  style={{
                    backgroundColor: '#ddd',
                  }}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      number: e.target.value,
                    });
                  }}
                />
              </S.Input>
            </div>
            <div className="split">
              <p className="title">회사 유형</p>
              <S.Input>
                <select
                  value={companyData?.type?.value ?? ''}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      type: ECompanyType.valueOf(e.target.value)!,
                    });
                  }}
                >
                  <option value="" disabled>
                    선택
                  </option>
                  {ECompanyType.values.map((v) => {
                    return <option value={v.value}>{v.name}</option>;
                  })}
                </select>
              </S.Input>
            </div>
            <div className="split">
              <p className="title">최대 장비 갯수</p>
              <S.Input>
                <input
                  type="number"
                  value={companyData.maxDeviceCount}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      maxDeviceCount: Number(e.target.value),
                    });
                  }}
                />
              </S.Input>
            </div>
            <div className="split">
              <p className="title">최대 파일 사이즈</p>
              <S.Input>
                <input
                  type="number"
                  value={companyData.maxFileSize}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      maxFileSize: Number(e.target.value),
                    });
                  }}
                />
              </S.Input>
            </div>
          </>
        )}
      </article>
      <article className="bottoms">
        <button className={id === 'delete' ? 'danger' : ''} onClick={onClickBtn}>
          {type}하기
        </button>
      </article>
    </S.Container>
  );
};

export default CompanyManage;
