import * as S from './styles/ListStyle';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { realCitySearchOptions } from '@typedef/Monitoring/monitoring.type';
import { useState } from 'react';
import SearchTableContainer from '@components/Common/SearchTable/containers/SearchTableContainer';
import { VisitorType } from '@typedef/Visit/visitor.types';
import { getWeatherLocationStr } from '@libs/weatherUtils';
import Pagination from '../Common/Pagination/Pagination';
import { PageTypes } from '@typedef/libs/pagination.types';

type Props = {
  visitorData: VisitorType[];
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  visitorListExcelDownload: () => void;
};

const RealCityVisitorList = ({
  visitorData,
  pageInfo,
  currentPage,
  setCurrentPage,
  onPageChange,
  visitorListExcelDownload,
}: Props) => {
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);

  return (
    <S.Container>
      <S.Header>
        <div className={'title'}>
          <div onClick={() => navigate('/monitoring')}>통합관제모니터링</div>
          <div>레알시티 방문자 조회</div>
        </div>
        <div className="search">
          <button
            className={'download-button'}
            onClick={() => {
              if (visitorData.length <= 0) return alert('저장할 데이터가 없습니다.');
              visitorListExcelDownload();
            }}
          >
            엑셀로 저장
          </button>
          <SearchTableContainer searchOptions={realCitySearchOptions} />
        </div>
      </S.Header>
      <S.Article>
        <div className={'background'}>
          <div className="tableHeader">
            <table>
              <colgroup>
                <col width="5%" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>방문 날짜</th>
                  <th>성별</th>
                  <th>거주지역</th>
                  <th>방문 목적</th>
                  <th>나이대</th>
                  <th>동행자</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tableContent">
            <table>
              <colgroup>
                <col width="5%" />
              </colgroup>
              <tbody>
                {visitorData.map((data, i) => (
                  <S.Tr $checked={false}>
                    <td>{i + 1 + (currentPage - 1) * 10}</td>
                    <td>{!data.createdAt ? '데이터 없음' : dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>{!data.gender ? <span>데이터 없음</span> : data.gender.name}</td>
                    <td>{!data.weather ? <span>데이터 없음</span> : getWeatherLocationStr(data.weather)}</td>
                    <td>{!data.visitType ? <span>데이터 없음</span> : data.visitType.name}</td>
                    <td>{!data.ageGroup ? <span>데이터 없음</span> : data.ageGroup.name}</td>
                    <td>{data.companionCount}명</td>
                  </S.Tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          onPageChange={onPageChange}
          pageInfo={pageInfo}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </S.Article>
    </S.Container>
  );
};

export default RealCityVisitorList;
