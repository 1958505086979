import { EWidgetType, EWidgetTypeProps } from './widget-type.enum';
import { EFileStreamingType, EFileStreamingTypeProps } from '@typedef/Widget/file-streaming-type.enum';
import { getBase64ByFileUrl } from '@libs/fileUtil';
import { FILE_DOWNLOAD_URL } from '@libs/api';

export type WidgetTypes = {
  type: EWidgetType;
  name: string;
  i: string;
  id?: string;
  x: number;
  y: number;
  w: number;
  h: number;
  static: boolean;
  isDraggable: boolean;
  isResizeable: boolean;
  minW: number;
  maxW: number;
  minH: number;
  maxH: number;
  newsKeyword?: string;
  newsSeparator?: string;
  backgroundColor: string;
  fileStreamingType?: EFileStreamingTypeProps;
  file?: string; //path
  fileType?: string;
  files?: string[];
  subSpeed?: number;
  autoPlaySpeed?: number;
  text?: string;
  textColor?: string;
  textFamily?: string;
  textItalic?: boolean;
  textSize?: number;
  textUnderline?: boolean;
  textWeight?: boolean;
  newWidget: boolean;
  weatherId?: string;
  location1?: string;
  location2?: string;
  location3?: string;
  weather?: string;
  resolution?: number;

  opacity?: number;
  priority: number;

  // only use in client
  fileData?: string;
};
export type RequestWidgetTypes = Omit<WidgetTypes, 'type' | 'fileStreamingType'> & {
  type: string;
  fileStreamingType?: string;
};

export type RawWidgetTypes = Omit<WidgetTypes, 'type'> & {
  type: EWidgetTypeProps;
};

export const WIDGET_LIST: WidgetTypes[] = [
  {
    i: 'HEADER',
    type: EWidgetType.HEADER,
    name: EWidgetType.HEADER.name,
    x: 0,
    y: 0,
    w: 40,
    h: 10,
    minW: 3,
    maxW: 1000000,
    minH: 3,
    maxH: 1000000,
    isResizeable: true,
    isDraggable: true,
    static: false,
    newWidget: true,
    textSize: 50,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
  {
    i: 'MAIN',
    type: EWidgetType.MAIN,
    name: EWidgetType.MAIN.name,
    x: 0,
    y: 0,
    w: 16,
    h: 4,
    minW: 3,
    maxW: 1000000,
    minH: 3,
    maxH: 1000000,
    isResizeable: true,
    isDraggable: true,
    static: false,
    newWidget: true,
    textSize: 16,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
  {
    i: 'SUB',
    type: EWidgetType.SUB,
    name: EWidgetType.SUB.name,
    x: 0,
    y: 0,
    w: 16,
    h: 3,
    minW: 3,
    maxW: 1000000,
    minH: 3,
    maxH: 1000000,
    isResizeable: true,
    isDraggable: true,
    static: true,
    newWidget: true,
    subSpeed: 100,
    textSize: 16,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
  {
    i: 'BLOCK',
    type: EWidgetType.BLOCK,
    name: EWidgetType.BLOCK.name,
    x: 0,
    y: 0,
    w: 10,
    h: 10,
    minW: 3,
    maxW: 1000000,
    minH: 3,
    maxH: 1000000,
    isResizeable: true,
    isDraggable: true,
    static: false,
    newWidget: true,
    textSize: 16,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
  {
    i: 'NEWS',
    type: EWidgetType.NEWS,
    name: EWidgetType.NEWS.name,
    x: 0,
    y: 0,
    w: 16,
    h: 3,
    minW: 3,
    maxW: 1000000,
    minH: 3,
    maxH: 1000000,
    newsKeyword: '속보',
    newsSeparator: '',
    isResizeable: true,
    isDraggable: true,
    static: true,
    newWidget: true,
    text: '키워드를 설정하면 키워드에 해당하는 뉴스가 나오게 됩니다. 위젯을 저장할시 동작합니다.',
    subSpeed: 100,
    textSize: 16,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
  {
    i: 'WEATHER',
    type: EWidgetType.WEATHER,
    name: EWidgetType.WEATHER.name,
    x: 0,
    y: 0,
    w: 8,
    h: 4.5,
    resolution: 9 / 16,
    minW: 4,
    maxW: 1000000,
    minH: 4,
    maxH: 1000000,
    isResizeable: true,
    isDraggable: true,
    static: false,
    newWidget: true,
    text: '지역을 설정하면 해당 지역의 날씨가 나옵니다. 위젯을 저장할시 동작합니다.',
    weatherId: '',
    location1: '서울특별시',
    textSize: 16,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
  {
    i: 'TIME',
    type: EWidgetType.TIME,
    name: EWidgetType.TIME.name,
    x: 0,
    y: 0,
    w: 8,
    h: 4.5,
    resolution: 9 / 16,
    minW: 4,
    maxW: 1000000,
    minH: 4,
    maxH: 1000000,
    isResizeable: true,
    isDraggable: true,
    static: false,
    newWidget: true,
    textSize: 16,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
  {
    i: 'FINEDUST',
    type: EWidgetType.FINEDUST,
    name: EWidgetType.FINEDUST.name,
    x: 0,
    y: 0,
    w: 8,
    h: 4.5,
    resolution: 9 / 16,
    minW: 4,
    maxW: 1000000,
    minH: 4,
    maxH: 1000000,
    isResizeable: true,
    isDraggable: true,
    static: false,
    newWidget: true,
    weatherId: '',
    location1: '서울특별시',
    textSize: 16,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
  {
    i: 'STOCK_INDEX',
    type: EWidgetType.STOCK_INDEX,
    name: EWidgetType.STOCK_INDEX.name,
    x: 0,
    y: 0,
    w: 8,
    h: 4.5,
    resolution: 9 / 16,
    minW: 4,
    maxW: 1000000,
    minH: 4,
    maxH: 1000000,
    isResizeable: true,
    isDraggable: true,
    static: false,
    newWidget: true,
    textSize: 16,
    backgroundColor: '#ffffff',
    opacity: 100,
    priority: -1,
  },
];

export type WidgetStyleProps = {
  height: number;
  width: number;
  isSetting?: boolean;
  resolution: ResolutionType;
  contentWidth: number;
  contentHeight: number;
};

export type FineDustStyleProps = WidgetStyleProps & {
  grade10: number | undefined;
  grade25: number | undefined;
};

export type ResolutionType = '1:1' | '9:16' | '32:9' | '16:9' | '4:3';

export const createWidget = async (rawWidget: RawWidgetTypes): Promise<WidgetTypes> => {
  const newWidget = {
    ...rawWidget,
    file: rawWidget.file,
    type: EWidgetType.valueOf(rawWidget.type)!,
  };

  if (newWidget.file) {
    if (!newWidget.fileStreamingType) {
      newWidget.fileStreamingType = EFileStreamingType.STREAMING.value;
    }
    if (EFileStreamingType.DOWNLOAD.equals(newWidget.fileStreamingType!)) {
      newWidget.fileData = await getBase64ByFileUrl(`${FILE_DOWNLOAD_URL}/${rawWidget.file}`);
    }
  }

  return newWidget;
};
