// 컨텐츠 타입
export type ContentTypes = {
  id: string;
  name: string;
  w: number;
  h: number;

  companyId: string;
  backgroundColor: string;
};

export const defaultContentTypes: ContentTypes = {
  id: '',
  name: '',
  w: 1920,
  h: 1080,
  companyId: '',
  backgroundColor: '',
};
