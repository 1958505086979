import { LoaderExtendType, MonitoringDeviceType } from '@typedef/Monitoring/monitoring.type';
import * as S from './styles/ListStyle';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SearchTableContainer from '@components/Common/SearchTable/containers/SearchTableContainer';
import { ContentsPlayListType, ContentsPlaySearchTypes } from '@typedef/Monitoring/monitoringContents.type';
import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import { PageTypes } from '@typedef/libs/pagination.types';
import Pagination from '@components/Common/Pagination/Pagination';

type Props = {
  contentPlaySummariesData: ContentsPlayListType[];
  searchOptions: SearchTableType<ContentsPlaySearchTypes>[];
  deviceData: LoaderExtendType<MonitoringDeviceType[]> | undefined;
  pageInfo: PageTypes;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  contentsPlayListExcelDownload: () => void;
};

const ContentPlaybackList = ({
  contentPlaySummariesData,
  searchOptions,
  deviceData,
  pageInfo,
  currentPage,
  setCurrentPage,
  onPageChange,
  contentsPlayListExcelDownload,
}: Props) => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.Header>
        <div className={'title'}>
          <div onClick={() => navigate('/monitoring')}>통합관제모니터링</div>
          <div>컨텐츠 재생 조회</div>
        </div>
        <div className="search">
          <button
            className={'download-button'}
            onClick={() => {
              if (contentPlaySummariesData.length <= 0) return alert('저장할 데이터가 없습니다.');
              contentsPlayListExcelDownload();
            }}
          >
            엑셀로 저장
          </button>
          <SearchTableContainer searchOptions={searchOptions} />
        </div>
      </S.Header>
      <S.Article>
        <div className={'background'}>
          <div className="tableHeader">
            <table>
              <colgroup>
                <col width="5%" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>프로그램명</th>
                  <th>플레이 시간</th>
                  <th>존</th>
                  <th>시작 시간</th>
                  <th>종료 시간</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tableContent">
            <table>
              <colgroup>
                <col width="5%" />
              </colgroup>
              <tbody>
                {contentPlaySummariesData.map((data, i) => (
                  <S.Tr $checked={false}>
                    <td>{i + 1 + (currentPage - 1) * 10}</td>
                    <td>{data.programName}</td>
                    <td>{`${Math.floor(data.playMilliSec / 1000 / 3600)}시간 ${Math.floor(
                      ((data.playMilliSec / 1000) % 3600) / 60,
                    )}분`}</td>
                    <td>
                      {deviceData && deviceData.data.some((item) => item.ip === data.ip) ? (
                        deviceData.data.find((item) => item.ip === data.ip)!.zone.name
                      ) : (
                        <span>데이터 없음</span>
                      )}
                    </td>
                    <td>
                      {!data.startedAt ? <span>데이터 없음</span> : dayjs(data.startedAt).format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                    <td>
                      {!data.endedAt ? <span>데이터 없음</span> : dayjs(data.endedAt).format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                  </S.Tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          onPageChange={onPageChange}
          pageInfo={pageInfo}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </S.Article>
    </S.Container>
  );
};

export default ContentPlaybackList;
