import { ContentTypes } from '@typedef/Contents/contents.types';
import '../styles/CustomStyle.scss';
import { WidgetTypes } from '@typedef/Widget/widget.types';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: WidgetTypes | ContentTypes | null;
};

const CustomSize = ({ handleOnChange, selectLayout }: Props) => {
  return (
    <div className="custom">
      <div className="size">
        <p className="title">가로</p>
        <input
          type="number"
          value={selectLayout?.w ?? '1'}
          onChange={(e) => {
            if (Number(e.target.value) > 100000) handleOnChange('w', 100000);
            else if (Number(e.target.value) < 1) handleOnChange('w', 1);
            else handleOnChange('w', e.target.value);
          }}
          min={1}
          max={100000}
          disabled={true}
        />
      </div>
      <div className="size">
        <p className="title">세로</p>
        <input
          type="number"
          value={selectLayout?.h ?? '1'}
          onChange={(e) => {
            if (Number(e.target.value) > 100000) handleOnChange('h', 100000);
            else if (Number(e.target.value) < 1) handleOnChange('h', 1);
            else handleOnChange('h', e.target.value);
          }}
          min={1}
          max={100000}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default CustomSize;
