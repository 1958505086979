import * as S from './styles/ScheduleStyle';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-calendar-timeline/lib/Timeline.css';
import dayjs from 'dayjs';
import React from 'react';
import { DeviceTypes } from '@typedef/Device/device.types';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import { ScheduleTypes } from '@typedef/Schedule/schedule.types';
import { ContentTypes } from '@typedef/Contents/contents.types';
import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import ReactDropdown, { Option } from 'react-dropdown';
import { DropdownStyle } from '@styles/dropdownStyle';

const localizer = dayjsLocalizer(dayjs);

type Props = {
  onClickManage: (type: '추가' | '수정', selectScheduleId?: string) => void;
  scheduleList: ScheduleTypes[];
  contentList: ContentTypes[];
  deviceList: DeviceTypes[];
  selectDeviceList: { id: string; name: string }[];
  selectDevice: DeviceTypes | undefined;
  setSelectDevice: React.Dispatch<React.SetStateAction<DeviceTypes | undefined>>;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
};

const Schedule = ({
  onClickManage,
  scheduleList,
  contentList,
  deviceList,
  selectDeviceList,
  selectDevice,
  setSelectDevice,
  setDate,
}: Props) => {
  return (
    <S.Container>
      <header>
        <div className="left ">
          <p className="title">스케줄링</p>
          <DropdownStyle width={'270px'} fontSize={'24px'} height={'34px'}>
            <ReactDropdown
              className="react-dropdown-main"
              menuClassName="react-dropdown-menu"
              controlClassName="react-dropdown-control"
              options={selectDeviceList.map((v) => {
                return { label: v.name, value: v.id };
              })}
              onChange={(value: Option) => {
                setSelectDevice(deviceList.find((device) => device.id === value.value)!);
              }}
              value={selectDevice ? selectDevice.id.toString() : undefined}
              placeholder={'장비을 선택해주세요'}
            />
          </DropdownStyle>
        </div>
        <div className="btns">
          <button onClick={() => onClickManage('추가')} disabled={deviceList.length <= 0}>
            스케줄 추가
          </button>
        </div>
      </header>
      <S.TimeLineContainer>
        <Calendar
          events={scheduleList.map((schedule) => ({
            id: schedule.id,
            title: contentList.find((content) => content.id === schedule.contentId)?.name,
            start: new Date(
              dayjs(
                schedule.startDate +
                  ' ' +
                  (!schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)
                    ? schedule.dailyStartTime
                    : schedule.startTime),
              ).format(),
            ),
            end: new Date(
              dayjs(
                schedule.endDate +
                  ' ' +
                  (!schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)
                    ? schedule.dailyEndTime
                    : schedule.endTime),
              ).format(),
            ),
          }))}
          onSelectEvent={(event) => onClickManage('수정', event.id)}
          localizer={localizer}
          views={['month', 'day']}
          formats={{
            monthHeaderFormat: 'YYYY년 M월',
            dayHeaderFormat: 'YYYY년 M월 D일 dddd',
          }}
          showMultiDayTimes
          step={60}
          style={{ height: 550 }}
          onNavigate={(date: Date) => setDate(date)}
        />
      </S.TimeLineContainer>
    </S.Container>
  );
};

export default Schedule;
