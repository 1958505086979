import Schedule from '../Schedule';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalSelector } from '@stories/modal';
import ScheduleManageContainer from './ScheduleManageContainer';
import {
  DayOfTheWeek,
  GetSchedulesInputsDefault,
  GetSchedulesInputsTypes,
  ScheduleResponseTypes,
  ScheduleTypes,
} from '@typedef/Schedule/schedule.types';
import { apiRoute, requestSecureGet } from '@libs/api';
import { convertDeviceResponseTypeToType, DeviceResponseTypes, DeviceTypes } from '@typedef/Device/device.types';
import { accountSelector } from '@stories/account';
import { ContentTypes } from '@typedef/Contents/contents.types';
import dayjs from 'dayjs';
import { EScheduleRepetitionType } from '@typedef/Schedule/repetition-type.enum';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { EDeviceType } from '@typedef/Device/device-type.enum';

const ScheduleContainer = () => {
  const [reload, setReload] = useState<number>(0);
  // DB 스케줄 리스트
  const [scheduleList, setScheduleList] = useState<ScheduleTypes[]>([]);
  // DB 컨텐츠 리스트
  const [contentList, setContentList] = useState<ContentTypes[]>([]);
  // 전광판 리스트
  const [deviceList, setDeviceList] = useState<DeviceTypes[]>([]);
  // 전광판 선택 리스트
  const [selectDeviceList, setSelectDeviceList] = useState<{ id: string; name: string }[]>([]);
  // 현재 선택한 전광판
  const [selectDevice, setSelectDevice] = useState<DeviceTypes | undefined>();
  const [date, setDate] = useState<Date>(new Date());

  const token = useRecoilValue(accountSelector).accessToken;
  const setModal = useSetRecoilState(modalSelector);

  // 수정 모달 오픈
  const onClickManage = useCallback(
    async (type: '추가' | '수정', selectScheduleId?: string) => {
      setModal({
        header: `스케줄 ${type}하기`,
        close: true,
        body: (
          <ScheduleManageContainer
            type={type}
            reload={() => setReload((prev) => prev + 1)}
            deviceId={selectDevice?.id}
            contentList={contentList}
            schedule={scheduleList.find((schedule) => schedule.id === selectScheduleId)}
          />
        ),
      });
    },
    [selectDevice, scheduleList, contentList],
  );

  // 장비 조회
  const loadDevices = useCallback(async () => {
    if (token.length === 0) return;

    const queryParams: Record<string, any> = { type: EDeviceType.BOARD.value, paged: false };

    const {
      config,
      data: { content },
    } = await requestSecureGet<PaginationTypes<DeviceResponseTypes>>(
      `${apiRoute.devices.get}${parseQueryParamsToString(queryParams)}`,
      {},
      token,
    );

    if (config.status === 200) {
      setDeviceList(content.map((v) => convertDeviceResponseTypeToType(v)));

      const sortingDataList = content.map((device) => {
        return {
          id: device.id.toString(),
          name: device.name,
        };
      });

      setSelectDeviceList(sortingDataList);
      if (content) setSelectDevice(convertDeviceResponseTypeToType(content[0]) ?? null);
    }
  }, [token]);

  // 컨텐츠 조회
  const loadContent = useCallback(async () => {
    if (token.length === 0) return;

    const {
      config,
      data: { content },
    } = await requestSecureGet<PaginationTypes<ContentTypes>>(apiRoute.contents.get + '?paged=false', {}, token);

    if (config.status === 200) {
      setContentList(content);
    }
  }, [token]);

  // 스케줄 조회
  const loadSchedule = useCallback(async () => {
    if (!selectDevice) {
      return;
    }
    const getSchedulesInputs: GetSchedulesInputsTypes = {
      ...GetSchedulesInputsDefault,
      deviceId: selectDevice.id,
    };

    const {
      config,
      data: { content },
    } = await requestSecureGet<PaginationTypes<ScheduleResponseTypes>>(
      `${apiRoute.schedule.get}${parseQueryParamsToString(getSchedulesInputs)}`,
      {},
      token,
    );

    if (config.status !== 200) {
      return;
    }
    const newScheduleList = content.map((v) => {
      return {
        ...v,
        repetitionType: EScheduleRepetitionType.valueOf(v.repetitionType),
      };
    });

    setScheduleList((_) => {
      const repeatScheduleList: ScheduleTypes[] = [];

      newScheduleList.forEach((schedule) => {
        if (schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)) {
          // 반복 일정이 아니라면 바로 푸시
          repeatScheduleList.push({
            ...schedule,
            startDate: schedule.startDate,
            startTime: schedule.startTime,
            endDate: schedule.endDate,
            endTime: schedule.endTime,
          });
          return;
        } else {
          // 반복 일정이라면
          let startDate = dayjs(schedule.startDate + schedule.startTime);
          let endDate = dayjs(schedule.endDate! + schedule.endTime!);
          const repetitionEndTime = !schedule.repetitionEndTime ? undefined : dayjs(schedule.repetitionEndTime);
          const previous = dayjs(date).subtract(1, 'month');
          const next = dayjs(date).add(1, 'month');

          if (
            !(repetitionEndTime === undefined && !schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)) &&
            previous.isAfter(repetitionEndTime, 'month')
          ) {
            return;
          } // 반복이 끝나는 시간이 전달보다 더 전에 있는 일정이라면 바로 리턴

          if (startDate.isBefore(previous, 'month')) {
            endDate = endDate.add(previous.diff(startDate, 'month'), 'month');
            startDate = startDate.add(previous.diff(startDate, 'month'), 'month');
          }

          const dailyStartTime = schedule.startTime?.split(':').map(Number)!;
          const dailyEndTime = schedule.endTime?.split(':').map(Number)!;

          while (startDate.isSameOrBefore(next, 'month')) {
            if (
              !(repetitionEndTime === undefined && !schedule.repetitionType.equals(EScheduleRepetitionType.ONCE)) &&
              startDate.isAfter(repetitionEndTime, 'day')
            ) {
              break;
            }

            const startDateAndTime = dayjs(startDate)
              .hour(dailyStartTime[0])
              .minute(dailyStartTime[1])
              .second(0)
              .toDate();

            if (schedule.repetitionType.equals(EScheduleRepetitionType.EVERY_DAY)) {
              repeatScheduleList.push({
                ...schedule,
                startDate: startDate.format('YYYY-MM-DD'),
                startTime: schedule.startTime,
                endDate: !repetitionEndTime
                  ? startDate.format('YYYY-MM-DD')
                  : startDate.isSame(repetitionEndTime, 'date')
                    ? repetitionEndTime?.format('YYYY-MM-DD')
                    : startDate.format('YYYY-MM-DD'),
                endTime: startDate.isSame(repetitionEndTime, 'date')
                  ? repetitionEndTime?.format('HH:mm')
                  : schedule.endTime,
                repetitionEndTime: schedule.repetitionEndTime,
                dailyStartTime: schedule.startTime,
                dailyEndTime: !repetitionEndTime
                  ? schedule.endTime
                  : startDate.isSame(repetitionEndTime, 'date')
                    ? repetitionEndTime?.format('HH:mm')
                    : schedule.endTime,
              });
            } else {
              const dayOfWeek = ['SUN', 'MON', 'TUES', 'WED', 'THURS', 'FRI', 'SAT'][startDateAndTime.getDay()];
              if (schedule.repetitionDayOfTheWeek?.includes(dayOfWeek as DayOfTheWeek)) {
                repeatScheduleList.push({
                  ...schedule,
                  startDate: startDate.format('YYYY-MM-DD'),
                  startTime: schedule.startTime,
                  endDate: startDate.format('YYYY-MM-DD'),
                  endTime: schedule.endTime,
                  repetitionEndTime: schedule.repetitionEndTime,
                  dailyStartTime: schedule.startTime,
                  dailyEndTime: schedule.endTime,
                });
              }
            }
            startDate = startDate.add(1, 'day');
            endDate = endDate.add(1, 'day');
          }
        }
      });
      return repeatScheduleList;
    });
  }, [token, selectDevice, date]);

  useEffect(() => {
    if (!selectDevice?.id) {
      return;
    }

    loadSchedule();
    loadContent();
  }, [selectDevice, reload]);

  useEffect(() => {
    loadSchedule();
  }, [date]);

  useEffect(() => {
    loadDevices();
  }, []);

  return (
    <Schedule
      onClickManage={onClickManage}
      scheduleList={scheduleList}
      contentList={contentList}
      deviceList={deviceList}
      selectDeviceList={selectDeviceList}
      selectDevice={selectDevice}
      setSelectDevice={setSelectDevice}
      setDate={setDate}
    />
  );
};

export default ScheduleContainer;
