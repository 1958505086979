import '../styles/CustomStyle.scss';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import ReactDropdown, { Option } from 'react-dropdown';
import { getResolutionValue } from '@libs/getResolutionValue';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: WidgetTypes | null;
};

export const RESOLUTION_LIST: Option[] = [
  {
    value: String(9 / 16),
    label: '16:9',
    className: '16to9',
  },
  {
    value: String(9 / 32),
    label: '32:9',
    className: '32to9',
  },
  {
    value: String(1),
    label: '1:1',
    className: '1to1',
  },
  {
    value: String(16 / 9),
    label: '9:16',
    className: '9to16',
  },
  {
    value: String(3 / 4),
    label: '4:3',
    className: '4to3',
  },
];

const CustomResolution = ({ handleOnChange, selectLayout }: Props) => {
  return (
    <div className="resolution">
      <p className="resolution-subtitle">비율</p>
      <div>
        <ReactDropdown
          options={RESOLUTION_LIST}
          className={'resolution-dropdown'}
          value={getResolutionValue(selectLayout?.resolution)}
          onChange={(value: Option) => {
            handleOnChange('resolution', Number(value.value));
          }}
        />
      </div>
    </div>
  );
};

export default CustomResolution;
