import { WidgetStyleProps, WidgetTypes } from '@typedef/Widget/widget.types';
import styled from 'styled-components';
import { getFontSize, getFontStyle } from '@libs/getFontStyle';
import { CurrentFontType } from '@typedef/Widget/font.types';
import { convertToRgba } from '@libs/colorUtiles';
import ReactGridLayout from 'react-grid-layout';

// 헤더
export const Header = styled.section`
  width: 100%;
  ${({ theme }) => theme.flex.row};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px 0;

  button {
    color: #fff;
  }

  .save-check {
    margin-left: auto;
    margin-right: 5px;

    .saveLoading {
      display: flex;
      align-items: center;
      justify-content: right;

      svg {
        width: 35px;
      }

      .loading {
        flex-direction: row;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: right;

    button {
      margin-left: 15px;

      &:first-child {
        margin: 0;
      }
    }
  }
`;

// 헤더 버튼
export const Button = styled.button<{ backgroundColor: string }>`
  min-width: 90px;
  width: 100%;
  height: 50px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: ${({ backgroundColor }) => backgroundColor};

  p {
    color: #fff;
    display: flex;
  }

  svg {
    margin-right: 5px;
    width: 25px;
  }

  .up-arrow {
    transform: rotate(90deg);
  }

  .down-arrow {
    transform: rotate(270deg);
  }
`;

export const CustomGridLayout = styled(ReactGridLayout)`
  width: 100%;
  height: 100% !important;
  background-size: ${({ width, cols, rowHeight }) => cols && rowHeight && width && `${width / cols}px ${rowHeight}px`};
  background-image: linear-gradient(to bottom, #dedede 1px, transparent 1px),
    linear-gradient(to right, #dedede 1px, transparent 1px);
  background-position: -1px -1px;
  overflow: hidden;
`;

// 레이아웃 내부 위젯
export const Widget = styled.div<{
  selected: boolean;
  styleOption: WidgetTypes;
  fontRatio?: number;
}>`
  cursor: pointer;

  border: 1.5px ${({ selected }) => (selected ? 'dashed #2196f3' : 'solid #333')};
  background-color: ${({ styleOption }) =>
    convertToRgba(styleOption.backgroundColor ?? '#ffffff', styleOption.opacity)};

  video,
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: fill;
  }

  .input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  textarea {
    width: 100%;
    height: auto;
    resize: none;
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    text-align: center;
    vertical-align: middle;
    color: ${({ styleOption }) => styleOption.textColor};
    font-weight: ${({ styleOption }) => (styleOption.textWeight ? 'bold' : 'normal')};
    font-style: ${({ styleOption }) => (styleOption.textItalic ? 'italic' : 'none')};
    text-decoration: ${({ styleOption }) => (styleOption.textUnderline ? 'underline' : 'none')};
    font-family: ${({ styleOption }) => getFontStyle(styleOption.textFamily)};
    font-size: ${({ styleOption, fontRatio }) =>
      styleOption.textSize ? styleOption.textSize * (fontRatio ?? 1) : '0'}pt;

    &:focus {
      outline: none;
    }
  }
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
export const WidgetContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  gap: 24px;
`;

export const WidgetButton = styled.button`
  display: flex;
  flex-direction: column;
  width: 154px;
  height: 125px;

  align-items: center;
  justify-content: center;

  gap: 6px;

  background-color: #ffffff;

  border-radius: 8px;
  border: 1px solid transparent;

  .widget-icon {
    width: 60px;
    height: 60px;
  }

  .widget-title {
    font-family: 'Pretendard', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;

    text-align: center;

    color: #333333;
  }

  .widget-size-text {
    font-family: 'Pretendard', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    text-align: center;

    color: #8a8a8a;
  }
`;

export const MainCustomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;

  width: auto;

  margin-top: 24px;
`;

export const MainBoard = styled.div`
  width: 960px;
  height: 534px;

  display: flex;

  align-items: center;
  justify-content: center;
`;

export const WidgetBoard = styled.div<{
  width: number;
  height: number;
  backgroundColor?: string;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  max-width: 960px;
  max-height: 534px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#ffffff'};
  border-radius: 8px;
`;

export const CustomBoard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 225px;
  height: 534px;

  background-color: #ffffff;

  border-radius: 8px;

  padding: 15px;
  margin-left: 15px;

  .title-text {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 18px;
  }

  button {
    margin-top: auto;
  }
`;

export const CustomOrderArea = styled.div`
  display: flex;

  margin-bottom: 10px;
  gap: 5px;
`;
export const WidgetText = styled.textarea`
  width: 100%;
  height: 100%;

  overflow: auto;
  text-overflow: ellipsis;

  resize: none;
`;

export const LoadingModalContainer = styled.div`
  width: 100%;
  height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .loading-text {
    margin-top: 8rem;
    font-size: 18px;
    font-family: inherit;
  }
`;

export const FontDropdownContainer = styled.div<CurrentFontType>`
  .Dropdown-root {
    font-family: ${({ font }) => getFontStyle(font)};
  }

  .IBM-Plex-Sans-KR {
    font-family: ${() => getFontStyle('IBM Plex Sans KR')};
  }

  .Dongle {
    font-family: ${() => getFontStyle('Dongle')};
  }

  .Gowun-Batang {
    font-family: ${() => getFontStyle('Gowun Batang')};
  }

  .Hahmlet {
    font-family: ${() => getFontStyle('Hahmlet')};
  }

  .Noto-Serif-Korean {
    font-family: ${() => getFontStyle('Noto Serif KR')};
  }

  .Gaegu {
    font-family: ${() => getFontStyle('Gaegu')};
  }

  .Noto-Sans-Korean {
    font-family: ${() => getFontStyle('Noto Sans KR')};
  }

  .Gothic-A1 {
    font-family: ${() => getFontStyle('Gothic A1')};
  }

  .Nanum-Myeongjo {
    font-family: ${() => getFontStyle('Nanum Myeongjo')};
  }

  .Nanum-Gothic-Coding {
    font-family: ${() => getFontStyle('Nanum Gothic Coding')};
  }

  .Nanum-Gothic {
    font-family: ${() => getFontStyle('Nanum Gothic')};
  }
`;

const getResolutionStyle = (
  resolution: string,
  isSetting: boolean | undefined,
  width: number,
  height: number,
  contentWidth: number,
  contentHeight: number,
): string => {
  switch (resolution) {
    case '16:9':
      return `
        & > div {
          height: 80%;
          flex-direction: column;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .title {
          height: 35%;
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .icon-container {
            width: 28%;
            
            & > svg {
              height: 100%;
              width: 100%;
            }
          }
          
          .date-text {
             * {
                text-align: center;
                font-size: calc(${isSetting ? 1.5 : 2} * ${getFontSize(
                  width,
                  height,
                  {
                    w: contentWidth,
                    h: contentHeight,
                  },
                  isSetting!,
                )});
              }
              font-weight: 600;
            }
        }
          
          .time {
            width: 80%;
            height: auto;
            
            & > span {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            
            .ampm {
              font-size: calc(${isSetting ? 3.1 : 4.1} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
            
            .hhmm {
              font-size: calc(${isSetting ? 3.6 : 4.6} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
          }
    `;
    case '32:9':
      return `
        flex-direction: row;
        
        & > div {
          height: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .title {
          height: 35%;
          height: auto;
          width: 48%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .icon-container {
            width: 30%;
            
            & > svg {
              height: 100%;
              width: 100%;
            }
          }
        
        .date-text {
           * {
              text-align: center;
              font-size: calc(${isSetting ? 1.2 : 1.8} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
            font-weight: 600;
        }
      }
        
        .time {
          width: auto;
          height: auto;
            
          & > span {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          .ampm {
            font-size: calc(${isSetting ? 3 : 4} * ${getFontSize(
              width,
              height,
              {
                w: contentWidth,
                h: contentHeight,
              },
              isSetting!,
            )});
          }
          
          .hhmm {
            margin-left: 3%;
            font-size: calc(${isSetting ? 3.5 : 4.5} * ${getFontSize(
              width,
              height,
              {
                w: contentWidth,
                h: contentHeight,
              },
              isSetting!,
            )});
          }
        }
    `;
    case '1:1':
      return `
        & > div {
          height: 65%;
          flex-direction: column;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .title {
          height: 35%;
          width: 90%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .icon-container {
            width: 30%;
            
            & > svg {
              height: 100%;
              width: 100%;
            }
          }
          
          .date-text {
             * {
                text-align: center;
                font-size: calc(${isSetting ? 1.25 : 1.65} * ${getFontSize(
                  width,
                  height,
                  {
                    w: contentWidth,
                    h: contentHeight,
                  },
                  isSetting!,
                )});
              }
              font-weight: 600;
            }
        }
          
          .time {
            width: 90%;
            height: auto;
            
            & > span {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            
            .ampm {
              font-size: calc(${isSetting ? 3.1 : 4.1} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
            
            .hhmm {
              font-size: calc(${isSetting ? 3.6 : 4.6} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
          }
    `;
    case '9:16':
      return `
        & > div {
          height: 90%;
          width: 90%;
          flex-direction: column;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: top;
          height: auto;
          
          .icon-container {
            width: 30%;
            
            & > svg {
              height: 100%;
              width: 100%;
            }
          }
          
          .date-text {
             * {
                text-align: right;
                font-size: calc(${isSetting ? 1.6 : 2} * ${getFontSize(
                  width,
                  height,
                  {
                    w: contentWidth,
                    h: contentHeight,
                  },
                  isSetting!,
                )});
                
                font-weight: 600;
              }
              
              .divider {
                margin-top: 5%;
              }
              
              font-weight: 600;
            }
        }
          
          .time {
            width: 100%;
            height: auto;
            
            & > span {
              display: flex;
              flex-direction: column;
            }
            
            .ampm {
              text-align: left;
              font-size: calc(${isSetting ? 3.5 : 4.5} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
            
            .hhmm {
              text-align: left;
              margin-top: 5%;
              font-size: calc(${isSetting ? 4 : 5} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
          }
    `;
    case '4:3':
      return `
        & > div {
          height: 65%;
          flex-direction: column;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .title {
          height: 35%;
          width: 90%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .icon-container {
            width: 30%;
            
            & > svg {
              height: 100%;
              width: 100%;
            }
          }
          
          .date-text {
             * {
                text-align: center;
                font-size: calc(${isSetting ? 1.25 : 1.65} * ${getFontSize(
                  width,
                  height,
                  {
                    w: contentWidth,
                    h: contentHeight,
                  },
                  isSetting!,
                )});
              }
              font-weight: 600;
            }
          }
          
          .time {
            width: 90%;
            height: auto;
            
            & > span {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            
            .ampm {
              font-size: calc(${isSetting ? 3.1 : 4.1} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
            
            .hhmm {
              font-size: calc(${isSetting ? 3.6 : 4.6} * ${getFontSize(
                width,
                height,
                {
                  w: contentWidth,
                  h: contentHeight,
                },
                isSetting!,
              )});
            }
          }
    `;
    default:
      return '';
  }
};

export const TimeContainer = styled.div<WidgetStyleProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 5, 31, 0.9);

  * {
    color: #fff;
    text-align: center;
  }

  ${({ resolution, isSetting, width, height, contentWidth, contentHeight }) =>
    `${getResolutionStyle(resolution, isSetting, width, height, contentWidth, contentHeight)}`};

  & > div {
    width: 90%;

    .title {
      & > .date-text {
        font-weight: 600;
      }

      .ddd {
        margin-top: 5%;
      }
    }

    .ampm {
      color: #fff;
    }

    .hhmm {
      color: #f1c03a;
      font-family: 'Tauri', sans-serif;
    }
  }
`;
