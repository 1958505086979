import { WidgetTypes } from '@typedef/Widget/widget.types';
import '../styles/CustomStyle.scss';
import { isNumberFromString } from '@libs/inputValidator';

type Props = {
  handleOnChange: (id: string, value: any) => void;
  selectLayout: WidgetTypes | null;
};

const SubSpeed = ({ handleOnChange, selectLayout }: Props) => {
  return (
    <div className="custom">
      <div className="sub-speed">
        <p className="title">자막 속도(픽셀/초)</p>
        <input
          type="text"
          value={selectLayout?.subSpeed ?? 0}
          onChange={(e) => {
            if (isNumberFromString(e.target.value)) handleOnChange('subSpeed', Number(e.target.value));
          }}
        />
      </div>
    </div>
  );
};

export default SubSpeed;
