export type EDeviceTypeProps = 'BOARD' | 'COMPUTER' | 'PROJECTOR';

export class EDeviceType {
  static readonly BOARD: EDeviceType = new EDeviceType('BOARD', '전광판');
  static readonly PROJECTOR: EDeviceType = new EDeviceType('PROJECTOR', '프로젝터');
  static readonly COMPUTER: EDeviceType = new EDeviceType('COMPUTER', '기타(컴퓨터)');

  private constructor(
    public readonly value: EDeviceTypeProps,
    public readonly name: string,
  ) {}
  static readonly values = [EDeviceType.BOARD, EDeviceType.PROJECTOR, EDeviceType.COMPUTER];

  static valueOf(value: EDeviceTypeProps | string): EDeviceType | undefined {
    return EDeviceType.values.find((v) => v.value === value);
  }

  static isValue(value: string): boolean {
    return !!EDeviceType.values.find((v) => v.value === value);
  }

  equals(value: EDeviceTypeProps | EDeviceType) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
