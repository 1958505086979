import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import { useCallback, useEffect, useState } from 'react';
import {
  LoaderExtendType,
  MonitoringDeviceType,
  MonitoringResponseDeviceType,
  SurveyCountType,
} from '@typedef/Monitoring/monitoring.type';
import dayjs from 'dayjs';
import useDevicePowerManage from '@hooks/useDevicePowerManage';
import { apiRoute, requestSecureGet } from '@libs/api';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { convertMillisecondsToTime } from '@libs/convertMillisecondsToTime';
import Monitoring from '@components/Monitoring/Monitoring';
import { ContentsPlaySummariesStatisticsType } from '@typedef/Monitoring/monitoringContents.type';
import { ZonePlaytimeType, ZoneTypes } from '@typedef/Monitoring/zone.type';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';
import { convertDeviceResponseTypeToType } from '@typedef/Device/device.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { PeriodDefaultValue, PeriodType } from '@components/Common/CommonDate/CommonDate';

const MonitoringContainer = () => {
  const profileData = useRecoilValue(accountSelector);
  const account = useRecoilValue(accountSelector);
  const period: PeriodType = {
    ...PeriodDefaultValue,
    startedAt: dayjs().subtract(1, 'month').startOf('day').toISOString(),
  };

  const [metaCityPeopleCount, setMetaCityPeopleCount] = useState<{
    zepeto: { value: number; loading: boolean };
    webGL: { value: number; loading: boolean };
  }>({
    webGL: { loading: true, value: 0 },
    zepeto: { loading: true, value: 0 },
  });

  const [realCityPeopleCount, setRealCityPeopleCount] = useState<LoaderExtendType<number>>({ loading: true, data: 0 });
  const [realCityCompanionCount, setRealCityCompanionCount] = useState<LoaderExtendType<number>>({
    loading: true,
    data: 0,
  });
  const [deviceList, setDeviceList] = useState<LoaderExtendType<MonitoringDeviceType[]>>({ loading: true, data: [] });
  const [zonePlaytime, setZonePlaytime] = useState<ZonePlaytimeType[]>([]);

  const { updateDevicePower } = useDevicePowerManage();

  const loadZepetoPeopleCount = useCallback(async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType>(
      `${apiRoute.monitoring.zepetoPeopleCount}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setMetaCityPeopleCount((prevState) => ({ ...prevState, zepeto: { loading: false, value: data.value } }));
    }
  }, [profileData.accessToken]);

  const loadWebGLPeopleCount = useCallback(async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType>(
      `${apiRoute.monitoring.webGLPeopleCount}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setMetaCityPeopleCount((prevState) => ({ ...prevState, webGL: { loading: false, value: data.value } }));
    }
  }, [profileData.accessToken]);

  const loadRealCityPeopleCount = useCallback(async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType>(
      `${apiRoute.monitoring.realCityPeopleCount}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setRealCityPeopleCount({ loading: false, data: data.value });
    }
  }, []);

  const loadRealCityCompanionCount = useCallback(async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType>(
      `${apiRoute.monitoring.realCityCompanionCount}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setRealCityCompanionCount({ loading: false, data: data.value });
    }
  }, []);

  const loadDeviceList = useCallback(async () => {
    const queryParams: Record<string, any> = {
      paged: false,
    };
    const { config, data } = await requestSecureGet<PaginationTypes<MonitoringResponseDeviceType>>(
      `${apiRoute.monitoring.loadDeviceList}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setDeviceList({
        loading: false,
        data: data.content
          .filter((item) => item.zone !== null)
          .map((v) => {
            const monitoringDevice = convertDeviceResponseTypeToType(v) as MonitoringDeviceType;

            // 현재 시간
            const now = dayjs();
            const statusUpdatedAt = dayjs(monitoringDevice.statusUpdatedAt);
            // 특정한 시간이 현재 시간보다 120초 이전인지 확인
            const isBefore = statusUpdatedAt.add(120, 'second').isBefore(now);

            if (isBefore && monitoringDevice.status.equals(EDeviceStatus.REQUEST_OFF))
              updateDevicePower(monitoringDevice.ip, EDeviceStatus.ON);
            if (isBefore && monitoringDevice.status.equals(EDeviceStatus.REQUEST_ON))
              updateDevicePower(monitoringDevice.ip, EDeviceStatus.OFF);

            return monitoringDevice;
          }),
      });
    }
  }, [profileData.accessToken]);

  const loadContentsPlaySummariesStatistics = useCallback(
    async (ip: string, name: string, zoneName: string) => {
      const { config, data } = await requestSecureGet<ContentsPlaySummariesStatisticsType[]>(
        apiRoute.monitoring.contentsPlaySummariesStatistics + `?dateRange=WEEK&ip=${ip}`,
        {},
        profileData.accessToken,
      );

      if (config.status === 200) {
        const convertedTime = data.length ? convertMillisecondsToTime(data[0].playMilliSec) : { hours: 0, minutes: 0 };

        setZonePlaytime((prevState) => [
          ...prevState,
          {
            playtime: data.length ? data[0].count : 0,
            playMinute: convertedTime.minutes,
            playHour: convertedTime.hours,
            name: name,
            zoneName: zoneName,
          },
        ]);
      }
    },
    [profileData.accessToken],
  );

  const handleDevicePower = (powerOn: EDeviceStatus, ip: string, zone?: ZoneTypes) => {
    if (!zone) {
      updateDevicePower(ip, powerOn).then(loadDeviceList);
    } else {
      deviceList.data.forEach((v) => {
        if (v.zone.id === zone.id && v.isPowerManageable) updateDevicePower(v.ip, powerOn).then(loadDeviceList);
      });
    }
  };

  useEffect(() => {
    deviceList.data.map(
      (value) =>
        value.isPowerManageable &&
        value.zone !== null &&
        loadContentsPlaySummariesStatistics(value.ip, value.name, value.zone.name),
    );
  }, [deviceList.data.length]);

  useEffect(() => {
    loadZepetoPeopleCount();
    loadWebGLPeopleCount();
    loadRealCityPeopleCount();
    loadRealCityCompanionCount();
    loadDeviceList();

    const timer = setInterval(() => {
      loadDeviceList();
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Monitoring
      metaCityPeopleCount={metaCityPeopleCount}
      deviceList={deviceList}
      realCityPeopleCount={realCityPeopleCount}
      realCityCompanionCount={realCityCompanionCount}
      zonePlayTime={zonePlaytime}
      handleDevicePower={handleDevicePower}
      date={period}
      account={account}
    />
  );
};

export default MonitoringContainer;
