// start,end - ex) 2023-01-01T10:30:00

import { EScheduleRepetitionType, EScheduleRepetitionTypeTypes } from '@typedef/Schedule/repetition-type.enum';
import { PaginationFilterInputsDefault } from '@typedef/common.types';

export enum DayOfTheWeek {
  MON = 'MON',
  TUES = 'TUES',
  WED = 'WED',
  THURS = 'THURS',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export type ScheduleResponseTypes = {
  id: string;
  repetitionType: EScheduleRepetitionTypeTypes;
  // yyyy-MM-ddTHH:mm (반복 유형 끝나는 시간)
  repetitionEndTime?: string;
  repetitionDayOfTheWeek?: DayOfTheWeek[];
  // yyyy-MM-dd (반복 유형 or 유형 없음)
  startDate: string;
  // HH:mm (반복 유형 or 유형 없음)
  startTime: string;
  // yyyy-MM-dd (유형 없음)
  endDate?: string;
  // HH:mm (유형 없음)
  endTime?: string;
  contentId: string;
  deviceId: string;
  createdAt: string;
  updatedAt: string;
};

export type ScheduleTypes = Omit<ScheduleResponseTypes, 'repetitionType'> & {
  repetitionType: EScheduleRepetitionType;
  // yyyy-MM-ddTHH:mm (반복 유형)
  dailyStartTime?: string;
  // yyyy-MM-ddTHH:mm (반복 유형)
  dailyEndTime?: string;
};

export type ScheduleActiveTypes = {
  repetitionDayOfTheWeek?: DayOfTheWeek[];
};

export const GetSchedulesInputsDefault = {
  ...PaginationFilterInputsDefault,
  deviceId: '',
  contentId: '',
};

export type GetSchedulesInputsTypes = typeof GetSchedulesInputsDefault;
