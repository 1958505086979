import styled from 'styled-components';
import { FineDustStyleProps } from '@typedef/Widget/widget.types';
import { getFontSize } from '@libs/getFontStyle';

const getGradeColor = (grade: number | undefined) => {
  switch (grade) {
    case 1:
      return 'rgb(54, 108, 234)';
    case 2:
      return 'rgb(80, 210, 37)';
    case 3:
      return 'rgb(231, 112, 52)';
    case 4:
      return 'rgb(252, 63, 63)';
    default:
      return '';
  }
};

// 리팩토링 작업 미완료
const getResolutionStyle = (
  resolution: string,
  isSetting: boolean | undefined,
  width: number,
  height: number,
  contentWidth: number,
  contentHeight: number,
  grade10: number,
  grade25: number,
): string => {
  switch (resolution) {
    case '16:9':
      return `
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
      
          width: 100%;
          height: 100%;

          & > .icon-container {
            margin-bottom: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
      
            & > div {
              display: flex;
              justify-content: center;
              align-items: center;
              width: calc(50% - 5px);
              height: 100%;
      
              & > div {
                height: 55%;
                width: 50%;
      
                &:first-child {
                  text-align: center;
      
                  .title {
                    font-size: calc(
                      ${isSetting ? 1 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                  }
      
                  .grade10,
                  .grade25 {
                    font-weight: bold;
                    margin: 15% auto;
                    width: 80%;
                    height: 30%;
                    color: ${!grade10 ? '#000' : '#fff'};
                    font-size: calc(
                      ${isSetting ? 1 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50vw;
                  }
      
                  .grade10 {
                    background: ${getGradeColor(grade10)};
                  }
      
                  .grade25 {
                    background: ${getGradeColor(grade25)};
                  }
      
                  .unit {
                    & > span {
                      font-size: calc(
                      ${isSetting ? 1.2 : 1.7} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                      font-weight: bold;
                    }
      
                      font-size: calc(
                      ${isSetting ? 1 : 1.5} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                  }
                }
              }
              
              .icon-container {
                  & > svg {
                    width: 90%;
                    height: 100%;
                  }
              }
      
              & > div {
                font-size: calc(
                      ${isSetting ? 2 : 3} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                font-weight: bold;
              }
            }
      
            & > .divider {
              height: 100%;
              width: 0.05rem;
              background-color: #ccc;
            }
      
            & > .loading {
              text-align: center;
            }
          }
        }
      `;
    case '32:9':
      return `
        & > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
      
          width: 100%;
          height: 100%;

          & > .icon-container {
            margin-bottom: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
      
            & > div {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 90%;
              width: 50%;
      
              & > div {
                height: 90%;
                width: 50%;
      
                &:first-child {
                  text-align: center;
      
                  .title {
                    margin-left: 8%;
                    text-align: left;
                    font-size: calc(
                      ${isSetting ? 1 : 1.5} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                  }
      
                  .grade10,
                  .grade25 {
                    font-weight: bold;
                    margin: 15% auto;
                    height: 30%;
                    color: ${!grade10 ? '#000' : '#fff'};
                    font-size: calc(
                      ${isSetting ? 1 : 1.5} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    margin: 18% auto 10%;
                    width: 65%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50vw;
                  }
      
                  .grade10 {
                    background: ${getGradeColor(grade10)};
                  }
      
                  .grade25 {
                    background: ${getGradeColor(grade25)};
                  }
      
                  .unit {
                    & > span {
                      font-size: calc(
                      ${isSetting ? 1.5 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                      font-weight: bold;
                    }
      
                      font-size: calc(
                      ${isSetting ? 1.2 : 1.7} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                  }
                }
              }
              
              .icon-container {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  
                  & > svg {
                    width: 60%;
                    height: 100%;
                  }
              }
      
              & > div {
                font-size: calc(
                      ${isSetting ? 2 : 3} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                font-weight: bold;
              }
            }
      
            & > .divider {
              height: 100%;
              width: 0.05rem;
              background-color: #ccc;
            }
      
            & > .loading {
              text-align: center;
            }
          }
        }
      `;
    case '1:1':
      return `
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
      
          width: 100%;
          height: 100%;

          & > .icon-container {
            margin-bottom: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
      
            & > div {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              height: calc(50% - 5px);
      
              & > div {
                height: 90%;
                width: 100%;
      
                &:first-child {
                  text-align: center;
      
                  .title {
                    margin-left: 8%;
                    text-align: left;
                    font-size: calc(
                      ${isSetting ? 1 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                  }
      
                  .grade10,
                  .grade25 {
                    font-weight: bold;
                    margin: 10% auto;
                    width: 55%;
                    height: 30%;
                    color: ${!grade10 ? '#000' : '#fff'};
                    font-size: calc(
                      ${isSetting ? 1 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50vw;
                  }
      
                  .grade10 {
                    background: ${getGradeColor(grade10)};
                  }
      
                  .grade25 {
                    background: ${getGradeColor(grade25)};
                  }
      
                  .unit {
                    & > span {
                      font-size: calc(
                      ${isSetting ? 1.5 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                      font-weight: bold;
                    }
      
                      font-size: calc(
                      ${isSetting ? 1.2 : 1.7} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                  }
                }
              }
              
              .icon-container {
                  display: flex;
                  justify-content: center;
                  
                  & > svg {
                    width: 50%;
                    height: 100%;
                  }
              }
      
              & > div {
                font-size: calc(
                      ${isSetting ? 2 : 3} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                font-weight: bold;
              }
            }
      
            & > .divider {
              height: 0.05rem;
              width: 100%;
              background-color: #ccc;
            }
      
            & > .loading {
              text-align: center;
            }
          }
        }
      `;
    case '9:16':
      return `
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
      
          width: 100%;
          height: 100%;

          & > .icon-container {
            margin-bottom: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
      
            & > div {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              height: calc(50% - 5px);
      
              & > div {
                height: 90%;
                width: 100%;
      
                &:first-child {
                  text-align: center;
      
                  .title {
                    margin-left: 8%;
                    text-align: left;
                    font-size: calc(
                      ${isSetting ? 1 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                  }
      
                  .grade10,
                  .grade25 {
                    font-weight: bold;
                    margin: 30% auto 10%;
                    width: 75%;
                    height: 25%;
                    color: ${!grade10 ? '#000' : '#fff'};
                    font-size: calc(
                      ${isSetting ? 1 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50vw;
                  }
      
                  .grade10 {
                    background: ${getGradeColor(grade10)};
                  }
      
                  .grade25 {
                    background: ${getGradeColor(grade25)};
                  }
      
                  .unit {
                    & > span {
                      font-size: calc(
                      ${isSetting ? 1.5 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                      font-weight: bold;
                    }
      
                      font-size: calc(
                      ${isSetting ? 1.2 : 1.7} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                  }
                }
              }
              
              .icon-container {
                  display: flex;
                  justify-content: center;
                  
                  & > svg {
                    width: 70%;
                    height: 100%;
                  }
                }
      
              & > div {
                font-size: calc(
                      ${isSetting ? 2 : 3} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                font-weight: bold;
              }
            }
      
            & > .divider {
              height: 0.05rem;
              width: 100%;
              background-color: #ccc;
            }
      
            & > .loading {
              text-align: center;
            }
          }
        }
      `;
    case '4:3':
      return `
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
      
          width: 100%;
          height: 100%;

          & > .icon-container {
            margin-bottom: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
      
            & > div {
              display: flex;
              justify-content: center;
              align-items: center;
              width: calc(50% - 5px);
              height: 100%;
      
              & > div {
                height: 90%;
                width: 85%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                
      
                &:first-child {
                  text-align: center;
      
                  .title {
                    width: 100%;
                    text-align: left;
                    
                    font-size: calc(
                      ${isSetting ? 1 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                  }
      
                  .grade10,
                  .grade25 {
                    font-weight: bold;
                    margin: 15% auto;
                    width: 70%;
                    height: 18%;
                    color: ${!grade10 ? '#000' : '#fff'};
                    font-size: calc(
                      ${isSetting ? 1 : 2} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50vw;
                  }
      
                  .grade10 {
                    background: ${getGradeColor(grade10)};
                  }
      
                  .grade25 {
                    background: ${getGradeColor(grade25)};
                  }
      
                  .unit {
                    & > span {
                      font-size: calc(
                      ${isSetting ? 1.2 : 1.7} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                      font-weight: bold;
                    }
      
                      font-size: calc(
                      ${isSetting ? 1 : 1.5} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                      );
                  }
                }
      
                .icon-container {
                  & > svg {
                    margin-top: 5%;
                    width: 50%;
                    height: 100%;
                  }
                }
              }
      
              & > div {
                font-size: calc(
                      ${isSetting ? 2 : 3} *
                        ${getFontSize(
                          width,
                          height,
                          {
                            w: contentWidth,
                            h: contentHeight,
                          },
                          isSetting!,
                        )}
                    );
                font-weight: bold;
              }
            }
      
            & > .divider {
              height: 100%;
              width: 0.05rem;
              background-color: #ccc;
            }
      
            & > .loading {
              text-align: center;
            }
          }
        }
      `;
    default:
      return '';
  }
};

export const FineDustContainer = styled.div<FineDustStyleProps>`
  width: 100%;
  height: 100%;

  ${({ resolution, isSetting, width, height, contentWidth, contentHeight, grade10, grade25 }) =>
    `${getResolutionStyle(resolution, isSetting, width, height, contentWidth, contentHeight, grade10!, grade25!)}`};
`;
