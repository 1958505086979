import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *{
        font-family: 'Pretendard', 'Apple SD Gothic Neo', serif;
        font-size: 1rem;
        font-weight: 400;
        color: #191919;
        box-sizing: border-box;
    }

    li, p{
        font-family: 'Pretendard', 'Apple SD Gothic Neo', serif;
    }

    body {
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
    }

    a {
        font-family: 'Pretendard', 'Apple SD Gothic Neo', serif;
        text-decoration: none;
        color: #191919;
    }

    label, select, button, input[type="color"], input[type="range"], input[type="radio"] {
        cursor: pointer;
    }

    svg {
        -webkit-user-drag: none;
    }
`;
