import * as S from '../styles/DevicePowerStyle';

type Props = {
  btnState: string;
  onClickBtn: () => void;
};

const BoardPower = ({ btnState, onClickBtn }: Props) => {
  return (
    <S.Container className="modalBody">
      <article className="contents">
        <p>장비 전원을 {btnState}하시겠습니까?</p>
        <span>다소 시간이 필요한 기능이므로 추후에 새로고침하여 데이터를 다시 받길 바랍니다.</span>
      </article>
      <article className="bottoms">
        <button onClick={onClickBtn}>전원 {btnState}하기</button>
      </article>
    </S.Container>
  );
};

export default BoardPower;
