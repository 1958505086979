import { CompanyTypes } from '@typedef/Company/company.types';
import { AccountTypes } from '@typedef/Account/account.types';
import { Container } from './styles/ProfileStyle';

type Props = {
  company: CompanyTypes;
  account: AccountTypes;
  userName: string;
  handleChange: (userName: string) => void;
  byteToMegabyte: (bytes: number) => number;
  changeProfile: boolean;
  onClickUpdateProfile: () => void;
  fileFlush: () => void;
};

const Profile = ({
  company,
  account,
  userName,
  handleChange,
  byteToMegabyte,
  changeProfile,
  onClickUpdateProfile,
  fileFlush,
}: Props) => {
  return (
    <Container>
      <main>
        <div className="title-headline">
          <p>유저 정보</p>
        </div>
        <div className="profile-data">
          <p className="title">아이디</p>
          <p className="value">{account.userId}</p>
        </div>
        <div className="profile-data">
          <p className="title">유저이름</p>
          <input
            value={userName}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            type="text"
          />
        </div>
        <div className="profile-data">
          <p className="title">권한</p>
          <p className="value">{account.role.name}</p>
        </div>
        <div className="title-headline">
          <p>미터링 사용량 ( 사용량 / 최대 )</p>
        </div>
        <div className="profile-data">
          <p className="title">장비 생성 갯수</p>
          <p className="value">
            {company.currentDeviceCount} / {company.maxDeviceCount}개
          </p>
        </div>
        <div className="profile-data">
          <p className="title">컨텐츠 파일 사이즈</p>
          <p className="value">
            <progress
              id="fileSize"
              max={byteToMegabyte(company.maxFileSize)}
              value={byteToMegabyte(company.currentFileSize)}
            />

            <div className="visible-data">
              <div className="profile-data">
                <p className="title">총 사용량</p>
                <p className="value">{byteToMegabyte(company.currentFileSize)} MB</p>
              </div>
              <div className="profile-data">
                <p className="title">최대 사용량</p>
                <p className="value">{byteToMegabyte(company.maxFileSize)} MB</p>
              </div>
            </div>
          </p>
          <p className="value">
            {Math.round((byteToMegabyte(company.currentFileSize) / byteToMegabyte(company.maxFileSize)) * 100)}%
          </p>
        </div>
        <div className="title-headline">
          <button className="button" onClick={() => fileFlush()}>
            파일 정리
          </button>
          {changeProfile && (
            <div className={'btns'} style={{ marginTop: '10px' }}>
              <button className="update" onClick={onClickUpdateProfile}>
                수정
              </button>
            </div>
          )}
        </div>
      </main>
    </Container>
  );
};

export default Profile;
