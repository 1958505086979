import { AccountTypes } from '@typedef/Account/account.types';
import { EAccountRole } from '@typedef/Account/account-role.enum';
import { CompanyTypesDefault } from '@typedef/Company/company.types';
import { atom, selector } from 'recoil';

const accountAtom = atom<AccountTypes>({
  key: 'accountAtom',
  default: {
    id: window.localStorage.getItem('id') ?? '',
    userId: window.localStorage.getItem('userId') ?? '',
    userName: window.localStorage.getItem('userName') ?? '',
    role: window.localStorage.getItem('role')
      ? EAccountRole.valueOf(window.localStorage.getItem('role')!)!
      : EAccountRole.NONE,
    company: window.localStorage.getItem('company')
      ? JSON.parse(window.localStorage.getItem('company') ?? '')
      : CompanyTypesDefault,
    accessToken: window.localStorage.getItem('accessToken') ?? '',
    createdAt: window.localStorage.getItem('createdAt') ?? '',
    updatedAt: window.localStorage.getItem('updatedAt') ?? '',
  },
});

export const accountSelector = selector({
  key: 'accountSelector',
  get: ({ get }) => {
    return get(accountAtom);
  },
  set: ({ set }, value) => {
    set(accountAtom, value);
  },
});
