import React from 'react';
import * as S from './styles/VisitStyle';
import { EVisitStep } from '@typedef/Visit/visit-step.enum';

type Props = {
  onHomeButtonClicked: () => void;
  setStep: React.Dispatch<React.SetStateAction<EVisitStep>>;
};

const Visit = ({ onHomeButtonClicked, setStep }: Props) => {
  return (
    <S.ContentContainer>
      <S.TitleText>개인정보 수집동의</S.TitleText>
      <S.BodyText>
        의성군은 ‘가상 플레이그라운드’ 이용현황 확인 및 서비스 개선을 위해 「개인 정보 보호법」 제15조, 제17조, 제22조에
        따라 방문자의 개인정보를 수집·이용 하고자 합니다.
        <br />
        <br />
        수집하는 개인정보는 목적 외의 용도로는 이용되지 않으며, 이 용목적이 변경되는 경우에는 개인정보 보호법에 따라
        별도의 동의를 받는 등 필 요한 조치를 이행할 예정입니다.
        <br />
        <br />
        □ 개인정보 수집‧이용 내역 항목 수집목적 보유기간 성별, 나이(연령대), 주소(타지역 시∙군∙구, 의성군은 읍∙면)
        방문자 정보 확인 5년
        <br />
        <br />※ 위의 개인정보 수집‧이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 시설물의 체험
        활동에 제한을 받을 수 있습니다.
      </S.BodyText>
      <S.ButtonGrid>
        <S.Button height={65} fontSize={30} onClick={onHomeButtonClicked}>
          이전으로
        </S.Button>
        <S.Button height={65} fontSize={30} onClick={() => setStep(EVisitStep.VISITOR)}>
          동의
        </S.Button>
      </S.ButtonGrid>
    </S.ContentContainer>
  );
};

export default Visit;
