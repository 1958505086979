import { ChartData, ChartOptions } from 'chart.js';
import { SurveyCountType } from '@typedef/Monitoring/monitoring.type';
import dayjs from 'dayjs';

const regionBackgroundColor = [
  'rgb(255, 99, 132)',
  'rgb(54, 162, 235)',
  'rgb(255, 205, 86)',
  'rgb(128, 255, 86)',
  'rgb(168, 86, 255)',
  'rgb(255, 128, 128)',
  'rgb(128, 191, 255)',
  'rgb(255, 230, 128)',
  'rgb(170, 255, 128)',
  'rgb(197, 128, 255)',
  'rgb(255, 159, 64)',
  'rgb(75, 192, 192)',
  'rgb(255, 240, 128)',
  'rgb(140, 255, 140)',
  'rgb(191, 128, 255)',
  'rgb(255, 180, 64)',
  'rgb(91, 192, 192)',
  'rgb(255, 255, 128)',
  'rgb(140, 191, 255)',
  'rgb(255, 128, 191)',
];

const genderBackgroundColor = ['rgb(54, 162, 235)', 'rgb(255, 99, 132)'];

export const getDateWeeks = (): { dateWeek: string[]; ISODateWeek: { start: string; end: string }[] } => {
  const today = dayjs();
  const dateWeek: string[] = [];
  const ISODateWeek: { start: string; end: string }[] = [];

  for (let i = 6; i >= 0; i--) {
    const subDate = today.subtract(i, 'day');
    dateWeek.push(subDate.date() + '일');
    ISODateWeek.push({ start: subDate.subtract(1, 'day').toISOString(), end: subDate.toISOString() });
  }

  return { dateWeek: dateWeek, ISODateWeek: ISODateWeek };
};

export const configPieChartData = (data: SurveyCountType[], dataLabel: string): ChartData<'pie'> => {
  return {
    labels: data.map((value) => value.key),
    datasets: [
      {
        label: dataLabel,
        data: data.map((value) => value.value),
        backgroundColor: dataLabel === '성별' ? genderBackgroundColor : regionBackgroundColor,
        hoverOffset: 4,
      },
    ],
  };
};

export const data: ChartData<'pie'> = {
  labels: ['대구', '부산', '서울', '대전', '울산'],
  datasets: [
    {
      label: '인원 수',
      data: [300, 50, 100, 200, 100],
      backgroundColor: regionBackgroundColor,
      hoverOffset: 4,
    },
  ],
};

export const configLineChart = (data1: number[], data2: number[]): ChartData<'line'> => {
  return {
    labels: getDateWeeks().dateWeek,
    datasets: [
      {
        label: '사용 횟수',
        data: data1,
        fill: false,
        borderColor: '#3b2566',
        tension: 0.1,
      },
      {
        label: '사용 시간',
        data: data2,
        fill: false,
        borderColor: '#256662',
        tension: 0.1,
      },
    ],
  };
};

export const lineData: ChartData<'line'> = {
  labels: getDateWeeks().dateWeek,
  datasets: [
    {
      label: '사용 횟수',
      data: [300, 50, 100, 200, 100, 300, 50, 100, 200, 100, 300, 50, 100],
      fill: false,
      borderColor: '#3b2566',
      tension: 0.1,
    },
    {
      label: '사용 시간',
      data: [200, 150, 230, 40, 60, 100, 150, 130, 240, 60, 130, 240, 60],
      fill: false,
      borderColor: '#256662',
      tension: 0.1,
    },
  ],
};

export const genderData: ChartData<'pie'> = {
  labels: ['남성', '여성'],
  datasets: [
    {
      label: '인원 수',
      data: [300, 200],
      backgroundColor: ['rgb(54, 162, 235)', 'rgb(255, 99, 132)'],
      hoverOffset: 4,
    },
  ],
};

export const options: ChartOptions<'pie'> = {
  plugins: {
    legend: {
      position: 'right',
      maxWidth: 78,
      labels: {
        font: {
          size: 9,
        },
      },
    },
  },
};

export const genderOptions: ChartOptions<'pie'> = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const lineOptions: ChartOptions<'line'> = {
  scales: {
    x: {
      type: 'category' as const,
      ticks: {
        autoSkip: true,
        font: {
          size: 10,
        },
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          size: 10,
        },
      },
    },
  },
  plugins: {
    legend: {
      labels: {
        padding: 5,
      },
    },
  },
};
