import * as S from './styles/ListStyle';
import React, {useState} from "react";
import {DropdownStyle} from "@styles/dropdownStyle";
import ReactDropdown from "react-dropdown";
import {ArrowLeft, ArrowRight, Reset, Search} from "@assets/icons";
import {useNavigate} from "react-router-dom";

const List = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<number>(1)

  return (
    <S.Container>
      <S.Header>
        <div className={'title'}>
          <div onClick={() => navigate('/monitoring')}>통합관제모니터링</div>
          <div>OOO 조회</div>
        </div>

        <div className='search'>
          <button className={'download-button'}>
            엑셀로 저장
          </button>
          <DropdownStyle width={'100px'}>
            <ReactDropdown
              className='react-dropdown-main'
              menuClassName='react-dropdown-menu'
              controlClassName='react-dropdown-control'
              options={[...Array(5)].map((_, i) => {
                return {label: `data${i + 1}`, value: `data${i + 1}`}
              })}
              // onChange={(value: Option) => {
              //   setSearchOption(value.value === 'userId' ? 'userId' : 'userName');
              // }}
              // value={searchOption}
            />
          </DropdownStyle>

          <div className='search-input'>
            <label htmlFor='searchInput'>
              <Search title='search'/>
            </label>
            <input
              id='searchInput'
              type='text'
              placeholder='Search...'
              // value={searchInput}
              // onChange={(e) => {
              //   setSearchInput(e.target.value);
              // }}
            />

            <button
              // onClick={() => {
              //   setSearchInput('');
              // }}
            >
              <Reset title='reset'/>
            </button>
          </div>
        </div>
      </S.Header>
      <S.Article>
        <div className={'background'}>
          <div className='tableHeader'>
            <table>
              <colgroup>
                <col width='5%'/>
                <col width='25%'/>
                <col width='15%'/>
                <col width='15%'/>
                <col width='20%'/>
                <col width='20%'/>
              </colgroup>
              <thead>
              <tr>
                <th><input type='checkbox'/></th>
                <th>data1</th>
                <th>data2</th>
                <th>data3</th>
                <th>data4</th>
                <th>data5</th>
              </tr>
              </thead>
            </table>
          </div>
          <div className='tableContent'>
            <table>
              <colgroup>
                <col width='5%'/>
                <col width='25%'/>
                <col width='15%'/>
                <col width='15%'/>
                <col width='20%'/>
                <col width='20%'/>
              </colgroup>
              <tbody>
              {
                [...Array(10)].map((_, i) => (
                  <S.Tr $checked={false}>
                    <td><input type='checkbox'/></td>
                    <td>{'2023-11-01 10:00:00'}</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                  </S.Tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className={'pagination-container'}>
          <ArrowLeft/>
          {[...Array(5)].map((_, i) => (
            <S.PaginationButton key={i} $active={i + 1 === currentPage} onClick={() => setCurrentPage(i + 1)}>
              {i + 1}
            </S.PaginationButton>
          ))}
          <ArrowRight/>
        </div>
      </S.Article>
    </S.Container>
  )
}

export default List