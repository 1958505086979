import styled from 'styled-components';

export const Container = styled.div`
  width: 600px;

  .warning {
    margin-bottom: 15px;
    color: red;
    font-size: 12px;
    text-align: left;
  }

  .split {
    ${({ theme }) => theme.flex.row};
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .subtitle {
      span {
        color: red;
      }
      font-weight: 600;
    }
  }
`;

export const Inputs = styled.section`
  width: 100%;
  padding: 30px;
`;

export const Input = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  p {
    font-weight: bold;
  }

  input {
    width: 60%;
    padding: 10px 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
`;

export const Delete = styled.section`
  width: 100%;
  padding: 30px;

  p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &:last-child {
    margin: 0;
  }
`;
