import axios, { AxiosError, AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { API_URL } from '@libs/remote';

axios.defaults.baseURL = API_URL;
axios.interceptors.request.use((req) => {
  return req;
});

axios.interceptors.response.use((res) => {
  return res;
});

export const apiRoute = {
  // 계정
  company: {
    getList: '/api/v1/company',
    post: '/api/v1/company',
    get: '/api/v1/company/', //{name}
    delete: '/api/v1/company/', //{companyid}
    update: '/api/v1/company/', //{companyid}
    checkCompany: '/api/v2/company/name/', // 계정인증 불필요, 회사 존재 확인
    filePost: '/api/v1/company/file',
    fileFlush: '/api/v1/company/file/flush',
  },
  account: {
    getSelf: '/api/v1/account/self',
    signUp: '/api/v1/account',
    update: '/api/v1/account',
    delete: '/api/v1/account/',
    checkIdGet: '/api/v1/account/check-id/', // id
    signIn: '/api/v1/account/login',
    updateWithRootMaster: '/api/v1/account/',
    deleteWithRoot: '/api/v1/account/', // {userId}
    getWithRoot: '/api/v1/account',
  },
  // 장비들 ( 물리 )
  devices: {
    get: '/api/v1/devices',
    post: '/api/v1/devices',
    detailGet: '/api/v1/devices/', // deviceId
    update: '/api/v1/devices/', // deviceId PUT -> PATCH
    delete: '/api/v1/devices/', // deviceId
    checkIpGet: '/api/v1/devices/validate/ip/', // deviceIp
    checkMacGet: '/api/v1/devices/validate/macAddress/', // deviceMac

    uploadDeviceImageWithoutJWT: '/api/v2/device/file',
    getDeviceImage: '/api/v1/devices/file/',

    getDeviceDetailWithoutJWS: '/api/v2/device/',

    updateDeviceStatus: '/api/v1/devices/{ip}/status',
  },
  // 컨텐츠들
  contents: {
    get: '/api/v1/content',
    post: '/api/v1/content', // return contentId
    detailGet: '/api/v1/content/', // contentId
    update: '/api/v1/content/', // contnetId PUT -> PATCH
    delete: '/api/v1/content/', // contentId

    getContentsWithoutJWS: '/api/v2/content/',
  },
  schedule: {
    post: '/api/v1/schedule',
    get: '/api/v1/schedule', // deviceId
    update: '/api/v1/schedule/', // scheduleId
    delete: '/api/v1/schedule/', // scheduleId
    contentGet: '/api/v1/schedule/content/', // contentId

    getScheduleDeviceWithoutJWT: '/api/v2/schedule',
  },
  widget: {
    fileGetWithoutJWT: '/api/v2/widget/file?', // fileName

    get: '/api/v1/widgets/', // contentId
    post: '/api/v1/widgets/', // contentId
    getWidgetListWithContentIdWithoutJWT: '/api/v2/widgets/', //contentid
  },
  weather: {
    postWeather: '/api/v1/weather', //날씨 추가
    getWeatherLocation: '/api/v1/weather/',

    getGridXYWithoutJWT: '/api/v2/weather',
    // getWeatherDataWithoutJWT: '/api/v2/weather/',
    getWeatherDataWithoutJWT: '/api/v2/weather/{weatherId}/weather',
    getFineDustWithoutJWT: '/api/v2/weather/{weatherId}/air-pollution',
  },
  news: {
    // deleteNews: '/api/v1/news/', // 날씨 삭제
    getNewsDataWithoutJWS: '/api/v2/news', // newsKeyword 조회
  },
  visitor: {
    getVisitor: '/api/v1/visitor',
    createVisitor: '/api/v1/visitor',
    visitorCount: '/api/v1/visitor/count',
    visitorCompanionCount: '/api/v1/visitor/companion/count',
    visitorColumnCount: '/api/v1/visitor/{column}/count',
  },
  monitoring: {
    zepetoPeopleCount: '/api/v1/monitoring/zepeto-logs/people-count/sum',
    zepetoLogs: '/api/v1/monitoring/zepeto-logs',
    getSurveys: '/api/v1/surveys',
    webGLPeopleCount: '/api/v1/surveys/count',
    realCityPeopleCount: '/api/v1/visitor/count',
    realCityCompanionCount: '/api/v1/visitor/companion/count',
    surveyColumnCount: '/api/v1/surveys/{column}/count',
    contentsPlaySummaries: '/api/v1/monitoring/contents-play-summaries',
    // contentsPlaySummariesColumn: '/api/v1/monitoring/contents-play-summaries/programName/{command}',
    contentsPlaySummariesStatistics: '/api/v1/monitoring/contents-play-summaries/statistics',
    loadDeviceList: '/api/v1/devices',
    loadZoneList: '/api/v1/monitoring/zones',
    editDeviceStatus: '/api/v1/devices/{ip}/status',
  },
  excel: {
    // 방문자 (레알)
    visitorExcelDownload: '/api/excel/visitors/{column}/count',
    visitorListExcelDownload: '/api/excel/visitors',
    // 설문조사 (메타)
    surveyExcelDownload: '/api/excel/surveys/{column}/count',
    surveyListExcelDownload: '/api/excel/surveys',
    // 컨텐츠 재생
    contentsPlaySummariesStatisticsExcelDownload: '/api/excel/monitoring/contents-play-summaries/statistics',
    contentsPlayListExcelDownload: '/api/excel/monitoring/contents-play-summaries',
  },
  localFile: {
    download: '/api/v2/local-file',
    streaming: '/api/v2/local-file/streaming',
    delete: '/api/v2/local-file',
  },
  stock: {
    //주식 지수
    index: '/api/v2/stock-index',
  },
};

export const FILE_STREAMING_URL = `${API_URL}${apiRoute.localFile.streaming}?filename=`;
export const FILE_DOWNLOAD_URL = `${API_URL}${apiRoute.localFile.download}?filename=`;

type BasicApiResponse<T> = {
  data: T;
  config: {
    success: boolean;
    status: number;
    response: boolean;
    message: string;
    networkError: boolean;
  };
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
};

const parseAxiosResponse = <T>(res: AxiosResponse) => {
  return {
    data: res.data as T,
    config: {
      success: res.data?.success,
      message: res.data?.message,
      response: res.data?.response,
      status: res.status,
      networkError: false,
    },
    headers: res.headers,
  } as BasicApiResponse<T>;
};

const handleAxiosError = <T>(
  error: AxiosError<{
    message: string | undefined;
    response: boolean | undefined;
  }>,
) => {
  const message: string | undefined = error?.response?.data?.message ?? '';
  const status: number | undefined = error?.response?.status;
  const response: boolean | undefined = error?.response?.data?.response ?? false;

  if (status === 401) {
    if (message?.includes('유효한 토큰이 아닙니다.')) {
      alert('로그인한지 경과가 너무 오래되어 로그아웃 처리하였습니다. \n다시 로그인 해 주세요.');
    }
    if (message?.includes('회원 정보가 없습니다. 로그인후 사용해 주세요')) {
      alert('회원 정보가 없습니다. 로그인후 사용해 주세요');
    }
    localStorage.clear();
    window.location.href = '/';
  }

  return {
    data: {} as T,
    config: { status, message, response, networkError: !error.response },
  } as BasicApiResponse<T>;
};

export function requestSecureGet<T>(url: string, header: object, token: string): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        accept: 'application/json',
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestGetFile<T>(url: string, header: object, token: string): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      responseType: 'blob',
      headers: {
        accept: 'application/json',
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestGet<T>(url: string, header: object): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, JSON.stringify(body), {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestPost<T>(url: string, header: object, body: object): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, JSON.stringify(body), {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestSecurePostFile<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestPostFile<T>(url: string, header: object, body: object): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestPut<T>(url: string, header: object, body: object, token: string): Promise<BasicApiResponse<T>> {
  return axios
    .put(url, JSON.stringify(body), {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, JSON.stringify(body), {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      data: JSON.stringify(body),
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(parseAxiosResponse<T>)
    .catch(handleAxiosError<T>);
}
