export type EFileStreamingTypeProps = 'DOWNLOAD' | 'STREAMING';

export class EFileStreamingType {
  static readonly DOWNLOAD: EFileStreamingType = new EFileStreamingType('DOWNLOAD', '다운로드');
  static readonly STREAMING: EFileStreamingType = new EFileStreamingType('STREAMING', '스트리밍');

  private constructor(
    public readonly value: EFileStreamingTypeProps,
    public readonly name: string,
  ) {}

  static readonly values = [EFileStreamingType.DOWNLOAD, EFileStreamingType.STREAMING];

  static valueOf(value: EFileStreamingTypeProps): EFileStreamingType {
    return EFileStreamingType.values.find((v) => v.value === value)!;
  }

  equals(value: EFileStreamingType | EFileStreamingTypeProps) {
    if (typeof value === 'string') {
      return this.value === value;
    }
    return this.value === value.value;
  }
}
