import * as S from '../styles/WidgetManageStyle';

type Props = {
  onClickSubmit: () => void;
  type: 'add' | 'delete';
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const WidgetManage = ({ onClickSubmit, type, handleInput }: Props) => {
  if (type === 'add') {
    return (
      <S.Container className="modalBody">
        <S.Inputs>
          <S.Input>
            <p>이름</p>
            <input placeholder="이름" onChange={handleInput} name="name" />
          </S.Input>
          <S.Input>
            <p>가로</p>
            <input placeholder="1920" onChange={handleInput} name="w" />
          </S.Input>
          <S.Input>
            <p>세로</p>
            <input placeholder="1080" onChange={handleInput} name="h" />
          </S.Input>
        </S.Inputs>
        <article className="bottoms">
          <button onClick={onClickSubmit}>생성하기</button>
        </article>
      </S.Container>
    );
  }
  if (type === 'delete') {
    return (
      <S.Container className="modalBody">
        <S.Delete>
          <p>정말로 컨텐츠를 삭제하시겠습니까? </p>
          <p>삭제 후 복구하실수 없습니다.</p>
        </S.Delete>
        <article className="bottoms">
          <button onClick={onClickSubmit} className="danger">
            삭제하기
          </button>
        </article>
      </S.Container>
    );
  }

  return <></>;
};

export default WidgetManage;
