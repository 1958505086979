import { useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import { useEffect, useState } from 'react';
import {
  DateSurveyCountDefaultValue,
  DateSurveyCountType,
  LoaderExtendType,
  PeopleCount,
  RealCityPeopleCountDefaultValue,
  SurveyCountType,
} from '@typedef/Monitoring/monitoring.type';
import { apiRoute, requestGetFile, requestSecureGet } from '@libs/api';
import RealCity from '@components/Monitoring/RealCity';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { VisitorResponseType, VisitorType } from '@typedef/Visit/visitor.types';
import { EAgeGroup } from '@typedef/Visit/age-group.enum';
import { EGender } from '@typedef/Visit/gender.enum';
import { EVisitType } from '@typedef/Visit/visit-type.enum';
import { PeriodDefaultValue, PeriodType } from '@components/Common/CommonDate/CommonDate';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';

const RealCityContainer = () => {
  const profileData = useRecoilValue(accountSelector);

  const [period, setPeriod] = useState<PeriodType>(PeriodDefaultValue);

  const [visitorData, setVisitorData] = useState<VisitorType[]>([]);
  const [visitorCountData, setVisitorCountData] = useState<DateSurveyCountType>(DateSurveyCountDefaultValue);
  const [peopleCount, setPeopleCount] = useState<PeopleCount>(RealCityPeopleCountDefaultValue);
  const [companionCount, setCompanionCount] = useState<PeopleCount>(RealCityPeopleCountDefaultValue);

  const loadVisitorCountByColumn = async (column: string) => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType[]>(
      `${apiRoute.visitor.visitorColumnCount.replace('{column}', column)}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      const updateData = (targetData: DateSurveyCountType) => {
        let updateData: LoaderExtendType<SurveyCountType[]> = { data: [], loading: false };
        switch (column) {
          case 'ageGroup':
            updateData.data = data?.map((v) => {
              return { ...v, name: EAgeGroup.valueOf(v.key).name };
            });
            break;
          case 'gender':
            updateData.data = data?.map((v) => {
              return { ...v, name: EGender.valueOf(v.key).name };
            });
            break;
          case 'visitType':
            updateData.data = data?.map((v) => {
              if (v.key.length === 0) return { ...v, name: EVisitType.EMPTY.name };
              else return { ...v, name: EVisitType.valueOf(v.key).name };
            });
            break;
          case 'weather':
            updateData.data = data?.map((v) => {
              return { ...v, name: v.key };
            });
            break;
          default:
            break;
        }

        return {
          ...targetData,
          [column]: updateData,
        };
      };

      setVisitorCountData((prevState) => updateData(prevState));
    }
  };

  const loadVisitorCount = async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType>(
      `${apiRoute.visitor.visitorCount}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setPeopleCount((prevState) => ({
        ...prevState,
        data: data,
        loading: false,
      }));
    }
  };

  const loadVisitorCompanionCount = async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data } = await requestSecureGet<SurveyCountType>(
      `${apiRoute.visitor.visitorCompanionCount}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setCompanionCount((prevState) => ({
        ...prevState,
        data: data,
        loading: false,
      }));
    }
  };

  const loadVisitors = async () => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const {
      config,
      data: { content },
    } = await requestSecureGet<PaginationTypes<VisitorResponseType>>(
      `${apiRoute.visitor.getVisitor}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      setVisitorData(
        content.map((v) => {
          return {
            ...v,
            ageGroup: EAgeGroup.valueOf(v.ageGroup),
            gender: EGender.valueOf(v.gender),
            visitType: EVisitType.valueOf(v.visitType),
          };
        }),
      );
    }
  };

  const visitorExcelDownload = async (column: string) => {
    const queryParams: Record<string, any> = {
      createdAt: `${period.startedAt}~${period.endedAt}`,
    };
    const { config, data, headers } = await requestGetFile<BlobPart>(
      `${apiRoute.excel.visitorExcelDownload.replace('{column}', column)}${parseQueryParamsToString(queryParams)}`,
      {},
      profileData.accessToken,
    );

    if (config.status === 200) {
      const url = window.URL.createObjectURL(new Blob([data], { type: String(headers['Content-Type']) }));
      const link = document.createElement('a');
      link.href = url;
      let koColumn;
      if (column === 'ageGroup') {
        koColumn = '나이대';
      } else if (column === 'weather') {
        koColumn = '지역';
      } else if (column === 'visitType') {
        koColumn = '방문 목적';
      } else if (column === 'gender') {
        koColumn = '성별';
      }
      link.setAttribute('download', `${period?.startedAt}${koColumn} 데이터.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  };

  const onSummit = async () => {
    setVisitorData([]);

    await Promise.all([
      loadVisitors(),
      loadVisitorCount(),
      loadVisitorCompanionCount(),
      Object.keys(DateSurveyCountDefaultValue).map(async (column) => loadVisitorCountByColumn(column)),
    ]);
  };

  useEffect(() => {
    onSummit();
  }, []);

  return (
    <RealCity
      visitorData={visitorData}
      visitorCountData={visitorCountData}
      peopleCount={peopleCount}
      companionCount={companionCount}
      visitorExcelDownload={visitorExcelDownload}
      period={period}
      setPeriod={setPeriod}
      onSummit={onSummit}
    />
  );
};

export default RealCityContainer;
