import Visit from '../Visit';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountSelector } from '@stories/account';
import React, { useEffect, useState } from 'react';
import { defaultVisitorRequestType, VisitorRequestType } from '@typedef/Visit/visitor.types';
import { EVisitStep } from '@typedef/Visit/visit-step.enum';
import * as S from '../styles/VisitStyle';
import PrivacyPolicy from '@components/Visit/PrivacyPolicy';
import VisitHome from '@components/Visit/VisitHome';
import { GetWeatherInputsTypes, GetWeathersInputsDefault, WeatherTypes } from '@typedef/Widget/weather.types';
import { apiRoute, requestGet, requestSecurePost } from '@libs/api';
import { keyBy } from 'lodash';
import Complate from '@components/Visit/Complate';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { weatherListSelector } from '@stories/weatherList';

const VisitContainer = () => {
  const token = useRecoilValue(accountSelector).accessToken;
  const [visitor, setVisitor] = useState<VisitorRequestType>({ ...defaultVisitorRequestType });

  const [weatherList, setWeatherList] = useRecoilState(weatherListSelector);
  const [weatherDict, setWeatherDict] = useState<Record<string, WeatherTypes>>({});
  const [step, setStep] = useState<EVisitStep>(EVisitStep.HOME);

  const onVisitorChanged = <K extends keyof VisitorRequestType>(key: K, value: VisitorRequestType[K]) => {
    const newVisitor = { ...visitor, [key]: value };

    if (key === 'isLivingInUiseong') {
      if (value) {
        const uiseongWeather = weatherList.find(
          (weather) => weather.location1 === '경상북도' && weather.location2 === '의성군' && !weather.location3,
        );
        newVisitor.weatherId = uiseongWeather!.id!;
      } else {
        newVisitor.weatherId = '';
      }
    }

    setVisitor(newVisitor);
  };
  const onHomeButtonClicked = () => {
    setStep(EVisitStep.HOME);
    setVisitor({ ...defaultVisitorRequestType });
  };

  const onPrivacyPolicyButtonClicked = () => {
    setStep(EVisitStep.PRIVACY_POLICY);
  };

  const onCompleteButtonClicked = () => {
    if (!visitor.ageGroup) return alert('나이대를 선택해주세요.');
    if (!visitor.visitType) return alert('방문유형을 선택해주세요.');
    // if (!visitor.gender) ret

    if (visitor.isLivingInUiseong) {
      if (!weatherDict[visitor.weatherId].location3) {
        return alert('거주지역 정보를 입력해주세요.');
      }
    }

    postVisitor(visitor);
  };

  const postVisitor = async (inputs: VisitorRequestType) => {
    const {
      config: { status },
    } = await requestSecurePost(apiRoute.visitor.createVisitor, {}, inputs, token);
    if (status < 200 || status >= 300) {
      alert('방문자 등록에 실패했습니다.');
      return;
    }
    setStep(EVisitStep.COMPLETE);
  };

  const loadWeathers = async () => {
    const getWeathersInputs: GetWeatherInputsTypes = {
      ...GetWeathersInputsDefault,
      paged: false,
    };

    if (weatherList.length === 0) {
      const {
        data: { content },
        config,
      } = await requestGet<PaginationTypes<WeatherTypes>>(
        `${apiRoute.weather.getGridXYWithoutJWT}${parseQueryParamsToString(getWeathersInputs)}`,
        {},
      );

      if (config.status !== 200) return;

      setWeatherList(content);
      setWeatherDict(keyBy(content, 'id'));
    }
  };

  useEffect(() => {
    loadWeathers();
  }, []);

  return (
    <S.Container>
      <header>
        <div className="left">
          <p className="title">방문 등록</p>
          <p className="title">{step.name}</p>
        </div>
      </header>
      {step.equals(EVisitStep.HOME) && <VisitHome onPrivacyPolicyButtonClicked={onPrivacyPolicyButtonClicked} />}
      {step.equals(EVisitStep.PRIVACY_POLICY) && (
        <PrivacyPolicy onHomeButtonClicked={onHomeButtonClicked} setStep={setStep} />
      )}
      {step.equals(EVisitStep.VISITOR) && (
        <Visit
          visitor={visitor}
          onVisitorChanged={onVisitorChanged}
          weatherList={weatherList}
          weatherDict={weatherDict}
          onHomeButtonClicked={onHomeButtonClicked}
          onCompleteButtonClicked={onCompleteButtonClicked}
        />
      )}
      {step.equals(EVisitStep.COMPLETE) && <Complate onHomeButtonClicked={onHomeButtonClicked} />}
    </S.Container>
  );
};

export default VisitContainer;
