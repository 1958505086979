export enum EFluctuationDivisionType {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
  EQUAL = 'EQUAL',
}

export type StockIndexType = {
  indexInfo: string;
  fluctuationFigures: string;
  fluctuationRate: string;
  fluctuationDivision: EFluctuationDivisionType;
  highestPrice: string;
  lowestPrice: string;
};

export type StockIndexStateType = {
  kosdaq: StockIndexType;
  kospi: StockIndexType;
};

export const StockIndexDefaultValue: StockIndexType = {
  indexInfo: '',
  fluctuationRate: '',
  fluctuationFigures: '',
  fluctuationDivision: EFluctuationDivisionType.EQUAL,
  highestPrice: '',
  lowestPrice: '',
};
