import styled from 'styled-components';
import MapImage from '../../../assets/images/map.png';

const space = '28px';

export const Container = styled.main`
  width: 100%;
  height: 100%;
`;

export const Header = styled.header`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: bold;

  .date-picker-container {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-right: 15px;

    button {
      height: 40px;
      padding: 0 ${space};
      border: 1px solid rgb(37, 102, 98);
      background-color: rgb(37, 102, 98);
      color: #fff;
      border-radius: 5px;
      font-weight: 600;
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      p {
        color: #f1f1f1;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }
`;

export const Article = styled.article<{ grid?: number; $flexDirection?: string }>`
  margin-top: 30px;
  margin-bottom: 30px;

  & > h3 {
    font-size: 20px;
    font-weight: bold;
  }

  .loading-message {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #bbb !important;
  }

  .detail-button {
    margin-top: 10px;
    width: 65px;
    height: 24px;
    font-size: 11px;
    background: #2b3e63;
    color: #fff;
    border: none;
    border-radius: 5px;
  }

  padding: ${space};

  background-color: #fff;
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;

  .header {
    margin-bottom: 15px;

    display: flex;
    justify-content: space-between;

    .dropdown {
      height: 35px;

      padding-left: 5px;

      border: 1px solid #cccccc;
      border-radius: 7px;

      ${({ theme }) => theme.flex.row}
      align-items: center;
      justify-content: space-between;

      position: relative;

      .dropdown-arrow {
        position: relative;
        right: 0;
        top: 0;
        border-color: #999 transparent transparent;
        border-width: 5px 5px 0;
      }
    }

    & > button {
      width: 110px;
      height: 35px;

      border: 0;
      border-radius: 7px;

      background-color: #4399ff;
      color: #fff;
    }
  }

  .contentPlaybackBackground {
    min-height: 665px;

    padding-bottom: 15px;
    border-radius: 7px;

    display: grid;
    grid-template-columns: repeat(${({ grid }) => grid}, 1fr);
    justify-items: center;
    gap: 15px;

    & > svg {
      width: 16px;
      right: 16px;
      stroke: ${({ theme }) => theme.colors.gray200};
      transform: rotate(270deg);
      stroke: #bdbdbd;
      position: absolute;
    }

    & > .real-city-container {
      height: 600px !important;

      display: flex !important;
      flex-direction: row !important;
      gap: 10px;

      & > div:first-child {
        width: 500px;

        ${({ theme }) => theme.flex.col}
        .first {
          height: 230px;
        }

        .last {
          height: 100%;
        }
      }

      & > div:last-child {
        width: 100%;
      }
    }

    & > .line-chart {
      min-height: 450px !important;
    }

    & > .container {
      width: 100%;
      height: ${({ grid }) => grid && grid < 3 && 665}px;

      min-width: 330px;
      min-height: 665px;

      padding: 10px;

      border-radius: 7px;

      ${({ theme }) => theme.flex.col};
      justify-content: space-between;

      background: #f9f9f9;
      box-shadow: rgb(99 99 99 / 20%) 0 2px 4px 0;

      & > .graph-container {
        height: 90%;

        margin-top: 10px;
        padding: ${({ grid }) => grid && grid < 2 && 45}px;
        border-radius: 7px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        background: #fff;
        box-shadow: rgb(125 125 125 / 10%) 0 1px 2px 0;

        & > canvas {
          padding: 0 10px;

          &:last-child {
            margin-top: 15px;
          }
        }
      }
    }
  }

  .monitoring {
    box-shadow: none !important;
  }

  .background {
    min-height: 665px;
    ${({ theme }) => theme.flex.col};
    padding: ${space};
    background-color: #fff;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
    border-radius: 7px;
    height: 720px;
    gap: 20px;
    display: flex;
    ${({ theme }) => theme.flex.col};
    align-items: center;
    justify-content: space-between;
    flex-direction: ${({ $flexDirection }) => $flexDirection ?? 'row'};
    width: 100%;

    & > .real-city-container {
      height: 600px !important;
    }

    & > .container {
      width: 31.5%;
      background: #f9f9f9;
      height: 640px;
      border-radius: 7px;
      box-shadow: rgb(99 99 99 / 20%) 0 2px 4px 0;
      padding: 15px;
      min-width: 330px;

      & > .graph-container {
        background: #fff;
        height: 230px;
        border-radius: 7px;
        box-shadow: rgb(125 125 125 / 10%) 0 1px 2px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & > div {
          color: #256662;
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 17px;
          width: 90%;
        }

        & > canvas {
          width: 90% !important;
        }
      }

      & .visited-sub-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 25px;
        font-size: 16px;
        font-weight: 500;

        & > button {
          height: 100%;
          width: 65px;
          font-size: 12px;
          border: none;
          background-color: #4399ff;
          color: #fff;
          border-radius: 5px;
        }
      }

      & .usage-sub-header {
        font-size: 20px;
        font-weight: bold;
        margin: 17px 0;
        height: 25px;
      }
    }
  }
`;

export const DateContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 13px;

  & > h3 {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const StatisticContainer = styled.div`
  width: 1144px;
  height: 136px;
  display: flex;
  justify-content: space-between;

  & > div {
    width: 560px;
    height: 100%;
    background: #f9f9f9;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      &:first-child {
        text-align: center;

        & > div {
          font-size: 23px;

          &:nth-child(1) {
            font-weight: bold;
          }

          &:nth-child(2) {
            margin-top: 6px;
          }
        }
      }

      &:last-child {
        margin-left: 30px;
        width: 380px;
        background: #fff;
        height: 100px;
        display: flex;
        align-items: center;
        border-radius: 7px;
      }
    }

    & > .meta-city {
      &::before {
        content: '';
        left: 50%;
        position: relative;
        top: 0;
        height: 50%;
        width: 1px;
        background: #eee;
      }

      & > div {
        width: 50%;
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        & > div:last-child {
          margin-top: 7px;
        }

        * {
          font-size: 23px;
          font-weight: bold;
        }

        span {
          color: #256662;
        }
      }
    }

    & > .real-city {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & > div:last-child {
        margin-top: 7px;
      }

      * {
        font-size: 23px;
        font-weight: bold;
      }

      span {
        color: #256662;
      }
    }
  }
`;

export const MapLoading = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #000;
`;

export const MapContainer = styled.div`
  width: 1144px;
  height: 500px;
  background: url(${MapImage});
  background-size: cover;
  display: flex;

  & > .contents-container {
    text-align: center;
    height: auto;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 23px;
    font-weight: bold;
    width: 134px;
  }
`;

export const StyledStatusAndPlaybackHistory = styled.div`
  width: 1144px;
  height: 500px;
  display: flex;
  justify-content: space-between;

  & > div {
    overflow-y: auto;
    width: 560px;
    height: 100%;
    background: #f9f9f9;
    border-radius: 7px;
    padding: 20px;
    box-sizing: border-box;

    & > .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-weight: 700;

      & > button {
        margin-top: 0;
      }
    }

    .status-loading {
      width: 100%;
      height: calc(100% - 37px);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #bbb;
      font-size: 18px;
    }

    & > .device {
      margin-top: 20px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;

      & > div {
        width: 240px;

        & > h3 {
          font-size: 16px;
          font-weight: 600;
        }

        & > .power-container {
          display: flex;
          justify-content: space-between;
          background: #fff;
          margin-top: 10px;
          padding: 8px 12px;
          align-items: center;
          border-radius: 5px;

          & > .pending-text {
            width: 100%;
            color: #256662;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
          }

          & > .text-container {
            font-size: 16px;
            font-weight: 600;

            & > span {
              margin-left: 5px;
              font-size: 16px;
              font-weight: 600;
              color: #256662;
            }
          }

          & > .button-container {
            & > button {
              width: 45px;
              height: 20px;
              font-size: 8px;
              border: none;
              border-radius: 5px;
              color: #fff;
            }

            & > .button-on {
              background: #4399ff;
            }

            & > .button-off {
              margin-left: 5px;
              background: #6c6c6c;
            }
          }
        }
      }
    }

    & > .play-history-container {
      margin-top: 20px;
      width: 100%;
      height: calc(100% - 40px);
      background-color: #fff;
      border-radius: 7px;
      padding: 20px;
      box-sizing: border-box;

      & > table {
        width: 100%;

        th,
        td {
          height: 45px;
          width: 50%;
          vertical-align: middle;
          text-align: center;
        }

        th {
          height: auto;
          padding-bottom: 10px;
          font-size: 18px;
          font-weight: 700;
        }

        td {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > h3 {
    font-size: 22px;
    font-weight: bold;
  }

  & > button {
    width: 90px;
    height: 30px;
    font-size: 12px;
    color: #fff;
    background-color: #256662;
    border: none;
    border-radius: 5px;
  }

  & > .detail-button {
    margin-right: 10px;
    margin-left: auto;
    background-color: #4399ff;
    color: #fff;
  }
`;

export const DetailContainer = styled.div<{ $height: number; $flex?: boolean; $isUsage?: boolean }>`
  padding: 15px;
  width: 100%;
  height: ${({ $height }) => `${$height}px`};
  background: #fff;
  margin: 20px 0;
  border-radius: 7px;
  box-shadow: rgb(125 125 125 / 10%) 0 1px 2px 0;
  ${({ $flex }) =>
    $flex &&
    `
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `};

  ${({ $isUsage }) =>
    $isUsage &&
    `
  & > div {
    font-size: 17px;
    font-weight: bold;
    
    & span {
      color: #256662;
      padding-right: 3px;
    }
  }
  `};

  & > .visited-container-single {
    & > div {
      margin-top: -55px;
      width: 100% !important;
    }
  }

  & > .visited-container {
    width: 100%;
    margin-top: 10px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: calc(50% - 0.5px);
      text-align: center;

      & > div {
        font-size: 22px;
        font-weight: bold;

        &:last-child {
          margin-top: 5px;

          & > span {
            color: #256662;
          }
        }
      }
    }
  }

  & > .chart-container {
    width: 100%;
    height: 210px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #eee;
    justify-content: flex-start;

    & > div {
      padding-top: 20px;
      height: 100%;
      width: 45px;
      color: #256662;
      font-weight: bold;
      font-size: 17px;
    }
  }

  & > .bottom-container {
    margin-top: 5px;
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: calc(50% - 0.5px);

      & > h4 {
        width: 100%;
        margin-left: 5px;
        color: #256662;
        font-weight: bold;
        font-size: 17px;
      }

      & > div {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & > .facility-info {
    width: auto;
    height: auto;
    font-weight: bold;

    & > div {
      color: #256662;

      &:first-child {
        font-size: 24px;
      }

      &:last-child {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
`;

export const Divider = styled.span<{ $height: string }>`
  width: 1px;
  height: ${({ $height }) => $height};
  background-color: #eee;
`;

export const Gender = styled.span<{ $gender: 'M' | 'W' }>`
  color: ${({ $gender }) => ($gender === 'M' ? '#3B76E9' : '#E93BC3')} !important;
`;

export const ControlButtonContainer = styled.div<{ $power: boolean }>`
  width: 145px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    transition:
      background 0.25s,
      color 0.25s;
    width: 68px;
    height: 35px;
    font-size: 15px;
    border-radius: 50px;
    border: none;

    ${({ $power }) =>
      $power
        ? `
      &:first-child {
        background: #ECF9F0;
        color: #3DC061;
      }
      
      &:last-child {
       background: #EBEBEB; 
       color: #C4C4C4;
      }
      `
        : `
      &:first-child {
        background: #EBEBEB; 
        color: #C4C4C4;
      }
      
      &:last-child {
       background: #F9ECEC;
       color: #C03D3D;
      }
    `}
  }
`;

export const InfoMessage = styled.span<{ $isWeather?: boolean }>`
  width: 100%;
  padding-right: ${({ $isWeather }) => ($isWeather ? '45px' : '0')};
  font-size: ${({ $isWeather }) => ($isWeather ? '16px' : '11px')};
  text-align: center;
  color: #bbb;
`;
