import { apiRoute, requestSecureGet } from '@libs/api';
import { accountSelector } from '@stories/account';

import { CompanyResponseTypes, CompanyTypes } from '@typedef/Company/company.types';

import { useCallback, useEffect, useState } from 'react';
import Company from '../Company';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalSelector } from '@stories/modal';
import CompanyManageContainer from './CompanyManageContainer';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { ECompanyType } from '@typedef/Company/company-type.enum';
import useSearch from '@hooks/useSearch';
import { defaultPageTypes, PageTypes } from '@typedef/libs/pagination.types';

const CompanyContainer = () => {
  const [companyList, setCompanyList] = useState<CompanyTypes[]>([]);
  const [reload, setReload] = useState<number>(0);

  const [checkedList, setCheckedList] = useState<CompanyTypes[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageInfo, setPageInfo] = useState<PageTypes>(defaultPageTypes);

  const profileData = useRecoilValue(accountSelector);
  const setModal = useSetRecoilState(modalSelector);

  const { option, search } = useSearch();

  // 모달 오픈
  const onClickModalOpen = useCallback(
    (id: string, selectAccount?: CompanyTypes) => {
      const modal: {
        [key: string]: { id: string; header: string; body: JSX.Element };
      } = {
        update: {
          id: 'update',
          header: '회사 수정하기',
          body: (
            <CompanyManageContainer
              id="update"
              type="수정"
              checkedList={selectAccount ? [selectAccount] : null}
              reload={() => {
                setReload((prev) => prev + 1);
              }}
            />
          ),
        },
        delete: {
          id: 'delete',
          header: '회사 삭제하기',
          body: (
            <CompanyManageContainer
              id="delete"
              type="삭제"
              checkedList={checkedList}
              reload={() => {
                setReload((prev) => prev + 1);
              }}
            />
          ),
        },
        add: {
          id: 'add',
          header: '회사 추가하기',
          body: (
            <CompanyManageContainer
              id="add"
              type="추가"
              checkedList={selectAccount ? [selectAccount] : null}
              reload={() => {
                setReload((prev) => prev + 1);
              }}
            />
          ),
        },
      };

      if (id === 'delete' && checkedList.length === 0) {
        return alert('계정을 먼저 선택해 주세요');
      }

      setModal({
        header: modal[id].header,
        close: true,
        body: modal[id].body,
      });
    },
    [setModal, checkedList],
  );

  // 계정 조회
  const loadCompanys = useCallback(
    async (option?: string, search?: string, page?: number) => {
      const queryParams: Record<string, any> = { page: page, size: 8 };
      if (option) {
        queryParams[option] = search;
      }

      const { config, data } = await requestSecureGet<PaginationTypes<CompanyResponseTypes>>(
        `${apiRoute.company.getList}${parseQueryParamsToString(queryParams)}`,
        {},
        profileData.accessToken,
      );

      if (config.status === 200) {
        setCompanyList(
          data.content.map((v) => {
            return { ...v, type: ECompanyType.valueOf(v.type)! };
          }),
        );
        setPageInfo(data);
      }
    },
    [profileData.accessToken],
  );

  const onSearchSummit = () => {
    setCurrentPage(1);
  };
  const onPageChange = (page: number) => {
    setCompanyList([]);
  };
  useEffect(() => {
    loadCompanys(option!, search!, currentPage);
    setCheckedList([]);
  }, [option, search, currentPage, reload]);

  return (
    <Company
      onClickModalOpen={onClickModalOpen}
      companyList={companyList}
      checkedList={checkedList}
      setCheckedList={setCheckedList}
      pageInfo={pageInfo}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      onPageChange={onPageChange}
      onSearchSummit={onSearchSummit}
    />
  );
};

export default CompanyContainer;
