import styled from 'styled-components';

const space = '15px';
const height = '40px';

export const SearchStyle = styled.header`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 0 ${space};
  height: ${height};

  .weather-search-form {
    ${({ theme }) => theme.flex.col};

    .weather-search-container {
      width: 255px;
      height: auto;
      max-height: 350px;

      border-radius: 5px;

      padding: 10px;

      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

      position: absolute;
      top: 50px;
      right: 0px;

      overflow-y: auto;

      &::-webkit-scrollbar {
        // 스크롤바 전체
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        // 스크롤 막대
        background: rgba(94, 94, 94, 0.3);
        border-radius: 6px;
      }

      &::-webkit-scrollbar-track {
        // 스크롤 막대 외부
        background: rgba(187, 187, 187, 0.4);
        border-radius: 6px;
      }

      .location {
        height: 50px;

        padding: 0 5px;

        display: flex;
        align-items: center;

        background-color: #fff;

        font-size: 15px;

        & > span {
          color: #256662;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .location:hover {
        border-radius: 5px;

        background-color: #efefef;

        cursor: pointer;
      }
    }
  }

  form {
    width: 265px;
    position: relative;
  }

  .search-input {
    ${({ theme }) => theme.flex.row};
    align-items: center;
    background-color: #fff;
    margin: 0 15px;
    border-radius: 5px;

    label {
      cursor: text;
      margin-left: 15px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    input[type='text'] {
      height: ${height};
      padding: 0 ${space} 0 5px;
      font-size: 15px;
      border: none;
      border-radius: 5px 0 0 5px;

      &::placeholder {
        font-size: 16px;
        color: #d3d3d3;
        font-family: transparent, serif;
      }

      &:focus {
        outline: none;
      }
    }

    input[type='date'] {
      width: 100%;
      height: ${height};
      padding: 0 ${space} 0 5px;
      font-size: 15px;
      border: none;
      border-radius: 5px;

      &::placeholder {
        font-size: 16px;
        color: #d3d3d3;
        font-family: transparent, serif;
      }

      &:focus {
        outline: none;
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 10px;

    svg {
      display: flex;
      align-items: center;
      width: 30px;
      height: 30px;
    }
  }

  .search-button {
    width: 50px;
    height: 40px;
    background-color: #256662;
    color: #fff;
    border-radius: 5px;
  }

  .reset-button {
    width: 50px;
    height: 40px;
    background-color: #084d90;
    color: #fff;
    border-radius: 5px;
  }
`;
export const SearchDropdownStyle = styled.div<{ isSelected: boolean }>`
  width: 250px;
  height: 100%;

  margin-left: 15px;

  .search-dropdown-main {
    width: 100%;
    font-weight: normal;
    height: 100%;
  }

  .search-dropdown-control {
    display: flex;
    width: 100%;
    padding: 7px;
    background-color: #fff;
    border-radius: 5px;
    height: 100%;

    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border: #ffffffff;
  }

  .search-dropdown-menu {
    width: 100%;
    border-radius: 5px;
    font-weight: normal;
  }
  .search-dropdoen-placeholder {
    color: ${({ isSelected }) => (isSelected ? '#000000' : '#d3d3d3')};
  }

  .Dropdown-arrow {
    border-width: 0 0 0;
  }
`;
