import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PreviewContent from '../PreviewContent';
import { useMatch } from 'react-router-dom';
import { ContentTypes, defaultContentTypes } from '@typedef/Contents/contents.types';
import { apiRoute, requestSecureGet } from '@libs/api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountSelector } from '@stories/account';
import { createWidget, RawWidgetTypes, WidgetTypes } from '@typedef/Widget/widget.types';
import { modalSelector } from '@stories/modal';
import { LoadingModalContainer } from '@components/Widgets/styles/WidgetStyle';
import Spinner from '@components/Common/Spinner/Spinner';

const PreviewContentContainer = () => {
  const match = useMatch('/preview-content/:contentId');
  const contentId = match?.params.contentId;

  const token = useRecoilValue(accountSelector).accessToken;
  const monitorRef = useRef<HTMLDivElement>(null);

  const [widgetList, setWidgetList] = useState<WidgetTypes[]>([]);
  // 현재 레이아웃 width, height 값
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const setModal = useSetRecoilState(modalSelector);
  const [content, setContent] = useState<ContentTypes>(defaultContentTypes);

  // 열 하나당 width값 ( 총 갯수 : 16 )
  const rowWidth = useMemo(() => {
    return width / (content.w / 20);
  }, [width, content]);

  // 콜 하나당 height값 ( 총 갯수 : 8 )
  const colHeight = useMemo(() => {
    return height / (content.h / 20);
  }, [height, content]);

  const getContentById = useCallback(async () => {
    try {
      const { data: responseData, config } = await requestSecureGet<ContentTypes>(
        apiRoute.contents.getContentsWithoutJWS + contentId,
        {},
        token,
      );

      if (config.status === 200) {
        setModal({
          header: '컨텐츠 로딩 중...',
          close: false,
          body: (
            <LoadingModalContainer>
              <Spinner width={100} height={100} size={50} />
              <div className={'loading-text'}>컨텐츠를 로딩 중입니다...</div>
            </LoadingModalContainer>
          ),
        });

        setContent(responseData);

        const { data: responseWidgetList, config } = await requestSecureGet<RawWidgetTypes[]>(
          apiRoute.widget.get + contentId,
          {},
          token,
        );
        if (config.status !== 200) {
          setModal(null);
          return;
        }

        setWidgetList(
          (await Promise.all(responseWidgetList.map(async (rawWidget) => createWidget(rawWidget)))).sort(
            (a, b) => a.priority - b.priority,
          ),
        );

        setModal(null);
      }
    } catch (e) {
      console.error(e);
    }
  }, [contentId, token]);

  // 화면 크기 변함에 따라 전체 width값 및 height값 변경
  const handleResize = useCallback(() => {
    setWidth(monitorRef.current?.clientWidth ?? 0);
    setHeight(monitorRef.current?.clientHeight ?? 0);
  }, [monitorRef]);

  // 현재 화면 크기 실시간 감지
  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (contentId) {
      getContentById();
    }
  }, [contentId, getContentById]);

  return (
    <PreviewContent
      monitorRef={monitorRef}
      content={content}
      widgetList={widgetList}
      rowWidth={rowWidth}
      colHeight={colHeight}
    />
  );
};

export default PreviewContentContainer;
