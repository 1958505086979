import { useCallback, useEffect, useState } from 'react';
import Devices from '../Devices';
import { apiRoute, requestSecureGet } from '@libs/api';
import { convertDeviceResponseTypeToType, DeviceResponseTypes, DeviceTypes } from '@typedef/Device/device.types';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { modalSelector } from '@stories/modal';
import DeviceManageContainer from './DeviceManageContainer';
import DevicePowerContainer from './DevicePowerContainer';
import { accountSelector } from '@stories/account';
import { deviceListSelector } from '@stories/deviceList';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import { EDeviceType } from '@typedef/Device/device-type.enum';
import useSearch from '@hooks/useSearch';
import { defaultPageTypes, PageTypes } from '@typedef/libs/pagination.types';
import dayjs from 'dayjs';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';
import useDevicePowerManage from '@hooks/useDevicePowerManage';

const DevicesContainer = () => {
  const [reload, setReload] = useState<number>(0);
  const [deviceList, setDeviceList] = useRecoilState(deviceListSelector);
  const [checkedList, setCheckedList] = useState<DeviceTypes[]>([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState<EDeviceType>(EDeviceType.BOARD);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageInfo, setPageInfo] = useState<PageTypes>(defaultPageTypes);

  const { option, search } = useSearch();

  const setModal = useSetRecoilState(modalSelector);
  const token = useRecoilValue(accountSelector).accessToken;

  const { updateDevicePower } = useDevicePowerManage();

  // 클릭시 모달 오픈
  const onClickModalOpen = useCallback(
    (key: string, device?: DeviceTypes, status?: boolean) => {
      const modal: {
        [key: string]: { id: string; header: string; body: JSX.Element };
      } = {
        add: {
          id: 'add',
          header: '장비 추가하기',
          body: <DeviceManageContainer id="add" type="추가" reload={() => setReload((prev) => prev + 1)} />,
        },
        update: {
          id: 'update',
          header: '장비 수정하기',
          body: (
            <DeviceManageContainer
              id="update"
              type="수정"
              devices={[device!]}
              reload={() => setReload((prev) => prev + 1)}
            />
          ),
        },
        delete: {
          id: 'delete',
          header: '장비 삭제하기',
          body: (
            <DeviceManageContainer
              id="delete"
              type="삭제"
              devices={checkedList}
              reload={() => setReload((prev) => prev + 1)}
            />
          ),
        },
        power: {
          id: 'power',
          header: '장비 전원변경',
          body: (
            <DevicePowerContainer
              device={deviceList.find((v: DeviceTypes) => v.id === device?.id)!}
              reload={() => setReload((prev) => prev + 1)}
            />
          ),
        },
      };

      if (key === 'delete' && checkedList.length === 0) return alert('장비을 먼저 선택해 주세요.');

      setModal({
        header: modal[key].header,
        close: true,
        body: modal[key].body,
      });
    },
    [setModal, checkedList, deviceList],
  );

  // 디스플레이 해상도 비율 계산
  const ratioGCDCalculator = useCallback((width: number, height: number) => {
    // a = 최대공약수
    if (!width && !height) return '';

    let ratio: string = '';

    function gcd(a: number, b: number) {
      if (b === 0) {
        ratio = width / a + ' : ' + height / a;
      } else {
        gcd(b, a % b);
      }
    }

    gcd(width, height);

    return ratio;
  }, []);

  const handleSelectedEDevice = (v: EDeviceType) => {
    setSelectedDeviceType(v);
    setDeviceList([]);
  };

  // 장비 불러오기
  const loadDevices = useCallback(
    async (option: string, search: string, page: number) => {
      const queryParams: Record<string, any> = { page: page, size: 8, type: selectedDeviceType.value };
      if (option) {
        queryParams[option] = search;
      }

      const { config, data } = await requestSecureGet<PaginationTypes<DeviceResponseTypes>>(
        `${apiRoute.devices.get}${parseQueryParamsToString(queryParams)}`,
        {},
        token,
      );

      if (config.status === 200) {
        setDeviceList(
          data.content.map((v) => {
            const device = convertDeviceResponseTypeToType(v) as DeviceTypes;

            // 현재 시간
            const now = dayjs();
            const statusUpdatedAt = dayjs(device.statusUpdatedAt);
            // 특정한 시간이 현재 시간보다 120초 이전인지 확인
            const isBefore = statusUpdatedAt.add(120, 'second').isBefore(now);

            if (isBefore && device.status.equals(EDeviceStatus.REQUEST_OFF))
              updateDevicePower(device.ip, EDeviceStatus.ON);
            if (isBefore && device.status.equals(EDeviceStatus.REQUEST_ON))
              updateDevicePower(device.ip, EDeviceStatus.OFF);

            return device;
          }),
        );
        setPageInfo(data);
      }
    },
    [token, setDeviceList, selectedDeviceType],
  );

  const onSearchSummit = () => {
    setCurrentPage(1);
  };
  const onPageChange = (page: number) => {
    setDeviceList([]);
  };
  useEffect(() => {
    loadDevices(option!, search!, currentPage);

    setCheckedList([]);
    const timer = setInterval(() => {
      loadDevices(option!, search!, currentPage);
    }, 2000);

    return () => clearInterval(timer);
  }, [reload, option, search, currentPage, selectedDeviceType]);

  return (
    <Devices
      deviceList={deviceList}
      checkedList={checkedList}
      setCheckedList={setCheckedList}
      onClickModalOpen={onClickModalOpen}
      ratioGCDCalculator={ratioGCDCalculator}
      selectedDeviceType={selectedDeviceType}
      handleSelectedEDevice={handleSelectedEDevice}
      pageInfo={pageInfo}
      currentPage={currentPage}
      onPageChange={onPageChange}
      setCurrentPage={setCurrentPage}
      onSearchSummit={onSearchSummit}
    />
  );
};

export default DevicesContainer;
