import { Delete, Plus } from '@assets/icons';
import { companySearchOptions, CompanyTypes } from '@typedef/Company/company.types';

import * as S from '../Account/styles/AccountStyle';
import React from 'react';
import SearchTableContainer from '@components/Common/SearchTable/containers/SearchTableContainer';
import Pagination from '@components/Common/Pagination/Pagination';
import { PageTypes } from '@typedef/libs/pagination.types';

type Props = {
  onClickModalOpen: (id: string, selectAccount?: CompanyTypes) => void;
  companyList: CompanyTypes[];
  checkedList: CompanyTypes[];
  setCheckedList: React.Dispatch<React.SetStateAction<CompanyTypes[]>>;
  pageInfo: PageTypes;
  currentPage: number;
  onPageChange: (page: number) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onSearchSummit: () => void;
};

const Company = ({
  onClickModalOpen,
  companyList,
  checkedList,
  setCheckedList,
  pageInfo,
  currentPage,
  setCurrentPage,
  onPageChange,
  onSearchSummit,
}: Props) => {
  return (
    <S.Container>
      <S.Header>
        <S.Button
          backgroundColor="#f0614f"
          onClick={() => {
            onClickModalOpen('delete');
          }}
        >
          <Delete title="delte" />
          <p>삭제</p>
        </S.Button>
        <S.Button
          backgroundColor="#2b3e63"
          onClick={() => {
            onClickModalOpen('add');
          }}
        >
          <Plus title="plus" />
          <p>추가</p>
        </S.Button>
        <SearchTableContainer searchOptions={companySearchOptions} onSummit={onSearchSummit} />
      </S.Header>
      <S.Article>
        <div className="background">
          <div className="tableHeader">
            <table>
              <colgroup>
                <col width="5%" />
                <col width="15%" />
                <col width="80%" />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          const clone: CompanyTypes[] = [];
                          companyList.forEach((company) => {
                            clone.push(company);
                          });
                          setCheckedList(clone);
                        } else {
                          setCheckedList([]);
                        }
                      }}
                    />
                  </th>
                  <th>name</th>
                  <th>사업자번호</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tableContent">
            <table>
              <colgroup>
                <col width="5%" />
                <col width="15%" />
                <col width="80%" />
              </colgroup>
              <tbody>
                {companyList.map((company) => (
                  <S.Tr
                    id={company.name}
                    checked={Boolean(checkedList.find((item) => item.name === company.name))}
                    key={company.name}
                    onClick={() => {
                      onClickModalOpen('update', company);
                    }}
                  >
                    <td
                      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      <input
                        type="checkbox"
                        id={company.name ? company.name.toString() : '0'}
                        checked={Boolean(checkedList.find((item) => item.name === company.name))}
                        onChange={(e) => {
                          e.stopPropagation();
                          if (e.target.checked) {
                            setCheckedList((prev) => {
                              const clone = [...prev];

                              clone.push(company);

                              return clone;
                            });
                          } else {
                            setCheckedList(checkedList.filter((check) => check.name !== company.name));
                          }
                        }}
                      />
                    </td>
                    <td>{company.name}</td>
                    <td>{company.number}</td>
                  </S.Tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          onPageChange={onPageChange}
          pageInfo={pageInfo}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </S.Article>
    </S.Container>
  );
};

export default Company;
