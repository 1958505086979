import { apiRoute, requestSecurePost, requestSecurePatch } from '@libs/api';
import { accountSelector } from '@stories/account';
import { modalSelector } from '@stories/modal';
import { DeviceTypes } from '@typedef/Device/device.types';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DevicePower from '../components/DevicePower';
import useDevicePowerManage from '@hooks/useDevicePowerManage';
import { EDeviceStatus } from '@typedef/Device/device-status.enum';

type Props = {
  device: DeviceTypes;
  reload: () => void;
};

const DevicePowerContainer = ({ device, reload }: Props) => {
  const [btnState, setBtnState] = useState<string>('');

  const token = useRecoilValue(accountSelector).accessToken;
  const setModal = useSetRecoilState(modalSelector);

  const { updateDevicePower } = useDevicePowerManage();

  // 장비 전원 상태 업데이트
  const onClickBtn = useCallback(async () => {
    const newStatus = !device.status?.data;

    await updateDevicePower(device.ip, newStatus ? EDeviceStatus.REQUEST_ON : EDeviceStatus.REQUEST_OFF);
    setModal(null);
    reload();
  }, [device, setModal, reload, token]);

  useEffect(() => {
    if (!device.status?.data) {
      setBtnState('시작');
    } else {
      setBtnState('종료');
    }
  }, [setBtnState]);

  return <DevicePower btnState={btnState} onClickBtn={onClickBtn} />;
};

export default DevicePowerContainer;
