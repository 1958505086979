import { ContentTypes } from '@typedef/Contents/contents.types';
import * as S from './styles/MonitorStyle';
import SubContainer from '@components/Common/Sub/containers/SubContainer';
import CustomWidgetContainer from '@components/Widgets/containers/CustomWidgetContainer';
import { WidgetTypes } from '@typedef/Widget/widget.types';
import { EWidgetType } from '@typedef/Widget/widget-type.enum';
import ModalContainer from '@components/Common/Modal/containers/ModalContainer';
import React from 'react';
import { FullScreen, FullScreenHandle } from 'react-full-screen';
import { DeviceTypes } from '@typedef/Device/device.types';
import FileWidget from '@components/Widgets/components/FileWidget';
import { convertToRgba } from '@libs/colorUtiles';

type Props = {
  widgetList: WidgetTypes[];
  monitorRef: React.RefObject<HTMLDivElement>;
  rowWidth: number;
  colHeight: number;
  handle: FullScreenHandle;
  handleFullScreen: () => void;
  content: ContentTypes;
  device: DeviceTypes;
  isActive: boolean;
  noticeMessage: string;
};

const Monitor = ({
  widgetList,
  monitorRef,
  rowWidth,
  handleFullScreen,
  handle,
  colHeight,
  content,
  device,
  isActive,
  noticeMessage,
}: Props) => {
  return (
    <>
      {isActive ? (
        <>
          <ModalContainer />
          <FullScreen className="full-screen" handle={handle}>
            <S.Container
              ref={monitorRef}
              backgroundColor={content.backgroundColor}
              id={'screen-shot-target'}
              onDoubleClick={handleFullScreen}
            >
              {widgetList.map((data) => (
                <S.Section
                  key={data.id}
                  id={data.i}
                  width={rowWidth * data.w}
                  height={colHeight * data.h}
                  top={colHeight * data.y}
                  left={rowWidth * data.x}
                  backgroundColor={convertToRgba(data.backgroundColor, data.opacity)}
                >
                  {data.type.equals(EWidgetType.FILE) || data.type.equals(EWidgetType.FILE_LIST) ? (
                    <FileWidget widget={data} />
                  ) : data.type.equals(EWidgetType.TIME) ? (
                    <CustomWidgetContainer widget={data} deviceId={device.id} content={content} />
                  ) : data.type.equals(EWidgetType.SUB) ? (
                    <SubContainer widget={data} />
                  ) : data.type.equals(EWidgetType.NEWS) ? (
                    <CustomWidgetContainer widget={data} deviceId={device.id} content={content} />
                  ) : data.type.equals(EWidgetType.FINEDUST) ? (
                    <CustomWidgetContainer widget={data} deviceId={device.id} content={content} />
                  ) : data.type.equals(EWidgetType.WEATHER) ? (
                    <CustomWidgetContainer widget={data} deviceId={device.id} content={content} />
                  ) : data.type.equals(EWidgetType.STOCK_INDEX) ? (
                    <CustomWidgetContainer widget={data} deviceId={device.id} content={content} />
                  ) : (
                    <S.Text
                      textColor={data.textColor}
                      textFamily={data.textFamily}
                      textItalic={data.textItalic}
                      textSize={data.textSize}
                      textUnderline={data.textUnderline}
                      textWeight={data.textWeight}
                    >
                      {data.text}
                    </S.Text>
                  )}
                </S.Section>
              ))}
            </S.Container>
          </FullScreen>
        </>
      ) : (
        <>
          <S.Container backgroundColor={'rgba(0, 0, 0, .9)'}>
            <S.OffMonitor>{noticeMessage}</S.OffMonitor>
          </S.Container>
        </>
      )}
    </>
  );
};
export default Monitor;
