import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import SearchTable from '../SearchTable';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { parseQueryParamsToString } from '@libs/parseQueryParamsToString';
import {
  GetWeatherInputsTypes,
  GetWeathersInputsDefault,
  SearchWeatherTypes,
  WeatherTypes,
} from '@typedef/Widget/weather.types';
import { PaginationTypes } from '@typedef/libs/pagination.types';
import { apiRoute, requestGet } from '@libs/api';
import { useRecoilState } from 'recoil';
import { weatherListSelector } from '@stories/weatherList';
import { getWeatherLocationStr } from '@libs/weatherUtils';
import { PeriodDefaultValue, PeriodType } from '@components/Common/CommonDate/CommonDate';

type Props<T> = {
  searchOptions: SearchTableType<T>[];
  onChange?: (option: SearchTableType<T>, input: string) => void;
  onSummit?: () => void;
};

const SearchTableContainer = <T,>({ searchOptions, onChange, onSummit }: Props<T>) => {
  const navigate = useNavigate();

  const [weatherList, setWeatherList] = useRecoilState(weatherListSelector);
  const [searchWeatherList, setSearchWeatherList] = useState<SearchWeatherTypes[]>([]);

  const [searchOption, setSearchOption] = useState<SearchTableType<T>>(searchOptions[0]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [period, setPeriod] = useState<PeriodType>(PeriodDefaultValue);

  const [location, setLocation] = useState<string>('');
  const [locationShow, setLocationShow] = useState(false);

  const locationRef = useRef<HTMLDivElement>(null);

  const loadWeathers = async () => {
    const getWeathersInputs: GetWeatherInputsTypes = {
      ...GetWeathersInputsDefault,
      paged: false,
    };

    if (weatherList.length === 0) {
      const {
        data: { content },
        config,
      } = await requestGet<PaginationTypes<WeatherTypes>>(
        `${apiRoute.weather.getGridXYWithoutJWT}${parseQueryParamsToString(getWeathersInputs)}`,
        {},
      );

      if (config.status !== 200) return;

      setWeatherList(content);
    }
  };

  const handleOnChange = (option: SearchTableType<T>, input: string) => {
    setSearchOption(option);
    setSearchInput(input);

    onChange?.(option, input);
  };

  const handleOnDateChange = (option: SearchTableType<T>, period: PeriodType) => {
    setSearchOption(option);
    setPeriod(period);

    onChange?.(option, `${period.startedAt},${period.endedAt}`);
  };

  const handleOnSummit = (option?: SearchTableType<T>, input?: string) => {
    onSummit?.();

    if (option?.type === 'date') {
      navigate(
        parseQueryParamsToString({
          option: option ? option.value : searchOption.value,
          search: input ? input : `${period.startedAt}~${period.endedAt}`,
        }),
      );
    } else if (searchInput.length === 0 && !input) {
      navigate(parseQueryParamsToString({}));
      setLocation('');
    } else {
      navigate(
        parseQueryParamsToString({
          option: option ? option.value : searchOption.value,
          search: input ? input : searchInput,
        }),
      );
    }
  };

  const handleResetButton = () => {
    setSearchInput('');
    setLocation('');
    setPeriod(PeriodDefaultValue);
    navigate(parseQueryParamsToString({}));
  };

  useEffect(() => {
    void loadWeathers();
  }, []);

  useEffect(() => {
    setSearchWeatherList(
      weatherList.map((v) => {
        return { id: v.id!, value: getWeatherLocationStr(v) };
      }),
    );
  }, [weatherList]);

  useEffect(() => {
    handleResetButton();
  }, [searchOption.value]);

  return (
    <SearchTable
      handleOnChange={handleOnChange}
      handleOnDateChange={handleOnDateChange}
      handleOnSummit={handleOnSummit}
      handleResetButton={handleResetButton}
      searchInput={searchInput}
      period={period}
      searchOption={searchOption}
      searchOptions={searchOptions}
      weatherList={weatherList}
      searchWeatherList={searchWeatherList}
      location={location}
      setLocation={setLocation}
      locationShow={locationShow}
      setLocationShow={setLocationShow}
      locationRef={locationRef}
    />
  );
};

export default SearchTableContainer;
