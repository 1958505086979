import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 70vh;
  height: 100%;
  background-color: #fff;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border-radius: 7px;
  padding: 30px;

  .button {
    width: 100px;
    height: 40px;

    border: 0;
    border-radius: 8px;

    background-color: #256662;
    color: #fff;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btns {
    height: 45px;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    button {
      min-width: 100px;
      height: 45px;
      padding: 0 15px;
      background-color: #256662;
      border: 1px solid #256662;
      border-radius: 5px;
      color: #fff;
      font-weight: 600;

      &:active {
        opacity: 0.7;
      }

      &:disabled {
        background-color: #d3d3d3;
        color: #fff;
        cursor: not-allowed;
      }
    }

    .update {
      width: 100px;
      height: 40px;
      border-radius: 8px;
      background-color: #f16e00;
      border: none;
    }
  }

  .title-headline {
    padding: 15px 0;

    p {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .profile-data {
    margin: 7px 0;
    display: flex;
    align-items: center;
    position: relative;

    .title {
      min-width: 150px;
    }

    input[type='text'] {
      padding: 10px 12px;
      border-radius: 7px;
      border: 1px solid #ccc;
    }

    .value {
      padding-left: 12px;
      height: 100%;
    }

    progress {
      cursor: pointer;
    }
  }

  .profile-data:hover .visible-data {
    display: block;
  }

  .visible-data {
    display: none;
    top: 30px;
    position: absolute;
  }
`;
