import { SearchTableType } from '@typedef/SearchTable/searchTable.types';
import { LoaderExtendType, SurveyCountType } from './monitoring.type';

export type ContentsPlayStatisticsType = {
  ip: string;
  createdAt: string;
  playMilliSec: string;
  count: number;
};

export type DateContentsPlayCommandType = {
  COUNT: LoaderExtendType<SurveyCountType[]>;
  SUM: LoaderExtendType<SurveyCountType[]>;
};

export type contentsPlayStatisticsDateType = {
  ip: string;
  createdAt: string;
  playMilliSec: string;
  count: number;
};

export type contentsPlaySummariesStatistics = {
  day: LoaderExtendType<contentsPlayStatisticsDateType[]>;
  week: LoaderExtendType<contentsPlayStatisticsDateType[]>;
  month: LoaderExtendType<contentsPlayStatisticsDateType[]>;
};

export type ContentsPlayListType = {
  zone: string;
  createdAt: string;
  endedAt: string;
  id: string;
  ip: string;
  playMilliSec: 0;
  programName: string;
  startedAt: string;
  updatedAt: string;
};

export type ContentsPlaySearchTypes = Omit<ContentsPlayListType, 'zone'> & { zoneId: string };

export type ContentsPlaySummariesType = {
  createdAt: string;
  endedAt: string;
  id: string;
  ip: string;
  playMilliSec: 0;
  programName: string;
  startedAt: string;
  updatedAt: string;
}[];

export type ContentsPlaySummariesStatisticsType = {
  ip: string;
  createdAt: string;
  playMilliSec: number;
  count: number;
};

export const contentPlaybackSearchOptions: SearchTableType<ContentsPlaySearchTypes>[] = [
  {
    value: 'programName',
    name: '프로그램명',
    type: 'string',
  },
  {
    value: 'startedAt',
    name: '시작 시간',
    type: 'date',
  },
  {
    value: 'endedAt',
    name: '종료 시간',
    type: 'date',
  },
  {
    value: 'zoneId',
    name: '존',
    type: 'dropdown',
  },
];
